import { FC, useCallback, useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import StaffTempRegistrationDialog from 'components/molecules/StaffTempRegistrationDialog';
import StaffDeleteDialog from 'components/molecules/StaffDeleteDialog';
import { Table, Button as Button2 } from 'antd';
import { StaffListModel } from 'types/User';
import { API_URL, rootDir } from 'utils/CommonConst';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import StaffEditDialog from 'components/molecules/StaffEditDialog';
import { consoleLog, methodGet, methodPut } from 'utils/CommonFunctions';
import { useNavigate } from 'react-router';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    submit: {
      maxWidth: 100,
      margin: theme.spacing(3, 0, 2),
      '&:hover': {
        background: 'darkorange',
      },
    },
    remail: {
      '&:active': {
        background: 'green',
      },
      '&:focus': {
        background: 'green',
      },
    },
    space: {
      margin: theme.spacing(0, 2, 0),
    },
  }),
);

const StaffManagementPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const [staffsData, setStaffsData] = useState<StaffListModel[]>([]);
  const [staffData, setStaffData] = useState<StaffListModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [mode, setMode] = useState<Mode>('info');
  const navigate = useNavigate();

  useEffect(() => {
    if (appContext.state.LoginUser.isMaster) {
      // 顧客専用ページのため
      navigate(`${rootDir}top`);
    }
  }, [appContext.state.LoginUser.isMaster, navigate]);

  const getData = useCallback(async () => {
    if (appContext.state.LoginUser.isLogin) {
      try {
        const url = `${API_URL}api/v1/users/${
          appContext.state.LoginUser.idCust ?? 0
        }/staffs`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as StaffListModel[];
        setStaffsData(data);
        setIsLoading(false);
      } catch (ex) {
        consoleLog(ex);
      }
    }
  }, [appContext.state.LoginUser]);
  useEffect(() => {
    getData().finally(() => {
      /* Nothing to do. */
    });
  }, [getData]);

  const text =
    '担当者登録用にメールを送信します。 ¥n メールに記載されたURLにアクセスし、ログインすると登録が完了します。';
  const breakedText = text.split('¥n').map((line) => (
    <span key={line}>
      {line}
      <br />
    </span>
  ));

  const [openTemp, setOpenTemp] = useState(false);

  const handleClickOpenTemp = () => {
    setOpenTemp(true);
  };

  const handleCloseTemp = (handleClickInsert: boolean) => {
    setOpenTemp(false);
    if (handleClickInsert) {
      getData().finally(() => {
        /* Nothing to do. */
      });
    }
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (row: StaffListModel) => {
    setStaffData(row);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isDeleted: boolean) => {
    setOpenDelete(false);
    if (isDeleted) {
      getData().finally(() => {
        /* Nothing to do. */
      });
    }
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = (row: StaffListModel) => {
    setStaffData(row);
    setOpenEdit(true);
  };

  const handleCloseEdit = (isEdit: boolean, changeMail: boolean) => {
    setOpenEdit(false);
    if (changeMail) {
      setIsHide(false);
      setMode('info');
      setErrorMessages([
        `担当者「${
          staffData?.name ?? ''
        }」のメールアドレスに認証メールを送信しました。`,
      ]);
    }
    if (isEdit) {
      getData().finally(() => {
        /* Nothing to do. */
      });
    }
  };

  const resendMail = async (row: StaffListModel) => {
    try {
      const url = `${API_URL}api/v1/users/${
        appContext.state.LoginUser?.idCust ?? 0
      }/staffs/${row.picID ?? ''}/mail`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPut(url, null, true, appContext.state.LoginUser);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const data = res.data as boolean;
      if (data) {
        setIsHide(false);
        setMode('info');
        setErrorMessages([
          `担当者「${row.name}」のメールアドレスに認証メールを送信しました。`,
        ]);
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const columns = [
    {
      title: '担当者名',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      align: 'center' as const,
    },
    {
      title: '所属',
      dataIndex: 'section',
      key: 'section',
      align: 'center' as const,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'mail',
      key: 'mail',
      align: 'center' as const,
    },
    {
      title: '電話番号',
      dataIndex: 'tel',
      key: 'tel',
      align: 'center' as const,
    },
    {
      title: '状態',
      dataIndex: 'isEnable',
      key: 'isEnable',
      width: 150,
      align: 'center' as const,
      render: (value: boolean) => (
        <>{value ? <span>登録済</span> : <span>仮登録</span>}</>
      ),
    },
    {
      title: '削除',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      render: (value: boolean, row: StaffListModel) => (
        <>
          {!row.isEnable && (
            <Button2
              type="primary"
              className={classes.remail}
              onClick={() => {
                resendMail(row).finally(() => {
                  /* Nothing to do. */
                });
              }}
            >
              メール再送
            </Button2>
          )}
          <span className="invoiceUpload-button">
            <Button2
              type="primary"
              className={classes.space}
              onClick={() => {
                handleClickOpenEdit(row);
              }}
            >
              編集
            </Button2>
          </span>
          <Button2
            type="primary"
            danger
            className={classes.space}
            onClick={() => {
              handleClickOpenDelete(row);
            }}
          >
            削除
          </Button2>
        </>
      ),
      align: 'center' as const,
    },
  ];

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="lg">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            担当者管理
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div hidden={isLoading}>
            <MuiThemeProvider theme={theme2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleClickOpenTemp}
              >
                追加
              </Button>
            </MuiThemeProvider>
            <StaffTempRegistrationDialog
              open={openTemp}
              onClickClose={handleCloseTemp}
              text={breakedText}
              button="メール送信"
              register
            />
            <StaffDeleteDialog
              open={openDelete}
              onClickClose={handleCloseDelete}
              row={staffData}
              staffDelete
            />
            <StaffEditDialog
              open={openEdit}
              onClickClose={handleCloseEdit}
              row={staffData}
            />
            <div className="table-size">
              <Table
                dataSource={staffsData}
                columns={columns}
                pagination={{ pageSize: 20 }}
                rowKey="picID"
              />
            </div>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default StaffManagementPage;
