import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'antd/dist/antd.css';
import './Page.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InvoiceUploadDialog from 'components/molecules/InvoiceUploadDialog';
import { Table, Button as Button2 } from 'antd';
import { API_URL, rootDir } from 'utils/CommonConst';
import { FileInfo, FilesKey } from 'types/Files';
import {
  consoleLog,
  deleteFile,
  downloadData,
  findFile,
  methodGet,
  releaseFile,
} from 'utils/CommonFunctions';
import CommonDialog from 'components/molecules/CommonDialog';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';
import { User } from '../../types/User';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(1.2, 5, 4),
      '&:hover': {
        background: 'darkorange',
      },
    },
    space: {
      margin: theme.spacing(0, 2, 0),
    },
    length: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: 600,
    },
  }),
);

const InvoiceUploadPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const location = useLocation();
  const { idCust } = (location.state as { idCust: number }) || {
    idCust: 0,
  };

  const [name, setName] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [invoiceList, setInvoiceList] = useState<FileInfo[]>([]);
  const [commDlg, setCommDlg] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();
  const [commDlgProc, setCommDlgProc] = useState('del');
  const [currentRecord, setCurrentRecord] = useState<FileInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (appContext.state.LoginUser.isLogin) {
      if (!appContext.state.LoginUser.isMaster) {
        // 管理者専用ページのため
        navigate(`${rootDir}top`);
      }
    }
  }, [appContext.state.LoginUser, navigate]);

  const getUserData = useCallback(async () => {
    if (appContext.state.LoginUser.isLogin) {
      try {
        const url = `${API_URL}api/v1/users/${idCust}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as User;
        setName(data.custFullName);
      } catch (ex) {
        consoleLog(ex);
      }
    }
  }, [idCust, appContext.state.LoginUser]);

  const getMonthlyCharge = useCallback(async () => {
    if (appContext.state.LoginUser.isLogin) {
      const fileKey: FilesKey = {
        idCust,
        id: null,
        fileType: 2,
        no: null,
        name: '',
        path: '',
        billingDate: '',
        isEnable: null,
      };
      const filesKey: FilesKey[] = [];
      filesKey.push(fileKey);
      const fileInfo: FileInfo[] = await findFile(
        filesKey,
        appContext.state.LoginUser,
      );
      setInvoiceList(fileInfo);
    }
  }, [idCust, appContext.state.LoginUser]);

  useEffect(() => {
    getUserData().finally(() => {
      /* Nothing to do. */
    });
    getMonthlyCharge().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
  }, [getUserData, getMonthlyCharge]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isAdd: boolean) => {
    setOpen(false);
    if (isAdd) {
      getMonthlyCharge().finally(() => {
        /* Nothing to do. */
      });
    }
  };

  // ダウンロード
  const DownloadFile = async (key: FilesKey[], fileName: string) => {
    if (key.length > 0) {
      // ダウンロード要求
      await downloadData(key, fileName, appContext.state.LoginUser);
    } else {
      /*
        void message.warning(
          'ファイル情報を設定してください。',
        );
        */
    }
  };

  const releaseF = async () => {
    if (currentRecord) {
      const fileKey: FilesKey = {
        idCust: currentRecord.idCust,
        id: currentRecord.id,
        fileType: currentRecord.fileType,
        no: currentRecord.no,
        name: '',
        path: '',
        billingDate: '',
        isEnable: null,
      };
      const filesKey: FilesKey[] = [];
      filesKey.push(fileKey);
      const res: boolean = await releaseFile(
        filesKey,
        appContext.state.LoginUser,
      );
      setCommDlg(false);
      if (res) {
        // 成功
        getMonthlyCharge().finally(() => {
          /* Nothing to do. */
        });
      }
    }
  };

  const deleteF = async () => {
    if (currentRecord) {
      const fileKey: FilesKey = {
        idCust: currentRecord.idCust,
        id: currentRecord.id,
        fileType: currentRecord.fileType,
        no: currentRecord.no,
        name: '',
        path: '',
        billingDate: '',
        isEnable: null,
      };
      const filesKey: FilesKey[] = [];
      filesKey.push(fileKey);
      const res: boolean = await deleteFile(
        filesKey,
        appContext.state.LoginUser,
      );
      setCommDlg(false);
      if (res) {
        // 成功
        getMonthlyCharge().finally(() => {
          /* Nothing to do. */
        });
      }
    }
  };

  /* non eslint-disable */
  const columns = [
    {
      title: '請求書',
      dataIndex: 'billingDate',
      key: 'billingDate',
      render: (value: string, record: FileInfo) => (
        // eslint-disable-next-line
        <a
          onClick={() => {
            const fileKey: FilesKey = {
              idCust: record.idCust,
              id: record.id,
              fileType: record.fileType,
              no: record.no,
              name: record.name,
              path: '',
              billingDate: record.billingDate,
              isEnable: null,
            };
            const filesKey: FilesKey[] = [];
            filesKey.push(fileKey);
            DownloadFile(filesKey, record.name).finally(() => {
              /* Nothing to do. */
            });
          }}
        >
          {value}
          <br />
        </a>
      ),
      align: 'center' as const,
    },
    {
      title: '状況',
      dataIndex: 'isEnable',
      key: 'isEnable',
      render: (value: boolean) =>
        (value && <span>公開中</span>) || (!value && <span>確認待ち</span>),
      width: 200,
      align: 'center' as const,
    },
    {
      title: '操作',
      width: 250,
      render: (_value: string, record: FileInfo) => {
        const confirmationRel = () => {
          const text = `${record.name}を公開します。¥nよろしいですか？`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));

          setCurrentRecord(record);
          setCommDlgProc('rel');
          setConfirmMessages(breakedText);
          setCommDlg(true);
        };

        const confirmationDel = () => {
          const text = `${record.name}を削除します。¥nよろしいですか？`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));

          setCurrentRecord(record);
          setCommDlgProc('del');
          setConfirmMessages(breakedText);
          setCommDlg(true);
        };

        return (
          <>
            {!record.isEnable && (
              <span className="invoiceUpload-button">
                <Button2
                  type="primary"
                  className={classes.space}
                  onClick={confirmationRel}
                >
                  公開
                </Button2>
              </span>
            )}
            <span className="invoiceUpload-del">
              <Button2 type="primary" onClick={confirmationDel}>
                削除
              </Button2>
            </span>
          </>
        );
      },
      align: 'center' as const,
    },
  ];

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="lg">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            月次請求書
          </Typography>
          <div className={classes.form} hidden={isLoading}>
            <TextField
              variant="outlined"
              margin="dense"
              id="customerName"
              label="顧客名"
              name="customerName"
              InputProps={{
                readOnly: true,
              }}
              value={name}
              className={classes.length}
            />
            <MuiThemeProvider theme={theme2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleClickOpen}
              >
                追加
              </Button>
            </MuiThemeProvider>
            <InvoiceUploadDialog
              open={open}
              onClickClose={handleClose}
              idCust={idCust}
              name={name}
            />
            <CommonDialog
              msg={confirmMessages}
              isOpen={commDlg}
              doYes={commDlgProc === 'del' ? deleteF : releaseF}
              doNo={() => {
                setCommDlg(false);
              }}
            />
            <div className="table-size">
              <Table dataSource={invoiceList} columns={columns} rowKey="no" />
            </div>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default InvoiceUploadPage;
