import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Box } from '@material-ui/core';
import { StaffListModel, UpdateStaff } from 'types/User';
import { API_URL } from 'utils/CommonConst';
import { consoleLog, methodPut } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import MessagePanel, { Mode } from './MessagePanel';
import CustomTextField2 from './CustomTextField2';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    space: {
      margin: theme.spacing(3, 5, 0),
    },
    line: {
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: '#1769aa',
      },
    },
    dialogTitle: {
      fontSize: 24,
    },
    submit: {
      maxWidth: 110,
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: 'darkgray',
      },
    },
  }),
);

type Pic = {
  name: string;
  section: string;
  mail: string;
  tel: string;
};

export type StaffEditDialogProps = {
  open: boolean;
  onClickClose: (update: boolean, changeMail: boolean) => void;
  row: StaffListModel | undefined;
};

const StaffEditDialog: FC<StaffEditDialogProps> = ({
  open,
  onClickClose,
  row,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const mailCheck =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
  const telCheck = /\d{2,4}-\d{2,4}-\d{4}$/;

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [mode, setMode] = useState<Mode>('info');
  const [changeMail, setChangeMail] = useState<boolean>(false);
  const [pic, setPic] = useState<Pic>();
  const [picOrg, setPicOrg] = useState<Pic>();

  useEffect(() => {
    const staff: Pic = {
      name: row?.name ?? '',
      section: row?.section ?? '',
      mail: row?.mail ?? '',
      tel: row?.tel ?? '',
    };
    setPic(staff);
    setPicOrg(staff);
  }, [row]);

  const changedName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...pic } as Pic;
    data.name = event.target.value;
    setPic(data);
  };
  const changedSection = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...pic } as Pic;
    data.section = event.target.value;
    setPic(data);
  };
  const changedMail = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...pic } as Pic;
    data.mail = event.target.value;
    setPic(data);
    if (picOrg?.mail !== event?.target.value) {
      setChangeMail(true);
    } else {
      setChangeMail(false);
    }
  };
  const changedTel = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...pic } as Pic;
    data.tel = event.target.value;
    setPic(data);
  };

  const update: StaffListModel = {
    idCust: row?.idCust ?? null,
    picID: row?.picID ?? null,
    name: pic?.name ?? '',
    section: pic?.section ?? '',
    mail: pic?.mail ?? '',
    tel: pic?.tel ?? '',
    sortNo: null,
    isEnable: false,
    isDeleted: false,
  };

  const postData = async () => {
    try {
      const data: UpdateStaff = {
        pic: update,
        changeMail,
      };
      const url = `${API_URL}api/v1/users/staffs`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPut(url, data, true, appContext.state.LoginUser);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const resp = res.data as boolean;
      if (resp) {
        onClickClose(true, changeMail);
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const handleClickInsert = () => {
    let error = false;
    const message: string[] = [];
    if (pic && picOrg) {
      if (pic.name === '') {
        message.push('・担当者名が入力されていません。');
        error = true;
      }
      if (pic.mail === '') {
        message.push('・メールアドレスが入力されていません。');
        error = true;
      }
      if (pic.tel === '') {
        message.push('・電話番号が入力されていません。');
        error = true;
      }
      if (pic.mail !== '' && !mailCheck.test(pic.mail)) {
        message.push('・メールアドレスの形式になっていません。');
        error = true;
      }
      if (pic.tel !== '' && !telCheck.test(pic.tel)) {
        message.push('・電話番号は半角数字と半角ハイフンで入力してください。');
        message.push('　例）083-941-6300');
        error = true;
      }
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);

        return;
      }
      postData().finally(() => {
        /* Nothing to do. */
      });
      setIsHide(true);
    }
  };

  const handleClickCancel = () => {
    setIsHide(true);
    /* Nothing to do. */
    onClickClose(false, false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClickCancel}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>担当者編集</Box>
        </DialogTitle>
        <DialogContentText className={classes.space}>
          メールアドレスを変更した場合、変更したメールアドレスに認証用メールが送信されます。
        </DialogContentText>
        <DialogContent>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form}>
            <CustomTextField2
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="name"
              label="担当者名"
              value={pic?.name ?? ''}
              name="name"
              autoComplete="name"
              onChange={changedName}
              inputProps={{ maxLength: 20 }}
            />
            <CustomTextField2
              variant="outlined"
              margin="dense"
              fullWidth
              id="section"
              label="所属"
              value={pic?.section ?? ''}
              name="section"
              autoComplete="section"
              onChange={changedSection}
            />
            <CustomTextField2
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="mail"
              label="メールアドレス"
              value={pic?.mail ?? ''}
              name="mail"
              autoComplete="mail"
              onChange={changedMail}
              inputProps={{ maxLength: 50 }}
            />
            <CustomTextField2
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="tel"
              label="電話番号(ﾊｲﾌﾝ有)"
              value={pic?.tel ?? ''}
              name="tel"
              autoComplete="tel"
              onChange={changedTel}
              inputProps={{ maxLength: 20 }}
            />
          </div>
        </DialogContent>
        <MuiThemeProvider theme={theme2}>
          <DialogActions>
            <MuiThemeProvider theme={theme3}>
              <Button
                variant="contained"
                onClick={handleClickInsert}
                color="primary"
                className={classes.line}
              >
                {changeMail ? `メール送信` : `変更`}
              </Button>
            </MuiThemeProvider>
            <Button
              variant="contained"
              onClick={handleClickCancel}
              color="primary"
              className={classes.submit}
            >
              キャンセル
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>
    </>
  );
};

export default StaffEditDialog;
