import {
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Checkbox, Table, Button as Button2 } from 'antd';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { RequestDataInfo } from 'types/RequestDataInfo';
import { FilesKey } from 'types/Files';
import { debugLog, downloadData } from 'utils/CommonFunctions';
import { useNavigate } from 'react-router';
import { rootDir } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import useWindowSize from 'utils/useWindowSize';
import CommonDialog from './CommonDialog';
/* non eslint-disable */
const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'green',
      },
    },
  },
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      maxWidth: 100,
      margin: theme.spacing(0, 0, 3),
      '&:hover': {
        background: 'green',
      },
    },
    line: {
      margin: theme.spacing(0, 0, 5),
    },
    header: {
      textAlign: 'center',
    },
    dataIndentLeft: {
      marginLeft: theme.spacing(1),
    },
    dataIndentRight: {
      marginRight: theme.spacing(1),
    },
    statusIcon: {
      height: '21px',
      marginRight: 5,
    },
    dispCount: {
      textAlign: 'left',
    },
    grid: {
      height: '90%',
    },
  }),
);

export type RequestListPanelProps = {
  result: RequestDataInfo[] | undefined;
  setResult: Dispatch<SetStateAction<RequestDataInfo[] | undefined>>;
};

const RequestListPanel: FC<RequestListPanelProps> = ({ result, setResult }) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [width, height] = useWindowSize();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gridHeight, setGridHeight] = useState<number>(300);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(100);
  const [batchDownloadIndeterminate, setBatchDownloadIndeterminate] =
    useState(false);
  const [batchDownloadCheckAll, setBatchDownloadCheckAll] = useState(false);
  const [requestData, setRequestData] = useState<RequestDataInfo[] | undefined>(
    result,
  );
  const [commDlg, setCommDlg] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();

  // ダウンロード
  const DownloadFile = async (key: FilesKey[], fileName: string) => {
    if (key.length > 0) {
      // ダウンロード要求
      await downloadData(key, fileName, appContext.state.LoginUser);
    } else {
      /*
      void message.warning(
        'ファイル情報を設定してください。',
      );
      */
    }
  };
  const masterColum1 = [
    {
      title: () => (
        <div>
          顧客
          <br />
          番号
        </div>
      ),
      dataIndex: 'custData',
      key: 'custData',
      width: 60,
      fixed: true,
      align: 'center' as const,
      // eslint-disable-next-line
      sorter: (a: any, b: any) => a.custData - b.custData,
    },
    {
      title: '顧客名',
      dataIndex: 'custName',
      key: 'custName',
      fixed: true,
      width: 200,
      align: 'center' as const,
    },
  ];
  const masterColum2 = [
    {
      title: () => (
        <div>
          関連文書
          <br />
          アップロード
        </div>
      ),
      render: (_values: string, record: RequestDataInfo) => (
        <Button2
          type="primary"
          onClick={() => {
            navigate(`${rootDir}fileUpload`, { state: { id: record.id } });
          }}
        >
          UP
        </Button2>
      ),
      width: 100,
      align: 'center' as const,
    },
  ];
  const columns1 = [
    {
      title: () => (
        <div>
          依頼
          <br />
          No
        </div>
      ),
      dataIndex: 'no',
      key: 'no',
      width: 60,
      fixed: true,
      align: 'center' as const,
      // eslint-disable-next-line
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: '依頼日',
      dataIndex: 'rcptCreate',
      key: 'rcptCreate',
      width: 100,
      fixed: true,
      render: (value: string, record: RequestDataInfo) => (
        // eslint-disable-next-line
        <a
          onClick={() => {
            navigate(`${rootDir}requestInfo`, {
              state: {
                id: record.id,
                idCust: record.idCust,
                recieptType: null,
              },
            });
          }}
        >
          {value}
        </a>
      ),
      align: 'center' as const,
    },
    {
      title: () => <div className={classes.header}>担当者</div>,
      dataIndex: 'picList',
      key: 'picList',
      width: 120,
      render: (
        values: { id: number; name: string }[],
        record: RequestDataInfo,
        index: number,
      ) => {
        const pics: { id: number; name: string }[] = values;

        return pics?.map((pic, index2) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${pic.id}-${pic.name}-${index2}`}
            className={classes.dataIndentLeft}
          >
            {pic.name}
            <br />
          </span>
        ));
      },
      align: 'left' as const,
    },
    {
      title: '検査区分',
      dataIndex: 'recieptType1',
      key: 'recieptType1',
      align: 'center' as const,
      width: 150,
    },
    {
      title: () => <div className={classes.header}>試験品名</div>,
      dataIndex: 'sampleList',
      key: 'sampleList',
      render: (
        values: { name: string }[],
        record: RequestDataInfo,
        index: number,
      ) => {
        const samples: { name: string }[] = values;

        return samples?.map((sample, index2) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${sample.name}-${index2}`}
            className={classes.dataIndentLeft}
          >
            {sample.name}
            <br />
          </span>
        ));
      },
      width: 250,
      align: 'left' as const,
    },
    {
      title: '検体数',
      dataIndex: 'sampleNum',
      key: 'sampleNum',
      width: 70,
      align: 'center' as const,
    },
    {
      title: () => <div className={classes.header}>添付ファイル</div>,
      dataIndex: 'attachmentList',
      key: 'attachmentList',
      width: 120,
      render: (
        values: { no: number; name: string; fileType: number }[],
        record: RequestDataInfo,
        index: number,
      ) => {
        const attachments: { no: number; name: string; fileType: number }[] =
          values;

        return attachments?.map((attachment, index2) => (
          // eslint-disable-next-line
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${attachment.name}-${index2}`}
            className={classes.dataIndentLeft}
            onClick={() => {
              const fileKey: FilesKey = {
                idCust: record.idCust,
                id: record.id,
                fileType: attachment.fileType,
                no: attachment.no,
                name: attachment.name,
                path: '',
                billingDate: '',
                isEnable: true,
              };
              const filesKey: FilesKey[] = [];
              filesKey.push(fileKey);
              DownloadFile(filesKey, attachment.name).finally(() => {
                /* Nothing to do. */
              });
            }}
          >
            ・{attachment.name}
            <br />
          </a>
        ));
      },
      align: 'left' as const,
    },
  ];

  const columns2 = [
    {
      title: () => (
        <div>
          まとめて
          <br />
          ダウンロード
        </div>
      ),
      dataIndex: 'attachmentDownload',
      key: 'attachmentDownload',
      render: (_value: string, record: RequestDataInfo) => (
        <>
          {record.attachmentList.length > 0 && (
            <Button2
              type="primary"
              onClick={() => {
                const fileKey: FilesKey = {
                  idCust: record.idCust,
                  id: record.id,
                  fileType: 1,
                  no: null,
                  name: '',
                  path: '',
                  billingDate: '',
                  isEnable: true,
                };
                const filesKey: FilesKey[] = [];
                filesKey.push(fileKey);
                DownloadFile(filesKey, '添付').finally(() => {
                  /* Nothing to do. */
                });
              }}
            >
              DL
            </Button2>
          )}
        </>
      ),
      width: 100,
      align: 'center' as const,
    },
  ];

  const columns3 = [
    {
      title: () => <div className={classes.header}>状態</div>,
      dataIndex: 'process',
      key: 'process',
      width: 145,
      align: 'left' as const,
      render: (value: string) => {
        switch (value) {
          case '下書き（依頼前）':
            return (
              <span>
                <img
                  src="state-0.png?20230330"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case 'Web依頼申込':
            return (
              <span>
                <img
                  src="state-1.png?20220302"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case 'Web受付完了':
            return (
              <span>
                <img
                  src="state-2.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '検体到着':
            return (
              <span>
                <img
                  src="state-3.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '受入（検査中）':
            return (
              <span>
                <img
                  src="state-4.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '速報送付済':
            return (
              <span>
                <img
                  src="state-5.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '一部検査完了':
            return (
              <span>
                <img
                  src="state-9.png?20230301"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '検査完了':
            return (
              <span>
                <img
                  src="state-6.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '納品書送付済':
            return (
              <span>
                <img
                  src="state-6.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                検査完了
              </span>
            );
          case '請求書送付済':
            return (
              <span>
                <img
                  src="state-6.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                検査完了
              </span>
            );
          default:
            return '';
        }
      },
    },
    {
      title: () => (
        <div>
          速報
          <br />
          予定日
        </div>
      ),
      dataIndex: 'deadLine',
      key: 'deadLine',
      render: (_value: string, record: RequestDataInfo) => (
        <>
          {record.deadLine}
          <br />
          {record.preliminaryReportComment}
        </>
      ),
      width: 100,
      align: 'center' as const,
    },
    {
      title: () => <div className={classes.header}>検査費用</div>,
      dataIndex: 'budget',
      key: 'budget',
      width: 80,
      align: 'right' as const,
      render: (value: string) => (
        <span className={classes.dataIndentRight}>{value}</span>
      ),
    },
  ];

  const columns4 = [
    {
      title: () => <div className={classes.header}>関連文書</div>,
      dataIndex: 'documentList',
      key: 'documentList',
      width: 200,
      render: (
        values: { no: number; name: string; fileType: number }[],
        record: RequestDataInfo,
        index: number,
      ) => {
        const documents: { no: number; name: string; fileType: number }[] =
          values;

        return documents?.map((document, index2) => (
          // eslint-disable-next-line
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${document.fileType}-${document.no}-${document.name}-${index2}`}
            className={classes.dataIndentLeft}
            onClick={() => {
              const filesKey: FilesKey[] = [];
              filesKey.push({
                idCust: record.idCust,
                id: record.id,
                fileType: document.fileType,
                no: document.no,
                name: document.name,
                path: '',
                billingDate: '',
                isEnable: true,
              });
              DownloadFile(filesKey, document.name).finally(() => {
                /* Nothing to do. */
              });
            }}
          >
            ・{document.name}
            <br />
          </a>
        ));
      },
      align: 'left' as const,
    },
    {
      title: () => (
        <div>
          まとめて
          <br />
          ダウンロード
        </div>
      ),
      dataIndex: 'documentsDownload',
      key: 'documentsDownload',
      width: 100,
      render: (_values: string, record: RequestDataInfo) => (
        <>
          {record.documentList.length > 0 && (
            <Button2
              type="primary"
              onClick={() => {
                const filesKey: FilesKey[] = [];
                filesKey.push({
                  idCust: record.idCust,
                  id: record.id,
                  fileType: 3,
                  no: null,
                  name: '',
                  path: '',
                  billingDate: '',
                  isEnable: true,
                });
                filesKey.push({
                  idCust: record.idCust,
                  id: record.id,
                  fileType: 4,
                  no: null,
                  name: '',
                  path: '',
                  billingDate: '',
                  isEnable: true,
                });
                filesKey.push({
                  idCust: record.idCust,
                  id: record.id,
                  fileType: 5,
                  no: null,
                  name: '',
                  path: '',
                  billingDate: '',
                  isEnable: true,
                });
                filesKey.push({
                  idCust: record.idCust,
                  id: record.id,
                  fileType: 6,
                  no: null,
                  name: '',
                  path: '',
                  billingDate: '',
                  isEnable: true,
                });
                DownloadFile(filesKey, '関連文書').finally(() => {
                  /* Nothing to do. */
                });
              }}
            >
              DL
            </Button2>
          )}
        </>
      ),
      align: 'center' as const,
    },
    {
      title: () => (
        <div>
          <Checkbox
            indeterminate={batchDownloadIndeterminate}
            checked={batchDownloadCheckAll}
            onChange={(e) => {
              if (result) {
                const dataList = [...result];
                const pageData = result.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize,
                );
                for (let index = 0; index < dataList.length; index += 1) {
                  if (pageData.includes(dataList[index])) {
                    dataList[index].batchDownload = e.target.checked;
                  }
                }
                setResult(dataList);
              }
            }}
          >
            一括
          </Checkbox>
          <br />
          ダウンロード
        </div>
      ),
      dataIndex: 'batchDownload',
      key: 'batchDownload',
      width: 100,
      render: (value: boolean, record: RequestDataInfo) => (
        <>
          <Checkbox
            checked={value}
            onChange={(e) => {
              if (result) {
                const data = [...result];
                const idx = data.findIndex((n) => n.id === record.id);
                if (idx !== -1) {
                  data[idx].batchDownload = e.target.checked;
                  setResult(data);
                }
              }
            }}
          />
        </>
      ),
      align: 'center' as const,
    },
  ];

  const columnsMaster = [
    ...masterColum1,
    ...columns1,
    ...columns2,
    ...columns3,
    ...masterColum2,
    ...columns4,
  ];
  const columnsUser = [...columns1, ...columns3, ...columns4];

  // 一括ダウンロード
  const runBatchDownload = () => {
    if (result) {
      // TODO: 一括ダウンロードの処理を記述する
      // チェックが付いているデータを取得する
      const downloadTarget = result.filter((n) => n.batchDownload);
      debugLog(downloadTarget);
      if (
        downloadTarget.length === 0 ||
        (downloadTarget.length !== 0 &&
          !downloadTarget.find((n) => n.documentList.length !== 0))
      ) {
        const text = `ダウンロード対象がありません。¥n一括ダウンロードの対象を選択してください。¥nまた、一括ダウンロードできるのは関連文書のみです。`;
        const breakedText = text.split('¥n').map((line) => (
          <span key={line}>
            {line}
            <br />
          </span>
        ));
        setConfirmMessages(breakedText);
        setCommDlg(true);

        return;
      }
      const filesKey: FilesKey[] = [];
      downloadTarget.forEach((data) => {
        data.documentList?.forEach((document) => {
          filesKey.push({
            idCust: data.idCust,
            id: data.id,
            fileType: document.fileType,
            no: document.no,
            name: document.name,
            path: '',
            billingDate: '',
            isEnable: true,
          });
        });
      });
      DownloadFile(filesKey, '一括').finally(() => {
        /* Nothing to do. */
      });
    }
  };

  useEffect(() => {
    if (result) {
      const pageData = result.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize,
      );
      const downloadTarget = pageData.filter((n) => n.batchDownload);
      if (downloadTarget.length > 0) {
        if (downloadTarget.length === pageData.length) {
          setBatchDownloadIndeterminate(false);
          setBatchDownloadCheckAll(true);
        } else {
          setBatchDownloadIndeterminate(true);
          setBatchDownloadCheckAll(false);
        }
      } else {
        setBatchDownloadIndeterminate(false);
        setBatchDownloadCheckAll(false);
      }
    }
  }, [currentPage, pageSize, result]);

  useEffect(() => {
    if (result) {
      const data = [...result];
      setRequestData(data);
    }
  }, [result]);

  useEffect(() => {
    if (appContext.state.LoginUser.isMaster) {
      if (width < 1180) {
        setGridHeight(height - 500);
      } else if (width < 1270) {
        setGridHeight(height - 750);
      } else if (width < 1710) {
        setGridHeight(height - 690);
      } else {
        setGridHeight(height - 640);
      }
    } else if (width < 740) {
      setGridHeight(height - 860);
    } else if (width < 1175) {
      setGridHeight(height - 810);
    } else if (width < 1270) {
      setGridHeight(height - 700);
    } else if (width < 1800) {
      setGridHeight(height - 640);
    } else {
      setGridHeight(height - 580);
    }
  }, [width, height, appContext.state.LoginUser.isMaster]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            className={classes.grid}
          >
            <div className={classes.dispCount}>
              {requestData && `件数：${requestData?.length}件`}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="right">
            <MuiThemeProvider theme={theme2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={runBatchDownload}
              >
                一括DL
              </Button>
            </MuiThemeProvider>
          </Box>
        </Grid>
      </Grid>
      <div className="staff-select">
        <Table
          showSorterTooltip={{ title: '' }}
          dataSource={requestData}
          columns={
            appContext.state.LoginUser.isMaster ? columnsMaster : columnsUser
          }
          className={classes.line}
          onChange={(pagination, _filters) => {
            setCurrentPage(pagination.current ? pagination.current : 1);
          }}
          rowKey="id"
          pagination={{ pageSize }}
          scroll={{ x: 1500, y: 500 }}
        />
      </div>
      <CommonDialog
        msg={confirmMessages}
        isOpen={commDlg}
        doYes={() => {
          setCommDlg(false);
        }}
        doNo={() => {
          setCommDlg(false);
        }}
        okOnly
      />
    </>
  );
};

export default RequestListPanel;
