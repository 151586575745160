import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { API_URL } from 'utils/CommonConst';
import { consoleLog, methodGet } from 'utils/CommonFunctions';
import { PropTypes } from '@material-ui/core';
import { Organization } from '../../types/Organization';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1, 0, 1),
      minWidth: 200,
    },
  }),
);

/* non eslint-disable */
export type RegalPersonalityInputProps = {
  title?: string;
  margin?: PropTypes.Margin | undefined;
  checkValue?: number;
  selectValue?: unknown;
  readonly?: boolean;
  onChangeCheck?: ChangeEventHandler<HTMLInputElement>;
  onChangeSelect?: ChangeEventHandler<{ value: unknown }>;
};

const RegalPersonalityInput: FC<RegalPersonalityInputProps> = ({
  title = '',
  margin = 'normal',
  checkValue,
  selectValue,
  readonly = false,
  onChangeCheck,
  onChangeSelect,
}) => {
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const url = `${API_URL}api/v1/Masters/organization`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as Organization[];
        setOrganizations(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    getData().finally(() => {
      /* Nothing to do. */
    });
  }, []);

  useEffect(() => {
    switch (checkValue) {
      case 0:
        setIsDisabled(true);
        break;
      default:
        setIsDisabled(false);
        break;
    }
  }, [checkValue]);

  return (
    <>
      <FormControl>
        <FormLabel>{title}</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          value={checkValue}
          onChange={onChangeCheck}
        >
          <FormControlLabel
            value={0}
            control={<Radio color="primary" />}
            label="つけない"
            disabled={readonly}
          />
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label="前"
            disabled={readonly}
          />
          <FormControlLabel
            value={2}
            control={<Radio color="primary" />}
            label="後"
            disabled={readonly}
          />
        </RadioGroup>
      </FormControl>
      <br />
      <FormControl
        variant="outlined"
        className={classes.formControl}
        margin={margin}
      >
        <InputLabel>{title}</InputLabel>
        <Select
          value={selectValue === 0 ? '' : selectValue}
          onChange={onChangeSelect}
          label={title}
          readOnly={readonly}
          disabled={isDisabled}
        >
          {organizations?.map((item) => (
            <MenuItem
              key={item.orgID}
              value={item.orgID === 0 ? '' : `${item.orgID ?? ''}`}
            >
              {item?.orgName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default RegalPersonalityInput;
