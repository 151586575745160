import { FC, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Table } from 'antd';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RequestDataInfo } from 'types/RequestDataInfo';
import { FilesKey } from 'types/Files';
import { downloadData } from 'utils/CommonFunctions';
import { useNavigate } from 'react-router';
import { rootDir } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import useWindowSize from 'utils/useWindowSize';
/* non eslint-disable */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      height: '100%',
    },
    header: {
      textAlign: 'center',
    },
    dataIndentLeft: {
      marginLeft: theme.spacing(1),
    },
    statusIcon: {
      height: '21px',
      marginRight: 5,
    },
  }),
);

export type RequestListPanelTopProps = {
  result: RequestDataInfo[] | undefined;
};

const RequestListPanelTop: FC<RequestListPanelTopProps> = ({ result }) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [width, height] = useWindowSize();
  const [gridHeight, setGridHeight] = useState<number>(300);

  const navigate = useNavigate();
  const [requestData, setRequestData] = useState<RequestDataInfo[] | undefined>(
    result,
  );

  // ダウンロード
  const DownloadFile = async (key: FilesKey[], fileName: string) => {
    if (key.length > 0) {
      // ダウンロード要求
      await downloadData(key, fileName, appContext.state.LoginUser);
    } else {
      /*
      void message.warning(
        'ファイル情報を設定してください。',
      );
      */
    }
  };
  const masterColum1 = [
    {
      title: () => (
        <div>
          顧客
          <br />
          番号
        </div>
      ),
      dataIndex: 'custData',
      key: 'custData',
      width: 60,
      fixed: true,
      align: 'center' as const,
      // eslint-disable-next-line
      sorter: (a: any, b: any) => a.custData - b.custData,
    },
    {
      title: '顧客名',
      dataIndex: 'custName',
      key: 'custName',
      fixed: true,
      width: 200,
      align: 'center' as const,
    },
  ];
  const columns1 = [
    {
      title: () => (
        <div>
          依頼
          <br />
          No
        </div>
      ),
      dataIndex: 'no',
      key: 'no',
      width: 60,
      fixed: true,
      align: 'center' as const,
      // eslint-disable-next-line
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: '依頼日',
      dataIndex: 'rcptCreate',
      key: 'rcptCreate',
      width: 100,
      fixed: true,
      render: (value: string, record: RequestDataInfo) => (
        // eslint-disable-next-line
        <a
          onClick={() => {
            navigate(`${rootDir}requestInfo`, {
              state: {
                id: record.id,
                idCust: record.idCust,
                recieptType: null,
              },
            });
          }}
        >
          {value}
        </a>
      ),
      align: 'center' as const,
    },
    {
      title: () => <div className={classes.header}>状態</div>,
      dataIndex: 'process',
      key: 'process',
      width: 145,
      align: 'left' as const,
      render: (value: string) => {
        switch (value) {
          case '下書き（依頼前）':
            return (
              <span>
                <img
                  src="state-0.png?20230330"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case 'Web依頼申込':
            return (
              <span>
                <img
                  src="state-1.png?20220302"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case 'Web受付完了':
            return (
              <span>
                <img
                  src="state-2.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '検体到着':
            return (
              <span>
                <img
                  src="state-3.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '受入（検査中）':
            return (
              <span>
                <img
                  src="state-4.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '速報送付済':
            return (
              <span>
                <img
                  src="state-5.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '一部検査完了':
            return (
              <span>
                <img
                  src="state-9.png?20230301"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '検査完了':
            return (
              <span>
                <img
                  src="state-6.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                {value}
              </span>
            );
          case '納品書送付済':
            return (
              <span>
                <img
                  src="state-6.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                検査完了
              </span>
            );
          case '請求書送付済':
            return (
              <span>
                <img
                  src="state-6.png?20220304"
                  alt="アイコン"
                  className={classes.statusIcon}
                />
                検査完了
              </span>
            );
          default:
            return '';
        }
      },
    },
    {
      title: () => <div className={classes.header}>担当者</div>,
      dataIndex: 'picList',
      key: 'picList',
      width: 120,
      render: (
        values: { id: number; name: string }[],
        _record: RequestDataInfo,
        index: number,
      ) => {
        const pics: { id: number; name: string }[] = values;

        return pics?.map((pic, index2) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${pic.id}-${pic.name}-${index2}`}
            className={classes.dataIndentLeft}
          >
            {pic.name}
            <br />
          </span>
        ));
      },
      align: 'left' as const,
    },
    {
      title: '検査区分',
      dataIndex: 'recieptType1',
      key: 'recieptType1',
      align: 'center' as const,
      width: 150,
    },
    {
      title: () => <div className={classes.header}>試験品名</div>,
      dataIndex: 'sampleList',
      key: 'sampleList',
      render: (
        values: { name: string }[],
        _record: RequestDataInfo,
        index: number,
      ) => {
        const samples: { name: string }[] = values;

        return samples?.map((sample, index2) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${sample.name}-${index2}`}
            className={classes.dataIndentLeft}
          >
            {sample.name}
            <br />
          </span>
        ));
      },
      width: 250,
      align: 'left' as const,
    },
    {
      title: '検体数',
      dataIndex: 'sampleNum',
      key: 'sampleNum',
      width: 70,
      align: 'center' as const,
    },
    {
      title: () => <div className={classes.header}>添付ファイル</div>,
      dataIndex: 'attachmentList',
      key: 'attachmentList',
      width: 120,
      render: (
        values: { no: number; name: string; fileType: number }[],
        record: RequestDataInfo,
        index: number,
      ) => {
        const attachments: { no: number; name: string; fileType: number }[] =
          values;

        return attachments?.map((attachment, index2) => (
          // eslint-disable-next-line
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${attachment.name}-${index2}`}
            className={classes.dataIndentLeft}
            onClick={() => {
              const fileKey: FilesKey = {
                idCust: record.idCust,
                id: record.id,
                fileType: attachment.fileType,
                no: attachment.no,
                name: attachment.name,
                path: '',
                billingDate: '',
                isEnable: true,
              };
              const filesKey: FilesKey[] = [];
              filesKey.push(fileKey);
              DownloadFile(filesKey, attachment.name).finally(() => {
                /* Nothing to do. */
              });
            }}
          >
            ・{attachment.name}
            <br />
          </a>
        ));
      },
      align: 'left' as const,
    },
  ];

  const columns2 = [
    {
      title: () => <div className={classes.header}>関連文書</div>,
      dataIndex: 'documentList',
      key: 'documentList',
      width: 200,
      render: (
        values: { no: number; name: string; fileType: number }[],
        record: RequestDataInfo,
        index: number,
      ) => {
        const documents: { no: number; name: string; fileType: number }[] =
          values;

        return documents?.map((document, index2) => (
          // eslint-disable-next-line
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${document.fileType}-${document.no}-${document.name}-${index2}`}
            className={classes.dataIndentLeft}
            onClick={() => {
              const filesKey: FilesKey[] = [];
              filesKey.push({
                idCust: record.idCust,
                id: record.id,
                fileType: document.fileType,
                no: document.no,
                name: document.name,
                path: '',
                billingDate: '',
                isEnable: true,
              });
              DownloadFile(filesKey, document.name).finally(() => {
                /* Nothing to do. */
              });
            }}
          >
            ・{document.name}
            <br />
          </a>
        ));
      },
      align: 'left' as const,
    },
  ];

  const columnsMaster = [...masterColum1, ...columns1, ...columns2];
  const columnsUser = [...columns1, ...columns2];

  useEffect(() => {
    if (result) {
      const data = [...result];
      setRequestData(data);
    }
  }, [result]);

  useEffect(() => {
    setGridHeight(height - 370);
  }, [width, height]);

  return (
    <>
      <Table
        showSorterTooltip={{ title: '' }}
        dataSource={requestData}
        columns={
          appContext.state.LoginUser.isMaster ? columnsMaster : columnsUser
        }
        className={clsx('staff-select', classes.list)}
        rowKey="id"
        pagination={{ pageSize: 100 }}
        scroll={{ y: gridHeight }}
      />
    </>
  );
};

export default RequestListPanelTop;
