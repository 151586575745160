import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { StaffListModel, PicList } from 'types/User';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { API_URL } from 'utils/CommonConst';
import { Box } from '@material-ui/core';
import { consoleLog, methodPost } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import CustomTextField2 from './CustomTextField2';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    space: {
      margin: theme.spacing(3, 5, 0),
    },
    line: {
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: '#1769aa',
      },
    },
    dialogTitle: {
      fontSize: 24,
    },
    submit: {
      maxWidth: 110,
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: 'darkgray',
      },
    },
  }),
);

export type StaffTempRegistrationDialogProps = {
  // eslint-disable-next-line
  text?: any;
  button?: string;
  open: boolean;
  onClickClose: (handleClickInsert: boolean, insertData?: PicList) => void;
  register: boolean;
  accountName?: string;
  accountMail?: string;
  accountTell?: string;
};

const StaffTempRegistrationDialog: FC<StaffTempRegistrationDialogProps> = ({
  text = 'normal',
  button = '',
  open,
  onClickClose,
  register,
  accountName = '',
  accountMail = '',
  accountTell = '',
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const mailCheck =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
  const telCheck = /\d{2,4}-\d{2,4}-\d{4}$/;

  const [name, setName] = useState('');
  const [section, setSection] = useState('');
  const [mail, setMail] = useState('');
  const [confirMail, setConfirMail] = useState('');
  const [tel, setTel] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [mode, setMode] = useState<Mode>('info');

  const changedName = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);
  const changedSection = (event: ChangeEvent<HTMLInputElement>) =>
    setSection(event.target.value);
  const changedMail = (event: ChangeEvent<HTMLInputElement>) =>
    setMail(event.target.value);
  const changedConfirMail = (event: ChangeEvent<HTMLInputElement>) =>
    setConfirMail(event.target.value);
  const changedTel = (event: ChangeEvent<HTMLInputElement>) =>
    setTel(event.target.value);

  const insert: StaffListModel = {
    idCust: appContext.state.LoginUser.idCust,
    picID: null,
    name,
    section,
    mail,
    tel,
    sortNo: null,
    isEnable: false,
    isDeleted: false,
  };

  const postData = async () => {
    try {
      const url = `${API_URL}api/v1/users/${
        appContext.state.LoginUser.idCust ?? '0'
      }/staffs`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(
        url,
        insert,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const resp = res.data as boolean;
      if (resp) {
        setName('');
        setSection('');
        setMail('');
        setConfirMail('');
        setTel('');
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const handleClickInsert = () => {
    let error = false;
    const message: string[] = [];
    if (name === '') {
      message.push('・担当者名が入力されていません。');
      error = true;
    }
    if (mail === '') {
      message.push('・メールアドレスが入力されていません。');
      error = true;
    }
    if (tel === '') {
      message.push('・電話番号が入力されていません。');
      error = true;
    }
    if (mail !== '' && !mailCheck.test(mail)) {
      message.push('・メールアドレスの形式になっていません。');
      error = true;
    }
    if (tel !== '' && !telCheck.test(tel)) {
      message.push('・電話番号は半角数字と半角ハイフンで入力してください。');
      message.push('　例）083-941-6300');
      error = true;
    }
    if (mail !== '' && mail !== confirMail) {
      message.push('・メールアドレス（確認）が一致しません。');
      error = true;
    }
    if (error) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(message);

      return;
    }
    if (register) {
      postData().finally(() => {
        onClickClose(true);
        /* Nothing to do. */
      });
    } else {
      const data = { ...insert } as PicList;
      onClickClose(true, data);
      setName('');
      setSection('');
      setMail('');
      setConfirMail('');
      setTel('');
    }
    setIsHide(true);
  };

  const handleClickCancel = () => {
    setIsHide(true);
    setName('');
    setSection('');
    setMail('');
    setConfirMail('');
    setTel('');
    onClickClose(false);
  };

  useEffect(() => {
    if (open) {
      setName(accountName);
      setMail(accountMail);
      setConfirMail(accountMail);
      setTel(accountTell);
    }
  }, [accountName, accountMail, accountTell, open]);

  return (
    <>
      <Dialog open={open} onClose={handleClickCancel}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>担当者仮登録</Box>
        </DialogTitle>
        <DialogContentText className={classes.space}>{text}</DialogContentText>
        <DialogContent>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form}>
            <CustomTextField2
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="name"
              label="担当者名"
              value={name}
              name="name"
              autoComplete="name"
              onChange={changedName}
              inputProps={{ maxLength: 20 }}
            />
            <CustomTextField2
              variant="outlined"
              margin="dense"
              fullWidth
              id="section"
              label="所属"
              value={section}
              name="section"
              autoComplete="section"
              onChange={changedSection}
            />
            <CustomTextField2
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="mail"
              label="メールアドレス"
              value={mail}
              name="mail"
              autoComplete="mail"
              onChange={changedMail}
              inputProps={{ maxLength: 50 }}
            />
            <CustomTextField2
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="confirMail"
              label="メールアドレス（確認）"
              value={confirMail}
              name="confirMail"
              autoComplete="confirMail"
              onChange={changedConfirMail}
              inputProps={{ maxLength: 50 }}
            />
            <CustomTextField2
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="tel"
              label="電話番号(ﾊｲﾌﾝ有)"
              value={tel}
              name="tel"
              autoComplete="tel"
              onChange={changedTel}
              inputProps={{ maxLength: 20 }}
            />
          </div>
        </DialogContent>
        <MuiThemeProvider theme={theme2}>
          <DialogActions>
            <MuiThemeProvider theme={theme3}>
              <Button
                variant="contained"
                onClick={handleClickInsert}
                color="primary"
                className={classes.line}
              >
                {button}
              </Button>
            </MuiThemeProvider>
            <Button
              variant="contained"
              onClick={handleClickCancel}
              color="primary"
              className={classes.submit}
            >
              キャンセル
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>
    </>
  );
};

export default StaffTempRegistrationDialog;
