import { FC, useState, ChangeEvent, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Auth } from 'aws-amplify';
import MessagePanel from 'components/molecules/MessagePanel';
import {
  consoleLog,
  debugLog,
  loginCheckFunc,
  loginLockCheckFunc,
} from 'utils/CommonFunctions';
import validateToken from 'utils/helpers';
import { rootDir } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import CustomTextField from 'components/molecules/CustomTextField';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';
import AUTH_USER_TOKEN_KEY from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    margin_top: {
      marginTop: theme.spacing(6),
    },
  }),
);

const LoginPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const location = useLocation();
  const { from } = (location.state as { from: Location }) || {
    from: { pathname: '/top' },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const changedUserNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };
  const changedPasswordHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const signIn = async () => {
    let loading = true;
    setIsLoading(true);
    try {
      /* eslint-disable  */
      if (username === '') {
        setIsHide(false);
        setErrorMessages(['ログインIDが入力されていません。']);
        debugLog('ログインIDが入力されていません');

        return;

        // [Fix]UnexpectedLambdaException:null invocation failed due to configuration.
      }
      if (password === '') {
        setIsHide(false);
        setErrorMessages(['パスワードが入力されていません。']);
        debugLog('パスワードが入力されていません');

        return;
      }

      const check = await loginCheckFunc(username);
      if (check !== 0) {
        if (check === -1) {
          setIsHide(false);
          setErrorMessages(['ログインIDが無効です。']);
        } else if (check === 1) {
          setIsHide(false);
          setErrorMessages(['ログインIDが無効です。']);
        } else if (check === 2) {
          setIsHide(false);
          setErrorMessages([
            'アカウントは承認処理待ちです。',
            '承認までしばらく時間がかかることがあります。',
          ]);
        } else if (check === 3) {
          setIsHide(false);
          setErrorMessages([
            'アカウントがロックされています。',
            'パスワード初期化を行ってください。',
          ]);
        }

        return;
      }

      try {
        const user = await Auth.signIn(username, password);

        // ref. https://aws-amplify.github.io/docs/js/authentication#customize-your-own-components
        if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          // Do something...
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // Do something...
        } else if (user.challengeName === 'MFA_SETUP') {
          // Do something...
        } else {
          const res = (await loginLockCheckFunc(username, 0, '')) as boolean;
          if (!res) {
            setIsHide(false);
            setErrorMessages(['予期しないエラーが発生しました。']);
          }
          // DBのユーザーテーブルのチェック処理
          // ログイン失敗回数やロック状態のチェック
          // ------------------------------------------------------------------------------------------------
          // 問題なければログイン成功として、失敗回数等をリセットする
          // 問題があれば、ログイン失敗として、cognito もサインアウトする
          localStorage.setItem(
            AUTH_USER_TOKEN_KEY,
            user.signInUserSession.accessToken.jwtToken,
          );
          const state = { ...appContext.state };
          state.LoginUser = {
            isLogin: true,
            idCust: user.attributes['custom:IdCust'],
            idMaster: user.attributes['custom:IdMaster'],
            isMaster: user.attributes['custom:authority'] === 'master',
            loginID: user.attributes['preferred_username'],
          };
          appContext.setState(state);
          debugLog(`ログインしました`);
          setIsLoading(false);
          loading = false;
          navigate(from);
        }
      } catch (err: any) {
        switch (err.code) {
          case 'UserNotConfirmedException':
            // ユーザのステータスが UNCONFIRMED の場合に起こる。
            // SignUp用のコードを再送し、ステータスを CONFIRMED にする必要がある。
            // 検証コードの再送は １．３節の ResendConfirmationCode() を参照。
            setIsHide(false);
            setErrorMessages(['ユーザー登録の途中です。']);
            debugLog('ユーザー登録の途中です');
            debugLog('UserNotConfirmedException');
            break;
          case 'PasswordResetRequiredException':
            // Cognito コンソールでパスワードをリセット（ユーザープールにユーザをインポートする場合も含む）した場合に起こる。
            // パスワードをリセットする必要がある。
            // パスワードのリセットは 3.1節の SendForgotPasswordCode() 参照。
            setIsHide(false);
            setErrorMessages(['パスワードを変更する必要があります。']);
            debugLog('パスワードを変更する必要があります');
            debugLog('PasswordResetRequiredException');
            break;
          case 'NotAuthorizedException':
            // 誤ったパスワードを入力した場合に起こる。
            // 注) パスワードを間違え続けた場合にも起こり、 error.message が 'Password attempts exceeded' になる。
            // （エラーコードとして LimitExceededException が返ってくると思ったらそうではなかった）
            setIsHide(false);
            setErrorMessages([
              'ログインIDまたはパスワードが正しくありません。',
            ]);
            debugLog('ログインIDまたはパスワードが正しくありません');
            debugLog('NotAuthorizedException');
            const res = (await loginLockCheckFunc(
              username,
              1,
              password,
            )) as boolean;
            if (!res) {
              setIsHide(false);
              setErrorMessages(['予期しないエラーが発生しました。']);
            }

            break;
          case 'UserNotFoundException':
            // PASSWORD_VERIFIER は通るものの username が Cognito ユーザープールに存在しない場合に起こる。
            setIsHide(false);
            setErrorMessages([
              'ログインIDまたはパスワードが正しくありません２。',
            ]);
            debugLog('ログインIDまたはパスワードが正しくありません');
            debugLog('UserNotFoundException');
            break;
          case 'InvalidParameterException':
            // 入力された username や password が Cognito 側で正しくパースできないとき（バリデーションエラー）に起こる。
            // 注) 2019/04/24 現在、Cognito コンソールでパスワードをリセットした場合は 'PasswordResetRequiredException' ではなくこのエラーコードが返される。
            setIsHide(false);
            setErrorMessages([
              'ログインIDまたはパスワードが正しくありません３。',
            ]);
            debugLog('ログインIDまたはパスワードが正しくありません');
            debugLog('NotAuthorizedException');
            break;
          default:
            // その他のエラー
            setIsHide(false);
            setErrorMessages(['予期しないエラーが発生しました。']);
            consoleLog('予期しないエラーが発生しました');
            consoleLog(err);
            consoleLog('Unknowned error');
            break;
        }
      }
    } finally {
      if (loading) setIsLoading(false);
    }

    /* eslint-enable */
  };

  // eslint-disable-next-line
  const handleKeyDown = async (e: any) => {
    // eslint-disable-next-line
    if (e.key === 'Enter') {
      // エンターキーが押された時の処理
      await signIn();
    }
  };

  useEffect(() => {
    const checkUserAuth = validateToken(
      localStorage.getItem(AUTH_USER_TOKEN_KEY),
    );
    if (!checkUserAuth) {
      const state = { ...appContext.state };
      state.LoginUser = {
        isLogin: false,
        idCust: 0,
        idMaster: 0,
        isMaster: false,
        loginID: '',
      };
      appContext.setState(state);
    }
    // eslint-disable-next-line
    debugLog(`トークン確認？`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            ログイン
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} />
          <div className={classes.form}>
            <CustomTextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="userID"
              name="userID"
              label="ログインID"
              autoFocus
              value={username}
              onChange={changedUserNameHandler}
              disabled={isLoading}
            />
            <CustomTextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="password"
              name="password"
              label="パスワード"
              type="password"
              value={password}
              onChange={changedPasswordHandler}
              onKeyDown={(e) => handleKeyDown(e)}
              disabled={isLoading}
            />
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={signIn}
                disabled={isLoading}
              >
                ログイン
              </Button>
            </Box>
            <Box textAlign="center" className={classes.margin_top}>
              初めてシステムを利用される方は、
              <Link to={`${rootDir}applicationForUse`}>
                こちらから登録してください。
              </Link>
            </Box>
            <Box textAlign="center" className={classes.margin_top}>
              <Link to={`${rootDir}passwordInitialization`}>
                パスワード初期化
              </Link>
            </Box>
          </div>
        </div>
      </Container>
      <CustomBackdrop open={isLoading} />
    </HeaderTemplate>
  );
};

export default LoginPage;
