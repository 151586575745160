import { FC, useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './Panel.css';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { Table, Button as Button2 } from 'antd';
import FileUploadDialog from 'components/molecules/FileUploadDialog';
import { FileInfo, FilesKey } from 'types/Files';
import { Request, UpdateProcess } from 'types/RequestDataInfo';
import {
  consoleLog,
  deleteFile,
  downloadData,
  methodPut,
  releaseFile,
} from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import { API_URL } from 'utils/CommonConst';
import CommonDialog from './CommonDialog';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    space: {
      margin: theme.spacing(0, 2, 0),
    },
    submit: {
      margin: theme.spacing(0, 3, 1),
      '&:hover': {
        background: 'darkorange',
      },
    },
    interval: {
      margin: theme.spacing(3, 0, 1),
    },
  }),
);

export type FileListPanelProps = {
  title?: string;
  fileType: number;
  requestData?: Request;
  updateRequest: (value: Request) => void;
  dataLoad: () => void;
};
const FileListPanel: FC<FileListPanelProps> = ({
  title = '',
  fileType,
  requestData,
  updateRequest,
  dataLoad,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [openFile, setOpenFile] = useState(false);
  const [tempequestData, setTempRequestData] = useState<Request>();
  const [fileList, setFileList] = useState<FileInfo[]>([]);
  const [commDlg, setCommDlg] = useState(false);
  const [commDlg2, setCommDlg2] = useState(false);
  const [commDlg3, setCommDlg3] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();
  const [confirmMessages2, setConfirmMessages2] = useState<JSX.Element[]>();
  const [confirmMessages3, setConfirmMessages3] = useState<JSX.Element[]>();
  const [commDlgProc, setCommDlgProc] = useState('del');
  const [targetFilesKey, setTargetFilesKey] = useState<FilesKey[]>([]);
  const [code, setCode] = useState<number>(0);

  const handleClickOpenFile = () => {
    setOpenFile(true);
  };

  const handleCloseFile = () => {
    setOpenFile(false);
  };

  // ダウンロード
  const DownloadFile = async (key: FilesKey[], fileName: string) => {
    if (key.length > 0) {
      // ダウンロード要求
      await downloadData(key, fileName, appContext.state.LoginUser);
    } else {
      /*
          void message.warning(
            'ファイル情報を設定してください。',
          );
          */
    }
  };

  useEffect(() => {
    setTempRequestData(requestData);
  }, [requestData]);

  useEffect(() => {
    if (tempequestData && tempequestData.documentList) {
      const list = [...tempequestData.documentList];
      const data = list.filter((n) => n.fileType === fileType);
      setFileList(data);
      updateRequest(tempequestData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempequestData, fileType]);

  const releaseF = async () => {
    const res: boolean = await releaseFile(
      targetFilesKey,
      appContext.state.LoginUser,
    );
    setCommDlg(false);
    if (res) {
      // 成功
      // --------------------------------------------------表の公開フラグ更新処理
      if (tempequestData) {
        const data = { ...tempequestData } as Request;
        data.documentList = [...tempequestData.documentList];
        data.documentList = data.documentList.map((document) => {
          if (
            document.fileType === targetFilesKey[0].fileType &&
            document.no === targetFilesKey[0].no
          ) {
            const doc = { ...document };
            doc.isEnable = true;

            return doc;
          }

          return document;
        });
        setTempRequestData(data);

        if (fileType !== 5) {
          const text = `状況を更新しますか？`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));

          setCommDlgProc('statusup');
          setConfirmMessages(breakedText);
          setCommDlg(true);
        }
      }
    }
  };

  const deleteF = async () => {
    const res: boolean = await deleteFile(
      targetFilesKey,
      appContext.state.LoginUser,
    );
    setCommDlg(false);
    if (res) {
      // 成功
      if (tempequestData) {
        const data = { ...tempequestData } as Request;
        data.documentList = [...tempequestData.documentList];
        data.documentList = data.documentList.filter(
          (n) =>
            !(
              n.no === targetFilesKey[0].no &&
              n.fileType === targetFilesKey[0].fileType
            ),
        );
        setTempRequestData(data);
      }
    }
  };

  const updateData = () => {
    try {
      if (requestData) {
        if (fileType === 4 && requestData.sampleList.length > 1) {
          const text = `ステータスを選択してください。`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));

          setCommDlg(false);
          setConfirmMessages2(breakedText);
          setCommDlg2(true);
        } else if (
          fileType === 6 &&
          !requestData.processList?.find((n) => n.code === 5)
        ) {
          const text = `処理を選択してください。`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));

          setCommDlg(false);
          setConfirmMessages3(breakedText);
          setCommDlg3(true);
        } else {
          setCode(fileType + 1);
          setCommDlg(false);
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const updateData2 = async () => {
    try {
      if (requestData) {
        const date = new Date();
        const updateDate = `${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()}`;

        const putData: UpdateProcess = {
          idCustData: requestData?.idCust,
          id: requestData?.id,
          code,
          processDate: updateDate,
        };
        const url = `${API_URL}api/v1/requests/process`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodPut(
          url,
          putData,
          true,
          appContext.state.LoginUser,
        );
        setCommDlg2(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        if (data) {
          dataLoad();
        } else {
          consoleLog('状況の更新に失敗しました。');
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const updateData3 = async () => {
    try {
      if (requestData) {
        const date = new Date();
        const updateDate = `${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()}`;

        const putData: UpdateProcess = {
          idCustData: requestData?.idCust,
          id: requestData?.id,
          code: 7,
          processDate: updateDate,
        };
        const url = `${API_URL}api/v1/requests/processEnd`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodPut(
          url,
          putData,
          true,
          appContext.state.LoginUser,
        );
        setCommDlg3(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        if (data) {
          dataLoad();
        } else {
          consoleLog('状況の更新に失敗しました。');
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  useEffect(() => {
    if (code !== 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateData2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  /* eslint-disable */
  const columns = [
    {
      title: '日付',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      align: 'center' as const,
      width: 250,
    },
    {
      title: 'ファイル名',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as const,
      render: (value: string, record: FileInfo, index: number) => (
        <a
          key={`${index}-${value}`}
          onClick={() => {
            const fileKey: FilesKey = {
              idCust: record.idCust,
              id: record.id,
              fileType: record.fileType,
              no: record.no,
              name: record.name,
              path: '',
              billingDate: '',
              isEnable: null,
            };
            const filesKey: FilesKey[] = [];
            filesKey.push(fileKey);
            DownloadFile(filesKey, value).finally(() => {
              /* Nothing to do. */
            });
          }}
        >
          {value}
        </a>
      ),
    },
    {
      title: '状況',
      dataIndex: 'isEnable',
      key: 'isEnable',
      align: 'center' as const,
      render: (value: boolean) => {
        if (value) {
          return '公開済み';
        }

        return '確認待ち';
      },
      width: 150,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 250,
      render: (_value: string, record: FileInfo) => {
        const confirmationRel = () => {
          const text = `${record.name}を公開します。¥nよろしいですか？`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));
          const fileKey: FilesKey = {
            idCust: record.idCust,
            id: record.id,
            fileType: record.fileType,
            no: record.no,
            name: '',
            path: '',
            billingDate: '',
            isEnable: null,
          };
          const filesKey: FilesKey[] = [];
          filesKey.push(fileKey);
          setTargetFilesKey(filesKey);

          setCommDlgProc('rel');
          setConfirmMessages(breakedText);
          setCommDlg(true);
        };

        const confirmationDel = () => {
          const text = `${record.name}を削除します。¥nよろしいですか？`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));
          const fileKey: FilesKey = {
            idCust: tempequestData?.idCust ?? 0,
            id: tempequestData?.id ?? 0,
            fileType: record.fileType,
            no: record.no,
            name: '',
            path: '',
            billingDate: '',
            isEnable: null,
          };
          const filesKey: FilesKey[] = [];
          filesKey.push(fileKey);
          setTargetFilesKey(filesKey);

          setCommDlgProc('del');
          setConfirmMessages(breakedText);
          setCommDlg(true);
        };

        return (
          <>
            {!record.isEnable && (
              <span className="invoiceUpload-button">
                <Button2
                  type="primary"
                  className={classes.space}
                  onClick={confirmationRel}
                >
                  公開
                </Button2>
              </span>
            )}
            <Button2 type="primary" danger onClick={confirmationDel}>
              削除
            </Button2>
          </>
        );
      },
      align: 'center' as const,
    },
  ];

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5" className={classes.interval}>
        {title}
        <MuiThemeProvider theme={theme2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClickOpenFile}
            disabled={fileType === 6 && fileList.length !== 0}
          >
            追加
          </Button>
        </MuiThemeProvider>
      </Typography>
      <FileUploadDialog
        open={openFile}
        onClickClose={handleCloseFile}
        dialogTitle={title}
        fileType={fileType}
        requestData={tempequestData}
        updateRequest={(values: Request) => {
          setTempRequestData(values);
        }}
      />
      <CommonDialog
        msg={confirmMessages}
        isOpen={commDlg}
        doYes={
          commDlgProc === 'del'
            ? deleteF
            : commDlgProc === 'rel'
            ? releaseF
            : updateData
        }
        doNo={() => {
          setCommDlg(false);
        }}
      />
      <CommonDialog
        msg={confirmMessages2}
        isOpen={commDlg2}
        doYes={() => {
          setCode(5);
        }}
        doNo={() => {
          setCode(9);
        }}
        yesWord={'検査完了'}
        noWord={'一部検査完了'}
      />
      <CommonDialog
        msg={confirmMessages3}
        isOpen={commDlg3}
        doYes={() => {
          setCode(7);
        }}
        doNo={() => {
          updateData3();
        }}
        yesWord={'請求書送付済みにする'}
        noWord={'全て完了にする'}
      />
      <div className="table-size">
        <Table
          dataSource={fileList}
          columns={columns}
          rowKey="no"
          pagination={false}
        />
      </div>
    </div>
  );
};

export default FileListPanel;
