import { FC } from 'react';
import 'antd/dist/antd.css';
import './Panel.css';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    width: {
      width: 250,
    },
  }),
);

export type Destination = {
  id: number;
  name: string;
  postalCode: string;
  address1: string;
  address2: string;
  tel: string;
  fax: string;
  pic: string;
};

export type DestinationPanelProps = {
  destinations?: Destination;
};

const DestinationPanel: FC<DestinationPanelProps> = ({ destinations = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <table className="html-table">
        <tbody>
          <tr>
            <th className={classes.width}>名称</th>
            <td>{destinations.name}</td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>{destinations.postalCode}</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>{destinations.address1}</td>
          </tr>
          <tr>
            <th>建物等</th>
            <td>{destinations.address2}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{destinations.tel}</td>
          </tr>
          <tr>
            <th>FAX番号</th>
            <td>{destinations.fax}</td>
          </tr>
          <tr>
            <th>担当者</th>
            <td>{destinations.pic}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DestinationPanel;
