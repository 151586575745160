import { ChangeEventHandler, FC } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { PropTypes } from '@material-ui/core';
import CustomTextField from './CustomTextField';

const theme2 = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 1),
      '&:hover': {
        background: 'gray',
      },
    },
    length: {
      margin: theme.spacing(0, 0, 1),
    },
    line: {
      maxWidth: 600,
    },
  }),
);

export type AccountStaffInputProps = {
  title?: string;
  /* non eslint-disable */
  margin?: PropTypes.Margin | undefined;
  value?: unknown;
  readonly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const AccountStaffInput: FC<AccountStaffInputProps> = ({
  title = '',
  margin = 'normal',
  value,
  readonly = false,
  onChange,
}) => {
  const classes = useStyles();

  const text =
    'アカウント担当者とは ¥nログイン情報を管理する担当者のことです。メールアドレスはパスワードの変更や通知メールの宛先として使用されます。';
  const breakedText = text.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  return (
    <div className={classes.line}>
      <MuiThemeProvider theme={theme2}>
        <Box textAlign="right" hidden={readonly}>
          <Tooltip title={breakedText} placement="right">
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              アカウント担当者とは
            </Button>
          </Tooltip>
        </Box>
      </MuiThemeProvider>
      <CustomTextField
        variant="outlined"
        required
        id="accountStaff"
        label={title}
        name="accountStaff"
        autoComplete="accountStaff"
        autoFocus
        fullWidth
        margin={margin}
        className={classes.length}
        value={value}
        InputProps={{
          readOnly: readonly,
          inputProps: {
            maxLength: 20,
          },
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default AccountStaffInput;
