/* non eslint-disable  */
import { useState, FC, useContext, useCallback, useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { AppContext } from 'utils/AppContext';
import { RequestSearchConditions } from 'types/RequestSearchConditions';
import { API_URL, rootDir } from 'utils/CommonConst';
import {
  consoleLog,
  methodGet,
  methodPost,
  signOut,
} from 'utils/CommonFunctions';
import { RecieptType } from 'types/RecieptType';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Accordion,
  // AccordionProps,
  AccordionSummary,
  // AccordionSummaryProps,
  AccordionDetails,
  colors,
} from '@material-ui/core';
import RequestListPanelTop from 'components/molecules/RequestListPanelTop';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NewsInfo } from 'types/News';
import HeaderTemplate from '../templates/HeaderTemplate';
import { RequestDataInfo } from '../../types/RequestDataInfo';

const theme2 = createTheme({
  overrides: {
    MuiAccordion: {
      root: {
        marginTop: 1,
        backgroundColor: '#1a237e',
        color: 'white',
        boxShadow:
          '0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%)',
      },
    },
    MuiAccordionSummary: {
      content: {
        fontSize: 20,
        fontFamily: '游ゴシック体',
      },
    },
    MuiAccordionDetails: {
      root: {
        fontSize: 18,
        fontFamily: '游ゴシック体',
        backgroundColor: '#fff',
      },
    },
    MuiListItem: {
      root: {
        boxShadow:
          '0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%)',
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 20,
        fontFamily: '游ゴシック体',
      },
    },
  },
  palette: {
    // primary: { main: colors.blue[500] }, // テーマの色
    secondary: { main: colors.yellow[50] }, // テーマの色
  },
});

const theme3 = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 20,
        fontFamily: '游ゴシック体',
      },
    },
  },
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    requestList: {
      marginLeft: theme.spacing(2),
    },
    requestListHead: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    requestListHeadLink: {
      marginLeft: theme.spacing(2),
    },
    newsRed: {
      color: 'red',
    },
    newsBlack: {
      color: 'black',
    },
    list: {
      backgroundColor: '#1a237e',
      color: 'white',
      width: '100%',
    },
    listInList: {
      color: 'black',
    },
  }),
);

const TopPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState<RequestDataInfo[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [newsInfo, setNewsInfo] = useState<NewsInfo>();

  const accessLevel = appContext.state.LoginUser.isMaster ? 0 : 1;

  const [recieptTypes, setRecieptTypes] = useState<RecieptType[] | null>(null);

  useEffect(() => {
    // 検査区分マスタの取得・設定
    const getRecieptType = async () => {
      try {
        const url = `${API_URL}api/v1/masters/recieptttype`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as RecieptType[];
        setRecieptTypes(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    const getData = async () => {
      if (appContext.state.LoginUser.isLogin) {
        try {
          const url = `${API_URL}api/v1/masters/news/`;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const res = await methodGet(url, true, appContext.state.LoginUser);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = res.data as NewsInfo;
          setNewsInfo(data);
        } catch (ex) {
          consoleLog(ex);
        }
      }
    };

    getRecieptType().finally(() => {
      /* Nothing to do. */
    });
    getData().finally(() => {
      /* Nothing to do. */
    });
  }, [appContext.state.LoginUser]);

  // 検索処理
  const requestSearch = useCallback(
    (conditions: RequestSearchConditions) => {
      const getData = async () => {
        try {
          const url = `${API_URL}api/v1/requests/get`;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const res = await methodPost(
            url,
            conditions,
            true,
            appContext.state.LoginUser,
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = res.data as RequestDataInfo[];
          if (data.length > 0) {
            setRequestData(data);
          } else {
            setRequestData(data);
          }
        } catch (ex) {
          consoleLog(ex);
        }
      };
      if (appContext.state.LoginUser.isLogin) {
        setIsLoading(true);
        getData().finally(() => {
          /* Nothing to do. */
          setIsLoading(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appContext.state.LoginUser],
  );

  useEffect(() => {
    // 通常検索
    const conditions: RequestSearchConditions = {
      custData: '',
      custName: '',
      sRcptCreate: '',
      eRcptCreate: '',
      recieptType: null,
      process: null,
      mesItem: '',
      nameSampleIndex: '',
      pic: '',
      isMaster: appContext.state.LoginUser.isMaster,
      idCust: appContext.state.LoginUser.idCust,
    };
    requestSearch(conditions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.state.LoginUser]);

  const logout = async () => {
    await signOut();
    navigate(`${rootDir}`);
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="xl">
        <div className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <MuiThemeProvider theme={theme2}>
                  <List className={classes.list} hidden={isLoading}>
                    {appContext.state.LoginUser.isLogin && (
                      <ListItem button>
                        {/* <ListItemIcon></ListItemIcon> */}
                        <ListItemText
                          primary="依頼検索"
                          onClick={() => navigate(`${rootDir}requestSearch`)}
                        />
                      </ListItem>
                    )}
                    {appContext.state.LoginUser.isLogin && accessLevel === 1 && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          依頼申込
                        </AccordionSummary>
                        <AccordionDetails className={classes.paper}>
                          <MuiThemeProvider theme={theme3}>
                            <List>
                              {recieptTypes
                                ?.filter((x) => x.id !== 0)
                                .map((item) => (
                                  <ListItem button key={`li-${item.menuTitle}`}>
                                    <ListItemText
                                      key={item.menuTitle}
                                      primary={item.menuTitle}
                                      className={classes.listInList}
                                      onClick={() => {
                                        navigate(`${rootDir}requestInfo`, {
                                          state: {
                                            id: null,
                                            idCust:
                                              appContext.state.LoginUser.idCust,
                                            recieptType: item.id,
                                          },
                                        });
                                      }}
                                    />
                                  </ListItem>
                                ))}
                            </List>
                          </MuiThemeProvider>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {appContext.state.LoginUser.isLogin && accessLevel === 0 && (
                      <>
                        <ListItem button>
                          <ListItemText
                            primary="顧客検索"
                            onClick={() => navigate(`${rootDir}clientSearch`)}
                          />
                        </ListItem>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon color="secondary" />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                          >
                            管理メニュー
                          </AccordionSummary>
                          <AccordionDetails className={classes.paper}>
                            <MuiThemeProvider theme={theme3}>
                              <List>
                                <ListItem button>
                                  <ListItemText
                                    primary="お知らせ編集"
                                    className={classes.listInList}
                                    onClick={() => navigate(`${rootDir}news`)}
                                  />
                                </ListItem>
                                <ListItem button>
                                  <ListItemText
                                    primary="メール通知"
                                    className={classes.listInList}
                                    onClick={() =>
                                      navigate(`${rootDir}mailForm`)
                                    }
                                  />
                                </ListItem>
                              </List>
                            </MuiThemeProvider>
                          </AccordionDetails>
                        </Accordion>

                        <ListItem button>
                          <ListItemText
                            primary="パスワード変更"
                            onClick={() => navigate(`${rootDir}passwordChange`)}
                          />
                        </ListItem>
                      </>
                    )}
                    {accessLevel === 1 && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          アカウント管理
                        </AccordionSummary>
                        <AccordionDetails className={classes.paper}>
                          <MuiThemeProvider theme={theme3}>
                            <List>
                              <ListItem button>
                                <ListItemText
                                  primary="ログイン情報"
                                  className={classes.listInList}
                                  onClick={() =>
                                    navigate(`${rootDir}loginInfo`)
                                  }
                                />
                              </ListItem>
                              <ListItem button>
                                <ListItemText
                                  primary="基本情報"
                                  className={classes.listInList}
                                  onClick={() =>
                                    navigate(`${rootDir}accountInfo`)
                                  }
                                />
                              </ListItem>
                              <ListItem button>
                                <ListItemText
                                  primary="担当者管理"
                                  className={classes.listInList}
                                  onClick={() =>
                                    navigate(`${rootDir}staffManagement`)
                                  }
                                />
                              </ListItem>
                              <ListItem button>
                                <ListItemText
                                  primary="月次請求設定"
                                  className={classes.listInList}
                                  onClick={() =>
                                    navigate(`${rootDir}billingInfo`)
                                  }
                                />
                              </ListItem>
                              <ListItem button>
                                <ListItemText
                                  primary="月次請求書"
                                  className={classes.listInList}
                                  onClick={() => navigate(`${rootDir}invoice`)}
                                />
                              </ListItem>
                              <ListItem button>
                                <ListItemText
                                  primary="パスワード変更"
                                  className={classes.listInList}
                                  onClick={() =>
                                    navigate(`${rootDir}passwordChangeClient`)
                                  }
                                />
                              </ListItem>
                            </List>
                          </MuiThemeProvider>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    <ListItem button>
                      <ListItemText
                        primary="お問い合わせ"
                        onClick={() => navigate(`${rootDir}inquiry`)}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="利用規約"
                        onClick={() => {
                          const fileUrl = `${rootDir}terms.pdf`;
                          const link = document.createElement('a');
                          link.href = fileUrl;
                          link.target = '_blank';
                          link.rel = 'noreferrer';
                          link.click();
                        }}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="ＦＡＱ"
                        onClick={() => {
                          const fileUrl =
                            'http://www.yobou.or.jp/shokuhin/e-yoboufaq/';
                          const link = document.createElement('a');
                          link.href = fileUrl;
                          link.target = '_blank';
                          link.rel = 'noreferrer';
                          link.click();
                        }}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        primary="食品検査ホームページ"
                        onClick={() => {
                          const fileUrl = 'http://www.yobou.or.jp/shokuhin/';
                          const link = document.createElement('a');
                          link.href = fileUrl;
                          link.target = '_blank';
                          link.rel = 'noreferrer';
                          link.click();
                        }}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="ログアウト" onClick={logout} />
                    </ListItem>
                  </List>
                </MuiThemeProvider>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <span hidden={isLoading} className={classes.requestListHead}>
                  <span
                    className={
                      newsInfo?.isRed ? classes.newsRed : classes.newsBlack
                    }
                  >
                    {newsInfo?.news}
                  </span>
                  <a
                    hidden={newsInfo?.linkText === ''}
                    className={classes.requestListHeadLink}
                    href={newsInfo?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {newsInfo?.linkText}
                  </a>
                </span>
                <span hidden={isLoading} className={classes.requestList}>
                  <RequestListPanelTop result={requestData} />
                </span>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
      <CustomBackdrop open={isLoading} />
    </HeaderTemplate>
  );
};

export default TopPage;
