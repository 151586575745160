import {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import PasswordInput from 'components/molecules/PasswordInput';
import { PasswordReset } from 'types/User';
import {
  checkPassword,
  consoleLog,
  debugLog,
  methodPost,
  requestCheckFunc,
} from 'utils/CommonFunctions';
import { API_URL } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

const PasswordResetPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const { search } = useLocation();
  const query2 = new URLSearchParams(search);
  const username = query2.get('username') ?? '';
  const code = query2.get('code') ?? '';
  const requestID = query2.get('request') ?? '';

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>('info');
  const [password, setPassword] = useState('');
  const [passwordVeri, setPasswordVeri] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangePassword = (value: ChangeEvent<HTMLInputElement>) => {
    setPassword(value.target.value);
  };
  const onChangePasswordVeri = (value: ChangeEvent<HTMLInputElement>) => {
    setPasswordVeri(value.target.value);
  };

  // リクエストIDのチェック
  const requestIDCheck = useCallback(async () => {
    try {
      const check = await requestCheckFunc(requestID);
      if (!check) {
        setIsHide(false);
        setIsSuccess(true);
        setMode('error');
        setErrorMessages([
          'リクエストの有効期限が過ぎているか、不正なリクエストです。',
          '再度パスワード再設定を行ってください。',
        ]);
      }
    } catch (ex) {
      consoleLog(ex);
    }
  }, [requestID]);

  useEffect(() => {
    requestIDCheck().finally(() => {
      /* Nothing to do. */
    });
  }, [requestIDCheck]);

  const resetPassword = async () => {
    if (!password) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['新しいパスワードが入力されていません。']);
      debugLog('新しいパスワードが入力されていません。');

      return;
    }
    if (password !== passwordVeri) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['新しいパスワード(確認)が一致しません。']);
      debugLog('新しいパスワード(確認)が一致しません');

      return;
    }
    if (!checkPassword(password, appContext.state.LoginUser.loginID)) {
      setIsHide(false);
      setMode('error');
      setErrorMessages([
        '新しいパスワードがパスワードポリシーを満たしていません。',
      ]);

      return;
    }

    try {
      setIsLoading(true);
      const url = `${API_URL}api/v1/users/passwordresetcomfirm`;
      const data: PasswordReset = {
        userName: username,
        code,
        password,
        requestID,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(url, data, false);
      setIsHide(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (res.data) {
        setMode('info');
        setIsSuccess(true);
        setErrorMessages(['パスワード再設定が完了しました。']);
        debugLog('パスワード再設定が完了しました。');
      } else {
        setMode('error');
        setErrorMessages([
          'パスワード再設定が失敗しました。',
          '再度パスワード再設定（初期化）手続きを行ってください。',
        ]);
        debugLog(res);
      }

      // eslint-disable-next-line
    } catch (err: any) {
      consoleLog(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            パスワード再設定
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess}>
            <PasswordInput
              title="新しいパスワード"
              onChange={onChangePassword}
            />
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="confirmNewPassword"
              label="新しいパスワード(確認)"
              type="password"
              id="confirmNewPassword"
              value={passwordVeri}
              onChange={onChangePasswordVeri}
            />
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={resetPassword}
              >
                設定
              </Button>
            </Box>
          </div>
          {/* <div className={classes.form} hidden={!isSuccess}>
            <Box textAlign="center">
              <Link to={`${rootDir}`}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  ログインへ
                </Button>
              </Link>
            </Box>
          </div> */}
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default PasswordResetPage;
