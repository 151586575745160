import { FC, useEffect, useState, MouseEvent, useContext } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { RecieptType } from 'types/RecieptType';
import { consoleLog, methodGet, signOut } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import { useStateIfMounted } from 'use-state-if-mounted';
import { API_URL, rootDir } from '../../utils/CommonConst';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingRight: 24,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: 0,
      width: `calc(100%)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: 'fixed',
    },
    title: {
      flexGrow: 1,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    menu: {
      fontSize: '18px',
    },
    titleMenu: {
      fontSize: '25px',
    },
    login: {
      color: 'white',
    },
    logo: {
      height: '50px',
      marginRight: 10,
    },
    version: {
      fontSize: '11px',
      marginTop: '5%',
    },
  }),
);

export type HeaderProps = {
  accessLevel: number; // アクセスレベル（0：管理者、1：ユーザー）
};

const Header: FC<HeaderProps> = ({ accessLevel }) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const [recieptTypes, setRecieptTypes] = useStateIfMounted<
    RecieptType[] | null
  >(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);  
  const [versionNg, setVersionNg] = useState<boolean>(true);

  const handleMenu1 = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleMenu2 = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleMenu3 = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  useEffect(() => {
    // 検査区分マスタの取得・設定
    const getRecieptType = async () => {
      try {
        const url = `${API_URL}api/v1/masters/recieptttype`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as RecieptType[];
        setRecieptTypes(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    const checkVersion = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const url = `${API_URL}api/v1/masters/checkversion/${process.env.REACT_APP_PACKAGE_VERSION}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        setVersionNg(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    getRecieptType().finally(() => {
      /* Nothing to do. */
    });
    checkVersion().finally(() => {
      /* Nothing to do. */
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = async () => {
    await signOut();
    setAnchorEl2(null);
    navigate(`${rootDir}`);
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, classes.appBarShift)}
      color="secondary"
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          <Button
            color="inherit"
            onClick={() => {
              if (appContext.state.LoginUser.isLogin) {
                navigate(`${rootDir}top`);
              } else {
                navigate(`${rootDir}`);
              }
            }}
            className={classes.titleMenu}
          >
            <img
              src={`${rootDir}logo.png`}
              alt="アイコン"
              className={classes.logo}
            />
          </Button>
          <span className={classes.version} hidden={versionNg}>
            {/* Ver{process.env.REACT_APP_PACKAGE_VERSION} */}
            更新があります。
          </span>
        </Typography>
        {appContext.state.LoginUser.isLogin && (
          <Button
            color="inherit"
            onClick={() => navigate(`${rootDir}requestSearch`)}
            className={classes.menu}
          >
            依頼検索
          </Button>
        )}
        {appContext.state.LoginUser.isLogin &&
          (accessLevel === 1 || accessLevel === 3) && (
            <div>
              <Button
                color="inherit"
                onClick={handleMenu1}
                className={classes.menu}
              >
                依頼申込
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl1}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={open1}
                onClose={handleClose1}
              >
                {recieptTypes
                  ?.filter((x) => x.id !== 0)
                  .map((item) => (
                    <MenuItem
                      key={item.menuTitle}
                      value={`${item.id}`}
                      onClick={() => {
                        navigate(`${rootDir}requestInfo`, {
                          state: {
                            id: null,
                            idCust: appContext.state.LoginUser.idCust,
                            recieptType: item.id,
                          },
                        });
                        handleClose1();
                      }}
                    >
                      {item.menuTitle}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          )}
        {appContext.state.LoginUser.isLogin &&
          (accessLevel === 0 || accessLevel === 3) && (
            <Button
              color="inherit"
              onClick={() => navigate(`${rootDir}clientSearch`)}
              className={classes.menu}
            >
              顧客検索
            </Button>
          )}
        {appContext.state.LoginUser.isLogin &&
          (accessLevel === 0 || accessLevel === 3) && (
            <div>
              <Button
                onClick={handleMenu3}
                color="inherit"
                className={classes.menu}
              >
                管理メニュー
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl3}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={open3}
                onClose={handleClose3}
              >
                <Link to={`${rootDir}news`} className={classes.link}>
                  <MenuItem onClick={handleClose3}>お知らせ編集</MenuItem>
                </Link>
                <Link to={`${rootDir}mailForm`} className={classes.link}>
                  <MenuItem onClick={handleClose3}>メール通知</MenuItem>
                </Link>
              </Menu>
            </div>
          )}
        {!appContext.state.LoginUser.isLogin && (
          <div>
            <Link to={`${rootDir}`} className={classes.login}>
              <Button
                onClick={handleMenu2}
                color="inherit"
                className={classes.menu}
              >
                ログイン
              </Button>
            </Link>
          </div>
        )}
        {appContext.state.LoginUser.isLogin && (
          <div>
            <Button
              onClick={handleMenu2}
              color="inherit"
              className={classes.menu}
            >
              アカウント管理
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl2}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open2}
              onClose={handleClose2}
            >
              {(accessLevel === 1 || accessLevel === 3) && (
                <Link to={`${rootDir}loginInfo`} className={classes.link}>
                  <MenuItem onClick={handleClose2}>ログイン情報</MenuItem>
                </Link>
              )}
              {(accessLevel === 1 || accessLevel === 3) && (
                <Link to={`${rootDir}accountInfo`} className={classes.link}>
                  <MenuItem onClick={handleClose2}>基本情報</MenuItem>
                </Link>
              )}
              {(accessLevel === 1 || accessLevel === 3) && (
                <Link to={`${rootDir}staffManagement`} className={classes.link}>
                  <MenuItem onClick={handleClose2}>担当者管理</MenuItem>
                </Link>
              )}
              {(accessLevel === 1 || accessLevel === 3) && (
                <Link to={`${rootDir}billingInfo`} className={classes.link}>
                  <MenuItem onClick={handleClose2}>月次請求設定</MenuItem>
                </Link>
              )}
              {(accessLevel === 1 || accessLevel === 3) && (
                <Link to={`${rootDir}invoice`} className={classes.link}>
                  <MenuItem onClick={handleClose2}>月次請求書</MenuItem>
                </Link>
              )}
              {(accessLevel === 1 || accessLevel === 3) && (
                <Link
                  to={`${rootDir}passwordChangeClient`}
                  className={classes.link}
                >
                  <MenuItem onClick={handleClose2}>パスワード変更</MenuItem>
                </Link>
              )}
              {(accessLevel === 0 || accessLevel === 3) && (
                <Link to={`${rootDir}passwordChange`} className={classes.link}>
                  <MenuItem onClick={handleClose2}>パスワード変更</MenuItem>
                </Link>
              )}
              <MenuItem onClick={logout}>ログアウト</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
