import {
  FC,
  ReactEventHandler,
  ChangeEvent,
  useEffect,
  useState,
  SyntheticEvent,
  useContext,
} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table } from 'antd';
import { AddressHistory, Request } from 'types/RequestDataInfo';
import { API_URL } from 'utils/CommonConst';
import { User } from 'types/User';
import { Box } from '@material-ui/core';
import { consoleLog, methodGet } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import MessagePanel from './MessagePanel';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'right',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 1,
        marginBottom: 1,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(1, 5, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    configuration: {
      margin: theme.spacing(0, 3, 0),
      '&:hover': {
        background: '#1769aa',
      },
    },
    return: {
      margin: theme.spacing(0, 5, 0),
      '&:hover': {
        background: 'darkgray',
      },
    },
    radio: {
      margin: theme.spacing(-0.7, 3, 1),
    },
    checkboxJapanese: {
      margin: theme.spacing(-0.6, 1.7, 0),
    },
    checkboxEnglish: {
      margin: theme.spacing(-0.6, -1.9, 0),
    },
    width: {
      width: 400,
    },
    dialogTitle: {
      fontSize: 24,
    },
    title: {
      margin: theme.spacing(3, -3, -5),
    },
    titleSpace: {
      margin: theme.spacing(3, -3, 1),
    },
    caution: {
      fontSize: 16,
      color: 'red',
    },
    disabledItem: {
      backgroundColor: 'lightgray',
    },
    enabledItem: {
      backgroundColor: 'white',
    },
  }),
);

export type Result = {
  id: number;
  forJapanese: string;
  forEnglish: string;
};
export type History = {
  id: number;
  forJapanese: string;
  forEnglish: string;
};

export type ResultAddressDialogProps = {
  open: boolean;
  onClickClose: ReactEventHandler<HTMLButtonElement>;
  userData?: User;
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};

const ResultAddressDialog: FC<ResultAddressDialogProps> = ({
  open,
  onClickClose,
  userData,
  requestData,
  updateRequest,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const columns = [
    {
      title: '和文',
      dataIndex: 'forJapanese',
      key: 'forJapanese',
      width: 200,
      align: 'center' as const,
    },
    {
      title: '英文',
      dataIndex: 'forEnglish',
      key: 'forEnglish',
      width: 200,
      align: 'center' as const,
    },
  ];
  const [tempRequestData, setTempRequestData] = useState<Request>();
  const [addressHistory, setAddressHistory] = useState<AddressHistory>();
  const [resultAddress, setResultAddress] = useState<Result[]>([]);
  const [history, setHistory] = useState<History[]>([]);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);

  useEffect(() => {
    const getAddressHistory = async () => {
      try {
        const url = `${API_URL}api/v1/requests/${
          requestData?.idCust ?? 0
        }/addresshistory`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as AddressHistory;
        setAddressHistory(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    if (requestData && appContext.state.LoginUser.isLogin) {
      getAddressHistory().finally(() => {
        /* Nothing to do. */
      });
      setTempRequestData(requestData);
    }
  }, [requestData, appContext.state.LoginUser]);

  useEffect(() => {
    if (tempRequestData) {
      const results: Result[] = [
        {
          id: 1,
          forJapanese: tempRequestData?.namePublishFor1 ?? '',
          forEnglish: tempRequestData?.namePublishFor1En ?? '',
        },
        {
          id: 2,
          forJapanese: tempRequestData?.namePublishFor2 ?? '',
          forEnglish: tempRequestData?.namePublishFor2En ?? '',
        },
        {
          id: 3,
          forJapanese: tempRequestData?.namePublishFor3 ?? '',
          forEnglish: tempRequestData?.namePublishFor3En ?? '',
        },
      ];
      setResultAddress(results);
    }
    if (updateRequest && tempRequestData && isSave) {
      updateRequest(tempRequestData);
      setIsSave(false);
    }
  }, [tempRequestData, isSave, updateRequest]);

  useEffect(() => {
    if (addressHistory) {
      const histories: History[] = [];
      const count =
        (addressHistory?.japanese.length ?? 0) >
        (addressHistory?.english.length ?? 0)
          ? addressHistory?.japanese.length
          : addressHistory?.english.length;
      for (let index = 1; index <= (count ?? 0); index += 1) {
        histories.push({
          id: index,
          forJapanese:
            addressHistory?.japanese.find((n) => n.id === index)?.name ?? '',
          forEnglish:
            addressHistory?.english.find((n) => n.id === index)?.name ?? '',
        });
      }
      setHistory(histories);
    }
  }, [addressHistory]);

  // 結果書宛名数
  const changeNamePublishForNum = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    const value = Number(event.target.value);
    data.namePublishForNum = value;
    setTempRequestData(data);
  };

  // 和文選択
  const clickNamePublishForJa = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    const value = event.target.checked;
    data.namePublishForJa = value;
    setTempRequestData(data);
  };

  // 英文選択
  const clickNamePublishForEn = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    const value = event.target.checked;
    data.namePublishForEn = value;
    setTempRequestData(data);
  };

  // 結果書宛名
  const changeSendNameFlgSameRcpt = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    const value = event.target.value.toLowerCase() === 'true';
    data.sendNameFlgSameRcpt = value;
    if (value) {
      data.namePublishFor1 = userData?.custFullName ?? '';
    }
    setTempRequestData(data);
  };

  const updateTempRequestData = () => {
    const data = { ...tempRequestData } as Request;
    data.namePublishFor1 =
      resultAddress.find((n) => n.id === 1)?.forJapanese ?? '';
    data.namePublishFor2 =
      resultAddress.find((n) => n.id === 2)?.forJapanese ?? '';
    data.namePublishFor3 =
      resultAddress.find((n) => n.id === 3)?.forJapanese ?? '';
    data.namePublishFor1En =
      resultAddress.find((n) => n.id === 1)?.forEnglish ?? '';
    data.namePublishFor2En =
      resultAddress.find((n) => n.id === 2)?.forEnglish ?? '';
    data.namePublishFor3En =
      resultAddress.find((n) => n.id === 3)?.forEnglish ?? '';
    setTempRequestData(data);
  };

  // 設定ボタン
  const confirmation = (event: SyntheticEvent<HTMLButtonElement>) => {
    // 入力チェック
    if (tempRequestData) {
      const enCheck = /^\w|\s*$/;
      if (
        !tempRequestData.namePublishForJa &&
        !tempRequestData.namePublishForEn
      ) {
        setIsHide(false);
        setErrorMessages(['宛名種類を選択してください。']);

        return;
      }
      switch (tempRequestData.namePublishForNum) {
        case 1:
          if (tempRequestData.namePublishForJa) {
            if (!tempRequestData.namePublishFor1) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor1 &&
              tempRequestData.namePublishFor1.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForEn) {
            if (!tempRequestData.namePublishFor1En) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor1En &&
              !enCheck.test(tempRequestData.namePublishFor1En)
            ) {
              setIsHide(false);
              setErrorMessages(['宛名（英文用）は英数字で入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor1En &&
              tempRequestData.namePublishFor1En.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          break;
        case 2:
          if (tempRequestData.namePublishForJa) {
            if (!tempRequestData.namePublishFor1) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor1 &&
              tempRequestData.namePublishFor1.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForEn) {
            if (!tempRequestData.namePublishFor1En) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor1En &&
              tempRequestData.namePublishFor1En.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForJa) {
            if (!tempRequestData.namePublishFor2) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor2 &&
              tempRequestData.namePublishFor2.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForEn) {
            if (!tempRequestData.namePublishFor2En) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor2En &&
              tempRequestData.namePublishFor2En.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          break;
        case 3:
          if (tempRequestData.namePublishForJa) {
            if (!tempRequestData.namePublishFor1) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor1 &&
              tempRequestData.namePublishFor1.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForEn) {
            if (!tempRequestData.namePublishFor1En) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor1En &&
              tempRequestData.namePublishFor1En.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForJa) {
            if (!tempRequestData.namePublishFor2) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor2 &&
              tempRequestData.namePublishFor2.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForEn) {
            if (!tempRequestData.namePublishFor2En) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor2En &&
              tempRequestData.namePublishFor2En.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForJa) {
            if (!tempRequestData.namePublishFor3) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor3 &&
              tempRequestData.namePublishFor3.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          if (tempRequestData.namePublishForEn) {
            if (!tempRequestData.namePublishFor3En) {
              setIsHide(false);
              setErrorMessages(['宛名を入力してください。']);

              return;
            }
            if (
              tempRequestData.namePublishFor3En &&
              tempRequestData.namePublishFor3En.length >= 50
            ) {
              setIsHide(false);
              setErrorMessages(['宛名には50文字までしか入力できません。']);

              return;
            }
          }
          break;
        default:
          break;
      }

      const data = { ...tempRequestData } as Request;
      if (!tempRequestData.namePublishForJa) {
        data.namePublishFor1 = '';
        data.namePublishFor2 = '';
        data.namePublishFor3 = '';
      }
      if (!tempRequestData.namePublishForEn) {
        data.namePublishFor1En = '';
        data.namePublishFor2En = '';
        data.namePublishFor3En = '';
      }
      switch (tempRequestData.namePublishForNum) {
        case 1:
          data.namePublishFor2 = '';
          data.namePublishFor3 = '';
          data.namePublishFor2En = '';
          data.namePublishFor3En = '';
          break;
        case 2:
          data.namePublishFor3 = '';
          data.namePublishFor3En = '';
          break;
        case 3:
          break;
        default:
          break;
      }
      setTempRequestData(data);

      setIsHide(true);
      setIsSave(true);
      onClickClose(event);
    }
  };

  // ダイアログを閉じる
  const dialogClose = (event: SyntheticEvent<HTMLButtonElement>) => {
    setIsHide(true);
    setErrorMessages(['']);
    onClickClose(event);
  };

  /* non eslint-disable  */
  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={dialogClose}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>結果書宛名</Box>
        </DialogTitle>
        <DialogContent>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5" className={classes.title}>
              結果書宛名の設定
            </Typography>
            <div className={classes.form}>
              <MuiThemeProvider theme={theme2}>
                <DialogActions>
                  <MuiThemeProvider theme={theme3}>
                    <Button
                      variant="contained"
                      onClick={confirmation}
                      color="primary"
                      className={classes.configuration}
                    >
                      設定
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    variant="contained"
                    onClick={dialogClose}
                    color="primary"
                    className={classes.return}
                  >
                    戻る
                  </Button>
                </DialogActions>
              </MuiThemeProvider>
              <MessagePanel messages={errorMessages} isHide={isHide} />
              <span className={classes.caution}>
                ※[宛名の数]、[宛名種類]を変更すると対象外となった項目の内容は削除されます。
              </span>
              <Typography component="h1" variant="h6">
                宛名の数
                <FormControl className={classes.radio}>
                  <RadioGroup
                    row
                    aria-label="gender"
                    value={tempRequestData?.namePublishForNum}
                    onChange={changeNamePublishForNum}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio color="primary" />}
                      label="1個"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio color="primary" />}
                      label="2個"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio color="primary" />}
                      label="3個"
                    />
                  </RadioGroup>
                </FormControl>
              </Typography>
              <Typography component="h1" variant="h6">
                宛名種類
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={clickNamePublishForJa}
                    />
                  }
                  label="和文"
                  className={classes.checkboxJapanese}
                  checked={tempRequestData?.namePublishForJa ?? false}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={clickNamePublishForEn}
                    />
                  }
                  label="英文"
                  className={classes.checkboxEnglish}
                  checked={tempRequestData?.namePublishForEn ?? false}
                />
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                className={classes.titleSpace}
              >
                結果書宛名
              </Typography>
              <Typography component="h1" variant="h6">
                和文宛名1
                <FormControl className={classes.radio}>
                  <RadioGroup
                    row
                    aria-label="gender"
                    value={tempRequestData?.sendNameFlgSameRcpt}
                    onChange={changeSendNameFlgSameRcpt}
                  >
                    <FormControlLabel
                      value
                      control={<Radio color="primary" />}
                      label="依頼者と同じ"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label="その他"
                    />
                  </RadioGroup>
                </FormControl>
              </Typography>
              <MuiThemeProvider theme={theme3}>
                <table className="input-table">
                  <tbody>
                    <tr>
                      <th>番号</th>
                      <th className={classes.width}>和文用</th>
                      <th className={classes.width}>英文用</th>
                    </tr>
                    {resultAddress.map((result) => (
                      <tr key={result.id}>
                        <td className="center">宛名{result.id}</td>
                        <td>
                          <Autocomplete
                            freeSolo
                            options={
                              addressHistory?.japanese?.map(
                                (option) => option.name,
                              ) ?? []
                            }
                            renderInput={(params) => {
                              const {
                                inputProps,
                                InputProps,
                                InputLabelProps,
                              } = params;

                              return (
                                <TextField
                                  {...{
                                    inputProps,
                                    InputLabelProps,
                                  }}
                                  variant="outlined"
                                  margin="dense"
                                  required
                                  fullWidth
                                  id={`namePublishFor${result.id}`}
                                  name={`namePublishFor${result.id}`}
                                  disabled={
                                    (tempRequestData?.namePublishForNum === 1 &&
                                      result.id !== 1) ||
                                    (tempRequestData?.namePublishForNum === 2 &&
                                      result.id === 3) ||
                                    !tempRequestData?.namePublishForJa
                                  }
                                  InputProps={
                                    tempRequestData?.sendNameFlgSameRcpt &&
                                    result.id === 1
                                      ? {
                                          readOnly: true,
                                        }
                                      : { ...InputProps }
                                  }
                                  className={
                                    (tempRequestData?.namePublishForNum === 1 &&
                                      result.id !== 1) ||
                                    (tempRequestData?.namePublishForNum === 2 &&
                                      result.id === 3) ||
                                    !tempRequestData?.namePublishForJa
                                      ? classes.disabledItem
                                      : classes.enabledItem
                                  }
                                />
                              );
                            }}
                            onInputChange={(e, value) => {
                              const data = [...resultAddress];
                              const idx = data.findIndex(
                                (v) => v.id === result.id,
                              );
                              if (idx !== -1) {
                                data[idx].forJapanese = value;
                              }
                              setResultAddress(data);
                              updateTempRequestData();
                            }}
                            value={result.forJapanese}
                            disabled={
                              (tempRequestData?.namePublishForNum === 1 &&
                                result.id !== 1) ||
                              (tempRequestData?.namePublishForNum === 2 &&
                                result.id === 3) ||
                              !tempRequestData?.namePublishForJa
                            }
                          />
                        </td>
                        <td>
                          <Autocomplete
                            freeSolo
                            options={
                              addressHistory?.english?.map(
                                (option) => option.name,
                              ) ?? []
                            }
                            renderInput={(params) => {
                              const {
                                inputProps,
                                InputProps,
                                InputLabelProps,
                              } = params;

                              return (
                                <TextField
                                  {...{
                                    inputProps,
                                    InputProps,
                                    InputLabelProps,
                                  }}
                                  variant="outlined"
                                  margin="dense"
                                  required
                                  fullWidth
                                  id={`namePublishFor${result.id}En`}
                                  name={`namePublishFor${result.id}En`}
                                  disabled={
                                    (tempRequestData?.namePublishForNum === 1 &&
                                      result.id !== 1) ||
                                    (tempRequestData?.namePublishForNum === 2 &&
                                      result.id === 3) ||
                                    !tempRequestData?.namePublishForEn
                                  }
                                  className={
                                    (tempRequestData?.namePublishForNum === 1 &&
                                      result.id !== 1) ||
                                    (tempRequestData?.namePublishForNum === 2 &&
                                      result.id === 3) ||
                                    !tempRequestData?.namePublishForEn
                                      ? classes.disabledItem
                                      : classes.enabledItem
                                  }
                                />
                              );
                            }}
                            onInputChange={(e, value) => {
                              const data = [...resultAddress];
                              const idx = data.findIndex(
                                (v) => v.id === result.id,
                              );
                              if (idx !== -1) {
                                data[idx].forEnglish = value;
                              }
                              setResultAddress(data);
                              updateTempRequestData();
                            }}
                            value={result.forEnglish}
                            disabled={
                              (tempRequestData?.namePublishForNum === 1 &&
                                result.id !== 1) ||
                              (tempRequestData?.namePublishForNum === 2 &&
                                result.id === 3) ||
                              !tempRequestData?.namePublishForEn
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </MuiThemeProvider>
              <Typography
                component="h1"
                variant="h5"
                className={classes.titleSpace}
              >
                宛名の履歴
              </Typography>
              <div className="table-size">
                <Table
                  dataSource={history}
                  columns={columns}
                  pagination={{ pageSize: 20 }}
                  rowKey="id"
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResultAddressDialog;
