import { FC, ReactNode, useContext } from 'react';
import {
  createTheme,
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Header from 'components/molecules/Header';
import Footer from 'components/molecules/Footer';
import { AppContext } from 'utils/AppContext';

const themes = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'MS Pゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: colors.blue[500] }, // テーマの色
    secondary: { main: colors.indigo[900] }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '90vh',
      overflow: 'scroll',
    },
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    subTitle: {
      marginLeft: theme.spacing(3),
      fontSize: 14,
    },
  }),
);

export type HeaderTemplateProps = {
  children: ReactNode;
  title?: string;
  subTitle?: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
};

const HeaderTemplate: FC<HeaderTemplateProps> = ({
  children,
  title = '',
  subTitle = '',
  maxWidth = 'xl',
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  let accessLevel;
  if (appContext.state.LoginUser.idCust === 1) {
    accessLevel = 3;
  } else {
    accessLevel = appContext.state.LoginUser.isMaster ? 0 : 1;
  }

  return (
    <ThemeProvider theme={themes}>
      <div className={classes.root}>
        <CssBaseline />
        <Header accessLevel={accessLevel} />
        <main className={classes.content} id="HeaderContent">
          <div className={classes.appBarSpacer} />
          <Container maxWidth={maxWidth} className={classes.container}>
            <Typography
              component="h2"
              variant="h4"
              color="inherit"
              noWrap
              className={classes.pageTitle}
              id="TopPage"
            >
              {title}
              {subTitle !== '' && (
                <span className={classes.subTitle}>{subTitle}</span>
              )}
            </Typography>
            {children}
          </Container>
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default HeaderTemplate;
