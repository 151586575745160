import { FC, useState, ChangeEvent } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { API_URL } from 'utils/CommonConst';
import { consoleLog, debugLog, methodPost } from 'utils/CommonFunctions';
import CustomTextField from 'components/molecules/CustomTextField';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    line: {
      marginTop: theme.spacing(4),
    },
    label: {
      fontSize: '18px',
    },
  }),
);

const ApplicationForUsePage: FC = () => {
  const classes = useStyles();
  const mailCheck =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>('info');
  const [mailAddress, setMailAddress] = useState('');
  const [confirMailAddress, setConfirMailAddress] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  const onChangeMailAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setMailAddress(event.target.value);
  };
  const onChangeConfirMailAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirMailAddress(event.target.value);
  };
  const onChangeIsAgree = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAgree(event.target.checked);
  };
  const application = async () => {
    if (!mailAddress || !confirMailAddress) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['メールアドレスが入力されていません。']);
      debugLog('メールアドレスが入力されていません。');

      return;
    }
    if (mailAddress !== confirMailAddress) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['メールアドレス(確認)が一致しません。']);
      debugLog('メールアドレス(確認)が一致しません');

      return;
    }
    if (!mailCheck.test(mailAddress)) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['メールアドレスの形式になっていません。']);
      debugLog('メールアドレスの形式になっていません');

      return;
    }
    if (!isAgree) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['利用規約に同意してください。']);
      debugLog('利用規約に同意してください。');

      return;
    }
    try {
      const url = `${API_URL}api/v1/users/signup`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(url, mailAddress, false);
      setIsHide(false);
      // eslint-disable-next-line
      if (res.data.response) {
        setMode('info');
        setIsSuccess(true);
        setErrorMessages([
          `ご登録されたメールアドレス「${mailAddress}」にシステム利用申し込みのメールを送信しました。`,
          `メールをご確認ください。`,
        ]);
        debugLog('システム利用申し込みのメールを送信しました。');
      } else {
        setMode('error');
        setErrorMessages(['既に登録済のメールアドレスです。']);
        debugLog('既に登録済のメールアドレスです。');
        debugLog(res);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setMode('error');
      // その他のエラー
      setIsHide(false);
      setErrorMessages(['予期しないエラーが発生しました。']);
      consoleLog('予期しないエラーが発生しました');
      consoleLog(err);
      consoleLog('Unknowned error');
    }
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            システム利用申し込み
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess}>
            <Box textAlign="center">
              <a href="terms.pdf" target="_blank">
                システム利用規約
              </a>
              に同意の上、メールアドレスをご登録ください。
            </Box>
            <CustomTextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="mailAddress"
              label="メールアドレス"
              name="mailAddress"
              autoComplete="mailAddress"
              autoFocus
              className={classes.line}
              value={mailAddress}
              onChange={onChangeMailAddress}
              inputProps={{ maxLength: 50 }}
            />
            <CustomTextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="confirMailAddress"
              label="メールアドレス（確認）"
              value={confirMailAddress}
              onChange={onChangeConfirMailAddress}
              inputProps={{ maxLength: 50 }}
            />
            <Box textAlign="center">
              <FormControlLabel
                classes={{ label: classes.label }}
                control={
                  <Checkbox
                    color="primary"
                    value={isAgree}
                    onChange={onChangeIsAgree}
                  />
                }
                label="利用規約に同意します。"
              />
            </Box>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={application}
              >
                申し込み
              </Button>
            </Box>
          </div>
        </div>
      </Container>
    </HeaderTemplate>
  );
};

export default ApplicationForUsePage;
