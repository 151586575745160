import {
  ReactEventHandler,
  FC,
  useState,
  useEffect,
  SyntheticEvent,
  useContext,
} from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DestinationInputPanel from 'components/molecules/DestinationInputPanel';
import HistoryPanel from 'components/molecules/HistoryPanel';
import { DestinationHistory, Request } from 'types/RequestDataInfo';
import { API_URL, addressType } from 'utils/CommonConst';
import { Box } from '@material-ui/core';
import { consoleLog, methodGet } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import MessagePanel from './MessagePanel';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'left',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 5, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    configuration: {
      margin: theme.spacing(0, 3, 0),
      '&:hover': {
        background: '#1769aa',
      },
    },
    return: {
      margin: theme.spacing(0, 5, 0),
      '&:hover': {
        background: 'darkgray',
      },
    },
    dialogTitle: {
      fontSize: 24,
    },
  }),
);

export type DestinationInvoiceDialogProps = {
  open: boolean;
  onClickClose: ReactEventHandler<HTMLButtonElement>;
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};

const DestinationInvoiceDialog: FC<DestinationInvoiceDialogProps> = ({
  open,
  onClickClose,
  requestData,
  updateRequest,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const zipCheck = /^[0-9]{7}$/;
  const telCheck = /\d{2,4}-\d{2,4}-\d{4}$/;

  const [tempRequestData, setTempRequestData] = useState<Request>();
  const [destinationHistory, setDestinationHistory] = useState<
    DestinationHistory[]
  >([]);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);

  useEffect(() => {
    const getAddressHistory = async () => {
      try {
        const url = `${API_URL}api/v1/requests/${
          requestData?.idCust ?? 0
        }/destinationhistory/2`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as DestinationHistory[];
        setDestinationHistory(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    if (requestData?.idCust && appContext.state.LoginUser.isLogin) {
      getAddressHistory().finally(() => {
        /* Nothing to do. */
      });
    }
  }, [requestData?.idCust, appContext.state.LoginUser]);

  useEffect(() => {
    if (requestData) {
      setTempRequestData(requestData);
    }
  }, [requestData]);

  // 設定ボタン
  const confirmation = (event: SyntheticEvent<HTMLButtonElement>) => {
    // 入力チェック
    if (tempRequestData) {
      let error = false;
      const message: string[] = [];
      if (!tempRequestData.chargeSendName) {
        message.push('・名称を入力してください。');
        error = true;
      }
      if (!tempRequestData.chargeSendZipCode) {
        message.push('・郵便番号を入力してください。');
        error = true;
      }
      if (
        tempRequestData.chargeSendZipCode &&
        !zipCheck.test(tempRequestData.chargeSendZipCode)
      ) {
        message.push('・郵便番号は半角数字７桁で入力してください。');
        message.push('　例）7550152');
        error = true;
      }
      if (!tempRequestData.chargeSendAddress1) {
        message.push('・住所を入力してください。');
        error = true;
      }
      if (!tempRequestData.chargeSendPicTel) {
        message.push('・電話番号を入力してください。');
        error = true;
      }
      if (
        tempRequestData.chargeSendPicTel &&
        !telCheck.test(tempRequestData.chargeSendPicTel)
      ) {
        message.push('・電話番号は半角数字と半角ハイフンで入力してください。');
        message.push('　例）083-941-6300');
        error = true;
      }
      if (
        tempRequestData.chargeSendPicFax &&
        !telCheck.test(tempRequestData.chargeSendPicFax)
      ) {
        message.push('・FAX番号は半角数字と半角ハイフンで入力してください。');
        message.push('　例）083-941-6400');
        error = true;
      }
      if (!tempRequestData.chargeSendPic) {
        message.push('・担当者を入力してください。');
        error = true;
      }
      if (error) {
        setIsHide(false);
        setErrorMessages(message);

        return;
      }
      setIsHide(true);
      if (updateRequest) updateRequest(tempRequestData);
      onClickClose(event);
    }
  };

  // ダイアログを閉じる
  const dialogClose = (event: SyntheticEvent<HTMLButtonElement>) => {
    setIsHide(true);
    setErrorMessages(['']);
    onClickClose(event);
  };

  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={dialogClose}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>送付先設定（請求先）</Box>
        </DialogTitle>
        <DialogContent>
          <div className={classes.paper}>
            <div className={classes.form}>
              <MuiThemeProvider theme={theme2}>
                <DialogActions>
                  <MuiThemeProvider theme={theme3}>
                    <Button
                      variant="contained"
                      onClick={confirmation}
                      color="primary"
                      className={classes.configuration}
                    >
                      設定
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    variant="contained"
                    onClick={dialogClose}
                    color="primary"
                    className={classes.return}
                  >
                    戻る
                  </Button>
                </DialogActions>
              </MuiThemeProvider>
              <MessagePanel messages={errorMessages} isHide={isHide} />
              <DestinationInputPanel
                type={addressType.DestinationInvoice}
                requestData={tempRequestData}
                updateRequest={(values: Request) => {
                  setTempRequestData(values);
                }}
              />
              <HistoryPanel
                type={addressType.DestinationInvoice}
                history={destinationHistory}
                requestData={tempRequestData}
                updateRequest={(values: Request) => {
                  setTempRequestData(values);
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DestinationInvoiceDialog;
