import { FC } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const theme1 = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        boxShadow: '0 0 0 1000px white inset',
      },
    },
  },
  palette: {
    primary: { main: colors.blue[500] }, // テーマの色
    secondary: { main: colors.indigo[900] }, // テーマの色
  },
});

export type CustomTextField2Props = TextFieldProps;

const CustomTextField2: FC<CustomTextField2Props> = ({ ...props }) => (
  <>
    <MuiThemeProvider theme={theme1}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TextField {...props} />
    </MuiThemeProvider>
  </>
);
export default CustomTextField2;
