import { FC } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    space: {
      marginTop: theme.spacing(4),
    },
  }),
);

const AboutStaffPage: FC = () => {
  const classes = useStyles();

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="lg">
        <div className={classes.paper}>
          <Box>
            本システムは各担当者へ以下のタイミングでメールを配信します。
          </Box>
          <Box component="h2" className={classes.space}>
            依頼者
          </Box>
          <Box>
            ・システムに速報（ＰＤＦファイル）がアップロードされた、またはアップロードされたファイルが削除された時
          </Box>
          <Box>
            ・システムに結果書（ＰＤＦファイル）がアップロードされた、またはアップロードされたファイルが削除された時
          </Box>
          <Box>
            ・公益財団法人山口県予防保健協会がお客様の代理で依頼の申し込みを行った時
          </Box>
          <br />
          <Box component="h2">結果書</Box>
          <Box>
            ・システムに速報（ＰＤＦファイル）がアップロードされた、またはアップロードされたファイルが削除された時
          </Box>
          <Box>
            ・システムに結果書（ＰＤＦファイル）がアップロードされた、またはアップロードされたファイルが削除された時
          </Box>
          <br />
          <Box component="h2">請求書</Box>
          <Box>
            ・システムに請求書（ＰＤＦファイル）がアップロードされた、またはアップロードされたファイルが削除された時
          </Box>
        </div>
      </Container>
    </HeaderTemplate>
  );
};

export default AboutStaffPage;
