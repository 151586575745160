import {
  ChangeEvent,
  FC,
  ReactEventHandler,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import HistoryPanel from 'components/molecules/HistoryPanel';
import { DestinationHistory, Request } from 'types/RequestDataInfo';
import { API_URL, addressType } from 'utils/CommonConst';
import { Box } from '@material-ui/core';
import { consoleLog, methodGet } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import MessagePanel from './MessagePanel';
import CustomTextField2 from './CustomTextField2';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'left',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 1,
        marginBottom: 1,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 5, 0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    configuration: {
      margin: theme.spacing(0, 3, 0),
      '&:hover': {
        background: '#1769aa',
      },
    },
    return: {
      margin: theme.spacing(0, 5, 0),
      '&:hover': {
        background: 'darkgray',
      },
    },
    width: {
      width: 150,
    },
    length: {
      width: 500,
    },
    dialogTitle: {
      fontSize: 24,
    },
    titleSpace: {
      margin: theme.spacing(3, 0, 1),
    },
  }),
);

export type InvoiceAddressDialogProps = {
  open: boolean;
  onClickClose: ReactEventHandler<HTMLButtonElement>;
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};
const InvoiceAddressDialog: FC<InvoiceAddressDialogProps> = ({
  open,
  onClickClose,
  requestData,
  updateRequest,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const zipCheck = /^[0-9]{7}$/;

  const [tempRequestData, setTempRequestData] = useState<Request>();
  const [destinationHistory, setDestinationHistory] = useState<
    DestinationHistory[]
  >([]);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);

  useEffect(() => {
    const getAddressHistory = async () => {
      try {
        const url = `${API_URL}api/v1/requests/${
          requestData?.idCust ?? 0
        }/destinationhistory/3`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as DestinationHistory[];
        setDestinationHistory(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    if (requestData?.idCust && appContext.state.LoginUser.isLogin) {
      getAddressHistory().finally(() => {
        /* Nothing to do. */
      });
    }
  }, [requestData?.idCust, appContext.state.LoginUser]);

  useEffect(() => {
    if (requestData) {
      setTempRequestData(requestData);
    }
  }, [requestData]);

  // 宛名
  const clickChargeName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    data.chargeName = event.target.value;
    setTempRequestData(data);
  };

  // 郵便番号
  const clickChargeZipCode = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    data.chargeZipCode = event.target.value;
    setTempRequestData(data);
  };

  // 住所
  const clickChargeAddress1 = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    data.chargeAddress1 = event.target.value;
    setTempRequestData(data);
  };

  // 建物等
  const clickChargeAddress2 = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempRequestData } as Request;
    data.chargeAddress2 = event.target.value;
    setTempRequestData(data);
  };

  // 設定ボタン
  const confirmation = (event: SyntheticEvent<HTMLButtonElement>) => {
    // 入力チェック
    if (tempRequestData) {
      let error = false;
      const message: string[] = [];
      if (!tempRequestData.chargeName) {
        message.push('・宛名を入力してください。');
        error = true;
      }
      if (!tempRequestData.chargeZipCode) {
        message.push('・郵便番号を入力してください。');
        error = true;
      }
      if (
        tempRequestData.chargeZipCode &&
        !zipCheck.test(tempRequestData.chargeZipCode)
      ) {
        message.push('・郵便番号は半角数字７桁で入力してください。');
        message.push('　例）7550152');
        error = true;
      }
      if (!tempRequestData.chargeAddress1) {
        message.push('・住所を入力してください。');
        error = true;
      }
      if (error) {
        setIsHide(false);
        setErrorMessages(message);

        return;
      }

      setIsHide(true);
      if (updateRequest) updateRequest(tempRequestData);
      onClickClose(event);
    }
  };

  // ダイアログを閉じる
  const dialogClose = (event: SyntheticEvent<HTMLButtonElement>) => {
    setIsHide(true);
    setErrorMessages(['']);
    onClickClose(event);
  };

  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={dialogClose}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>請求書に記載される情報</Box>
        </DialogTitle>
        <DialogContent>
          <div className={classes.paper}>
            <div className={classes.form}>
              <MuiThemeProvider theme={theme2}>
                <DialogActions>
                  <MuiThemeProvider theme={theme3}>
                    <Button
                      variant="contained"
                      onClick={confirmation}
                      color="primary"
                      className={classes.configuration}
                    >
                      設定
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    variant="contained"
                    onClick={dialogClose}
                    color="primary"
                    className={classes.return}
                  >
                    戻る
                  </Button>
                </DialogActions>
              </MuiThemeProvider>
              <MessagePanel messages={errorMessages} isHide={isHide} />
              <Typography
                component="h1"
                variant="h5"
                className={classes.titleSpace}
              >
                請求書に記載される住所
              </Typography>
              <MuiThemeProvider theme={theme3}>
                <table className="input-table">
                  <tbody>
                    <tr>
                      <th className={classes.width}>宛名</th>
                      <td className={classes.length}>
                        <CustomTextField2
                          variant="outlined"
                          margin="dense"
                          required
                          fullWidth
                          id="name"
                          name="name"
                          autoComplete="name"
                          value={tempRequestData?.chargeName}
                          onChange={clickChargeName}
                          inputProps={{ maxLength: 50 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>郵便番号</th>
                      <td>
                        <CustomTextField2
                          variant="outlined"
                          margin="dense"
                          required
                          fullWidth
                          id="postalCode"
                          name="postalCode"
                          autoComplete="postalCode"
                          value={tempRequestData?.chargeZipCode}
                          onChange={clickChargeZipCode}
                          inputProps={{ maxLength: 7 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>住所</th>
                      <td>
                        <CustomTextField2
                          variant="outlined"
                          margin="dense"
                          required
                          fullWidth
                          id="address1"
                          name="address1"
                          autoComplete="address1"
                          value={tempRequestData?.chargeAddress1}
                          onChange={clickChargeAddress1}
                          inputProps={{ maxLength: 20 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>建物等</th>
                      <td>
                        <CustomTextField2
                          variant="outlined"
                          margin="dense"
                          required
                          fullWidth
                          id="address2"
                          name="address2"
                          autoComplete="address2"
                          value={tempRequestData?.chargeAddress2}
                          onChange={clickChargeAddress2}
                          inputProps={{ maxLength: 20 }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </MuiThemeProvider>
              <HistoryPanel
                type={addressType.InvoiceAddress}
                history={destinationHistory}
                requestData={tempRequestData}
                updateRequest={(values: Request) => {
                  setTempRequestData(values);
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InvoiceAddressDialog;
