import { FC, Key, ReactEventHandler, SyntheticEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Table } from 'antd';
import { Box } from '@material-ui/core';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: '#87cefa',
    },
    confirm: {
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: '#1769aa',
      },
    },
    cancel: {
      maxWidth: 110,
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: 'darkgray',
      },
    },
    header: {
      textAlign: 'center',
    },
    dataRow: {
      margin: theme.spacing(0, 1),
    },
    dialogTitle: {
      fontSize: 24,
    },
  }),
);

export type Address = {
  key: Key;
  address: string;
};

export type AddressSelectDialogProps = {
  open: boolean;
  onClickClose: ReactEventHandler<HTMLButtonElement>;
  addressList: Address[];
  updateAddress?: (value: string) => void;
};

const AddressSelectDialog: FC<AddressSelectDialogProps> = ({
  open,
  onClickClose,
  addressList,
  updateAddress,
}) => {
  const classes = useStyles();
  const [selectAddress, setSelectAddress] = useState('');

  const setAddress = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (updateAddress) updateAddress(selectAddress);
    onClickClose(e);
  };

  const rowSelection = {
    onChange: (_selectedRowKeys: Key[], selectedRows: Address[]) => {
      // 選択した住所を保持
      setSelectAddress(selectedRows[0].address);
    },
  };

  const columns = [
    {
      title: () => <div className={classes.header}>住所</div>,
      dataIndex: 'address',
      key: 'address',
      width: 100,
      align: 'left' as const,
      render: (value: string) => (
        <span className={classes.dataRow}>{value}</span>
      ),
    },
  ];

  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={onClickClose}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>住所設定</Box>
        </DialogTitle>
        <DialogContent>
          <div className="staff-select">
            <Table
              dataSource={addressList}
              columns={columns}
              rowSelection={{ type: 'radio', ...rowSelection }}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </DialogContent>
        <MuiThemeProvider theme={theme2}>
          <DialogActions>
            <MuiThemeProvider theme={theme3}>
              <Button
                variant="contained"
                onClick={setAddress}
                color="primary"
                className={classes.confirm}
              >
                確定
              </Button>
            </MuiThemeProvider>
            <Button
              variant="contained"
              onClick={onClickClose}
              color="primary"
              className={classes.cancel}
            >
              キャンセル
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>
    </>
  );
};

export default AddressSelectDialog;
