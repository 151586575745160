import {
  FC,
  Key,
  ReactEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { Table } from 'antd';
import { StaffListModel } from 'types/User';
import { Pic } from 'types/RequestDataInfo';
import { mailSendType } from 'utils/CommonConst';
import { nonNullable } from 'utils/CommonFunctions';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import MessagePanel from './MessagePanel';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const theme4 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});

const theme5 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'red',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    register: {
      width: 75,
      margin: theme.spacing(3.5, 0, 0),
      '&:hover': {
        background: 'darkorange',
      },
    },
    delete: {
      width: 75,
      margin: theme.spacing(0, 8, 0),
      '&:hover': {
        background: '#dc143c',
      },
    },
    toDelete: {
      marginTop: 27,
    },
    confirm: {
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: '#1769aa',
      },
    },
    cancel: {
      maxWidth: 110,
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: 'darkgray',
      },
    },
    dialogTitle: {
      fontSize: 24,
    },
  }),
);

export type Data = {
  key: Key;
  section: string;
  name: string;
};

export type StaffSelectDialogProps = {
  open: boolean;
  onClickClose: ReactEventHandler<HTMLButtonElement>;
  picList?: Pic[];
  picAll?: StaffListModel[];
  updatePic?: (value: Pic[]) => void;
  picType?: number;
};

const StaffSelectDialog: FC<StaffSelectDialogProps> = ({
  open,
  onClickClose,
  picList,
  picAll,
  updatePic,
  picType,
}) => {
  const classes = useStyles();

  const [selectedPic, setSelectedPic] = useState<StaffListModel[]>([]);
  const [selectedToPic, setSelectedToPic] = useState<Pic[]>([]);
  const [selectedCcPic, setSelectedCcPic] = useState<Pic[]>([]);
  const [selectedBccPic, setSelectedBccPic] = useState<Pic[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<Key[]>([]);
  const [selectedToRowKey, setSelectedToRowKey] = useState<Key[]>([]);
  const [selectedCcRowKey, setSelectedCcRowKey] = useState<Key[]>([]);
  const [selectedBccRowKey, setSelectedBccRowKey] = useState<Key[]>([]);
  const [toPic, setToPic] = useState<Pic[]>([]);
  const [ccPic, setCcPic] = useState<Pic[]>([]);
  const [bccPic, setBccPic] = useState<Pic[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);

  /* non eslint-disable  */
  useEffect(() => {
    const tempToPic: Pic[] = [];
    const tempCcPic: Pic[] = [];
    const tempBccPic: Pic[] = [];
    picList?.forEach((pic) => {
      switch (pic.mailSendType) {
        case mailSendType.To:
          tempToPic.push({
            id: pic.id,
            section: pic.section,
            name: pic.name,
            mail: pic.mail,
            tel: pic.tel,
            sortNo: pic.sortNo,
            picType: pic.picType,
            mailSendType: pic.mailSendType,
            isEnable: pic.isEnable,
          });
          break;
        case mailSendType.Cc:
          tempCcPic.push({
            id: pic.id,
            section: pic.section,
            name: pic.name,
            mail: pic.mail,
            tel: pic.tel,
            sortNo: pic.sortNo,
            picType: pic.picType,
            mailSendType: pic.mailSendType,
            isEnable: pic.isEnable,
          });
          break;
        case mailSendType.Bcc:
          tempBccPic.push({
            id: pic.id,
            section: pic.section,
            name: pic.name,
            mail: pic.mail,
            tel: pic.tel,
            sortNo: pic.sortNo,
            picType: pic.picType,
            mailSendType: pic.mailSendType,
            isEnable: pic.isEnable,
          });
          break;
        default:
      }
      setToPic(tempToPic);
      setCcPic(tempCcPic);
      setBccPic(tempBccPic);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picList]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: StaffListModel[]) => {
      setSelectedPic(selectedRows);
      setSelectedRowKey(selectedRowKeys);
    },
  };

  const toRowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: Pic[]) => {
      setSelectedToPic(selectedRows);
      setSelectedToRowKey(selectedRowKeys);
    },
  };

  const ccRowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: Pic[]) => {
      setSelectedCcPic(selectedRows);
      setSelectedCcRowKey(selectedRowKeys);
    },
  };

  const bccRowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: Pic[]) => {
      setSelectedBccPic(selectedRows);
      setSelectedBccRowKey(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: '所属',
      dataIndex: 'section',
      key: 'section',
      width: 100,
      align: 'center' as const,
    },
    {
      title: '担当者',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      align: 'center' as const,
    },
  ];

  // To一覧への追加処理
  const toAdd = () => {
    setIsHide(true);
    const tempToPic: Pic[] = [...toPic];
    if (tempToPic.length === 1) {
      setIsHide(false);
      setErrorMessages([
        'To に設定できるのは１人だけです。To の担当者を削除して登録してください。',
      ]);

      return;
    }
    if (selectedPic.length === 0) {
      setIsHide(false);
      setErrorMessages([
        '担当者が選択されていません。担当者一覧から１人だけ選択してください。',
      ]);

      return;
    }
    if (selectedPic.length > 1) {
      setIsHide(false);
      setErrorMessages([
        'To に設定できるのは１人だけです。担当者一覧から１人だけ選択してください。',
      ]);

      return;
    }
    selectedPic.forEach((pic) => {
      if (!tempToPic.find((n) => n.id === pic.picID)) {
        tempToPic.push({
          id: pic.picID ?? 0,
          section: pic.section,
          name: pic.name,
          mail: pic.mail,
          tel: pic.tel,
          sortNo: pic.sortNo ?? 0,
          picType: picType ?? 0,
          mailSendType: mailSendType.To,
          isEnable: pic.isEnable ?? true,
        });
      }
    });
    setToPic(tempToPic);
    setSelectedPic([]);
    setSelectedRowKey([]);
  };

  // Cc一覧への追加処理
  const ccAdd = () => {
    setIsHide(true);
    const tempCcPic: Pic[] = [...ccPic];
    if (selectedPic.length === 0) {
      setIsHide(false);
      setErrorMessages([
        '担当者が選択されていません。担当者一覧から選択してください。',
      ]);

      return;
    }
    selectedPic.forEach((pic) => {
      if (!tempCcPic.find((n) => n.id === pic.picID)) {
        tempCcPic.push({
          id: pic.picID ?? 0,
          section: pic.section,
          name: pic.name,
          mail: pic.mail,
          tel: pic.tel,
          sortNo: pic.sortNo ?? 0,
          picType: picType ?? 0,
          mailSendType: mailSendType.Cc,
          isEnable: pic.isEnable ?? true,
        });
      }
    });
    setCcPic(tempCcPic);
    setSelectedPic([]);
    setSelectedRowKey([]);
  };

  // Bcc一覧への追加処理
  const bccAdd = () => {
    setIsHide(true);
    const tempBccPic: Pic[] = [...bccPic];
    if (selectedPic.length === 0) {
      setIsHide(false);
      setErrorMessages([
        '担当者が選択されていません。担当者一覧から選択してください。',
      ]);

      return;
    }
    selectedPic.forEach((pic) => {
      if (!tempBccPic.find((n) => n.id === pic.picID)) {
        tempBccPic.push({
          id: pic.picID ?? 0,
          section: pic.section,
          name: pic.name,
          mail: pic.mail,
          tel: pic.tel,
          sortNo: pic.sortNo ?? 0,
          picType: picType ?? 0,
          mailSendType: mailSendType.Bcc,
          isEnable: pic.isEnable ?? true,
        });
      }
    });
    setBccPic(tempBccPic);
    setSelectedPic([]);
    setSelectedRowKey([]);
  };

  // To一覧からの削除処理
  const toDelete = () => {
    setIsHide(true);
    if (selectedToPic.length === 0) {
      setIsHide(false);
      setErrorMessages([
        '担当者が選択されていません。To一覧から選択してください。',
      ]);

      return;
    }
    setToPic([]);
    setSelectedToPic([]);
    setSelectedToRowKey([]);
  };

  // Cc一覧からの削除処理
  const ccDelete = () => {
    setIsHide(true);
    const tempCcPic: Pic[] = [...ccPic];

    if (selectedCcPic.length === 0) {
      setIsHide(false);
      setErrorMessages([
        '担当者が選択されていません。Cc一覧から選択してください。',
      ]);

      return;
    }

    const data = tempCcPic
      .map((pic) => {
        if (selectedCcPic.find((n) => n.id === pic.id)) {
          return null;
        }

        return pic;
      })
      .filter(nonNullable);
    setCcPic(data);
    setSelectedCcPic([]);
    setSelectedCcRowKey([]);
  };

  // Bcc一覧からの削除処理
  const bccDelete = () => {
    setIsHide(true);
    const tempBccPic: Pic[] = [...bccPic];

    if (selectedBccPic.length === 0) {
      setIsHide(false);
      setErrorMessages([
        '担当者が選択されていません。Bcc一覧から選択してください。',
      ]);

      return;
    }

    const data = tempBccPic
      .map((pic) => {
        if (selectedBccPic.find((n) => n.id === pic.id)) {
          return null;
        }

        return pic;
      })
      .filter(nonNullable);
    setBccPic(data);
    setSelectedBccPic([]);
    setSelectedBccRowKey([]);
  };

  // 設定ボタン
  const confirmation = (event: SyntheticEvent<HTMLButtonElement>) => {
    // 入力チェック
    if (toPic.length === 0) {
      setIsHide(false);
      setErrorMessages(['Toに担当者を設定してください。']);

      return;
    }
    setIsHide(true);

    const data = [...toPic, ...ccPic, ...bccPic];
    if (updatePic) updatePic(data);
    onClickClose(event);
  };

  // ダイアログを閉じる
  const dialogClose = (event: SyntheticEvent<HTMLButtonElement>) => {
    setIsHide(true);
    setErrorMessages(['']);
    onClickClose(event);
  };

  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={dialogClose}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>担当者設定</Box>
        </DialogTitle>
        <DialogContent>
          <MessagePanel messages={errorMessages} isHide={isHide} />
          <div className={classes.form}>
            <div className="staff-select">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography component="h1" variant="h5">
                    担当者一覧
                  </Typography>
                  <Table
                    dataSource={picAll}
                    columns={columns}
                    rowSelection={{
                      selectedRowKeys: selectedRowKey,
                      ...rowSelection,
                    }}
                    pagination={{ pageSize: 20 }}
                    rowKey="picID"
                  />
                </Grid>
                <Grid item xs={1}>
                  <MuiThemeProvider theme={theme4}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.register}
                      onClick={toAdd}
                    >
                      To ≫
                    </Button>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.register}
                      onClick={ccAdd}
                    >
                      Cc ≫
                    </Button>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.register}
                      onClick={bccAdd}
                    >
                      Bcc ≫
                    </Button>
                    <br />
                  </MuiThemeProvider>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <MuiThemeProvider theme={theme5}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={clsx(classes.delete, classes.toDelete)}
                          onClick={toDelete}
                        >
                          ≪ To
                        </Button>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography component="h1" variant="h5">
                        To：一人まで
                      </Typography>
                      <Table
                        dataSource={toPic}
                        columns={columns}
                        rowSelection={{
                          selectedRowKeys: selectedToRowKey,
                          ...toRowSelection,
                        }}
                        pagination={false}
                        rowKey="id"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MuiThemeProvider theme={theme5}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.delete}
                          onClick={ccDelete}
                        >
                          ≪ Cc
                        </Button>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item xs={9}>
                      <Table
                        dataSource={ccPic}
                        columns={columns}
                        rowSelection={{
                          selectedRowKeys: selectedCcRowKey,
                          ...ccRowSelection,
                        }}
                        pagination={false}
                        rowKey="id"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MuiThemeProvider theme={theme5}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.delete}
                          onClick={bccDelete}
                        >
                          ≪ Bcc
                        </Button>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item xs={9}>
                      <Table
                        dataSource={bccPic}
                        columns={columns}
                        rowSelection={{
                          selectedRowKeys: selectedBccRowKey,
                          ...bccRowSelection,
                        }}
                        pagination={false}
                        rowKey="id"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <MuiThemeProvider theme={theme2}>
          <DialogActions>
            <MuiThemeProvider theme={theme3}>
              <Button
                variant="contained"
                onClick={confirmation}
                color="primary"
                className={classes.confirm}
              >
                確定
              </Button>
            </MuiThemeProvider>
            <Button
              variant="contained"
              onClick={dialogClose}
              color="primary"
              className={classes.cancel}
            >
              キャンセル
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>
    </>
  );
};

export default StaffSelectDialog;
