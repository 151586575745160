import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Task,
  Request,
  RecieptMesitemIndex,
  TaskSample,
} from 'types/RequestDataInfo';
import { API_URL } from 'utils/CommonConst';
import { consoleLog, methodGet } from 'utils/CommonFunctions';
import MessagePanel from './MessagePanel';
import CommonDialog from './CommonDialog';

const theme2 = createTheme({
  overrides: {
    MuiDialogActions: {
      root: {
        justifyContent: 'right',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const theme4 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'red',
      },
    },
  },
});

const theme5 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(1, 5, 5),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    confirm: {
      margin: theme.spacing(0, 3, 0),
      '&:hover': {
        background: '#1769aa',
      },
    },
    delete: {
      margin: theme.spacing(0, 3, 0),
      '&:hover': {
        background: '#dc143c',
      },
    },
    return: {
      '&:hover': {
        background: 'darkgray',
      },
    },
    checkboxJapanese: {
      margin: theme.spacing(-1.2, 1.7, 0),
    },
    checkboxEnglish: {
      margin: theme.spacing(-1.2, -1.9, 0),
    },
    width: {
      width: 400,
    },
    text: {
      margin: theme.spacing(3, 0, -5),
      color: 'red',
      fontSize: 16,
    },
    dialogTitle: {
      fontSize: 24,
    },
    titleSpace: {
      margin: theme.spacing(3, 0, 1),
    },
    textColor: {
      color: 'red',
    },
    length: {
      width: 800,
    },
    titleWidth: {
      width: 150,
    },
    unit: {
      margin: theme.spacing(1.8, 2, 0),
    },
    quantity: {
      width: 100,
    },
    flex: {
      display: 'flex',
    },
    mesitemIndex: {
      width: '100%',
    },
    toTopDiv: {
      textAlign: 'right',
      margin: theme.spacing(2, 0, 0),
    },
    toTopButton: {
      width: 100,
      margin: theme.spacing(0, 0, 0),
    },
    disabledItem: {
      backgroundColor: 'lightgray',
    },
    enabledItem: {
      backgroundColor: 'white',
    },
    itemTitleTop: {
      fontWeight: 'bold',
    },
    itemTitle: {
      fontWeight: 'bold',
      marginTop: 20,
    },
  }),
);
/* non eslint-disable */
export type InspectionItem = {
  code: number;
  groupIndex: number;
  sortNo: number;
  mesItemName: string;
  existsComment: boolean;
};

export type BusinessContentDialogProps = {
  open: boolean;
  onClickClose: () => void;
  requestData?: Request;
  task?: Task | null;
  updateTask?: (value: Task, isDelete?: boolean) => void;
  isEdit?: boolean;
};

const BusinessContentDialog: FC<BusinessContentDialogProps> = ({
  open,
  onClickClose,
  requestData,
  task,
  updateTask,
  isEdit = true,
}) => {
  const classes = useStyles();

  const [tempTask, setTempTask] = useState<Task | null>(null);
  const [mesitemIndex, setMesitemIndex] = useState<RecieptMesitemIndex[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);
  const refInput = useRef<HTMLButtonElement>(null);
  const [commDlg, setCommDlg] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();

  const text =
    '英文の結果報告書が必要な場合、 ¥n チェックをつけて必要事項を入力してください。';
  const breakedText = text.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  useEffect(() => {
    if (requestData) {
      if (requestData.id === 0) {
        // 検査項目マスタの取得
        const getMesitemIndex = async () => {
          try {
            const url = `${API_URL}api/v1/masters/mesitemindex/${requestData.recieptType1}`;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const res = await methodGet(url, false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const data = res.data as RecieptMesitemIndex[];
            setMesitemIndex(data);
          } catch (ex) {
            consoleLog(ex);
          }
        };
        getMesitemIndex().finally(() => {
          /* Nothing to do. */
        });
      } else {
        // 検査項目マスタの取得
        const getMesitemIndex = async () => {
          try {
            const url = `${API_URL}api/v1/requests/${requestData.id}/mesitemindex`;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const res = await methodGet(url, false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const data = res.data as RecieptMesitemIndex[];
            setMesitemIndex(data);
          } catch (ex) {
            consoleLog(ex);
          }
        };
        getMesitemIndex().finally(() => {
          /* Nothing to do. */
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData?.id, requestData?.recieptType1]);

  useEffect(() => {
    if (task) {
      const temp = { ...task } as Task;
      const temp2 = [...task.taskSampleList] as TaskSample[];
      temp.taskSampleList = temp2;
      setTempTask(temp);
    }
  }, [task]);

  // トップへ移動
  const scrollToTop = () => {
    refInput?.current?.focus();
  };

  // 和文選択
  const clickIsJapanese = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    const value = event.target.checked;
    data.isJapanese = value;
    setTempTask(data);
  };

  // 英文選択
  const clickIsEnglish = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    const value = event.target.checked;
    data.isEnglish = value;
    setTempTask(data);
  };

  // 試験品名
  const changeNameSampleIndex = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.nameSampleIndex = event.target.value;
    setTempTask(data);
  };

  // 試験品名英文
  const changeNameSampleIndexEn = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.nameSampleIndexEn = event.target.value;
    setTempTask(data);
  };

  // 採取場所
  const changeSamplePlace = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.samplePlace = event.target.value;
    setTempTask(data);
  };

  // 採取場所英
  const changeSamplePlaceEn = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.samplePlaceEn = event.target.value;
    setTempTask(data);
  };

  // 採取者氏名
  const changeSampler = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.sampler = event.target.value;
    setTempTask(data);
  };

  // 採取者氏名英
  const changeSamplerEn = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.samplerEn = event.target.value;
    setTempTask(data);
  };

  // 採取年月日種類
  const changeDateSamplingType = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    const value = Number(event.target.value);
    data.dateSamplingType = value;
    setTempTask(data);
  };

  // 採取年月日
  const changeDateSampling = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.dateSampling = event.target.value;
    setTempTask(data);
  };

  // 採取年月日自由
  const changeDateSamplingFree = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.dateSamplingFree = event.target.value;
    setTempTask(data);
  };

  // 採取時状態
  const changeSampleStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    const value = Number(event.target.value);
    data.sampleStatus = value;
    setTempTask(data);
  };

  // 採取量
  const changeSampleWeight = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.sampleWeight = event.target.value;
    setTempTask(data);
  };

  // 採取数量
  const changeSampleQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.sampleQuantity = event.target.value;
    setTempTask(data);
  };

  // 試験品外観
  const changeSampleAppearance = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    const value = Number(event.target.value);
    data.sampleAppearance = value;
    setTempTask(data);
  };

  // 試験品外観その他
  const changeSampleAppearanceOthers = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const data = { ...tempTask } as Task;
    data.sampleAppearanceOthers = event.target.value;
    setTempTask(data);
  };

  // 搬送条件
  const changeTransportConditions = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    const value = Number(event.target.value);
    data.transportConditions = value;
    setTempTask(data);
  };

  // 備考
  const changeComment = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...tempTask } as Task;
    data.comment = event.target.value;
    setTempTask(data);
  };

  // 確定ボタン
  const confirmation = () => {
    // 入力チェック
    if (tempTask) {
      let error = false;
      const message: string[] = [];

      if (!tempTask.isJapanese && !tempTask.isEnglish) {
        message.push('和文・英文を選択してください。');
        error = true;
      }
      if (
        (tempTask.isJapanese && !tempTask.nameSampleIndex) ||
        (tempTask.isEnglish && !tempTask.nameSampleIndexEn)
      ) {
        message.push('試験品名を入力してください。');
        error = true;
      }
      if (tempTask.taskSampleList.length === 0) {
        message.push('検査項目を入力してください。');
        error = true;
      }
      if (tempTask.sampleWeight) {
        const num = Number(tempTask.sampleWeight);
        if (!Number.isFinite(num)) {
          message.push('採取量は数値で入力してください。');
          error = true;
        }
      }
      if (tempTask.sampleQuantity) {
        const num = Number(tempTask.sampleQuantity);
        if (!Number.isInteger(num)) {
          message.push('採取数量は数値で入力してください。');
          error = true;
        } else {
          tempTask.sampleQuantity = num.toString();
        }
      }
      if (error) {
        setIsHide(false);
        setErrorMessages(message);
        scrollToTop();

        return;
      }

      setIsHide(true);
      if (updateTask) updateTask(tempTask);
      onClickClose();
    }
  };

  // 削除ボタン
  const clickDelete = () => {
    setIsHide(true);
    const text2 = `削除します。¥nよろしいですか？`;
    const breakedText2 = text2.split('¥n').map((line) => (
      <span key={line}>
        {line}
        <br />
      </span>
    ));

    setConfirmMessages(breakedText2);
    setCommDlg(true);
  };

  // 削除処理
  const deleteProc = () => {
    if (tempTask) {
      if (updateTask) updateTask(tempTask, true);
      setCommDlg(false);
      onClickClose();
    }
  };

  // ダイアログを閉じる
  const dialogClose = () => {
    setIsHide(true);
    setErrorMessages(['']);
    onClickClose();
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={dialogClose}>
      <DialogTitle className={classes.color}>
        <Box className={classes.dialogTitle}>依頼内容設定</Box>
      </DialogTitle>
      <DialogContent>
        <div className={classes.paper}>
          <Box id="tops" className={classes.text}>
            ※試験品名と検査項目は必須です。
            <br />
            その他の項目は空欄でも構いません。
          </Box>
          <div className={classes.form}>
            <MuiThemeProvider theme={theme2}>
              <DialogActions>
                <MuiThemeProvider theme={theme3}>
                  <Button
                    variant="contained"
                    onClick={confirmation}
                    color="primary"
                    className={classes.confirm}
                    ref={refInput}
                  >
                    確定
                  </Button>
                </MuiThemeProvider>
                <MuiThemeProvider theme={theme4}>
                  <Button
                    variant="contained"
                    onClick={clickDelete}
                    color="primary"
                    className={classes.delete}
                    hidden={!isEdit}
                  >
                    削除
                  </Button>
                </MuiThemeProvider>
                <MuiThemeProvider theme={theme5}>
                  <Button
                    variant="contained"
                    onClick={dialogClose}
                    color="primary"
                    className={classes.return}
                  >
                    キャンセル
                  </Button>
                </MuiThemeProvider>
                <CommonDialog
                  msg={confirmMessages}
                  isOpen={commDlg}
                  doYes={deleteProc}
                  doNo={() => {
                    setCommDlg(false);
                  }}
                />
              </DialogActions>
            </MuiThemeProvider>
            <MessagePanel messages={errorMessages} isHide={isHide} />
            <Box>
              結果報告書
              <FormControlLabel
                control={
                  <Checkbox color="primary" onChange={clickIsJapanese} />
                }
                label="和文"
                className={classes.checkboxJapanese}
                checked={tempTask?.isJapanese ?? false}
                name="isJapanese"
                id="isJapanese"
              />
              <Tooltip title={breakedText} placement="right">
                <FormControlLabel
                  control={
                    <Checkbox color="primary" onChange={clickIsEnglish} />
                  }
                  label="英文"
                  className={classes.checkboxEnglish}
                  checked={tempTask?.isEnglish ?? false}
                  name="isEnglish"
                  id="isEnglish"
                />
              </Tooltip>
            </Box>
            <Typography
              component="h1"
              variant="h5"
              className={classes.titleSpace}
            >
              基本情報
            </Typography>
            <table className="business-content">
              <tbody>
                <tr>
                  <th>項目</th>
                  <th className={classes.width}>和文用</th>
                  <th className={classes.width}>英文用</th>
                </tr>
                <tr>
                  <td>
                    試験品名
                    <span
                      style={{ float: 'right' }}
                      className={classes.textColor}
                    >
                      (必須)
                    </span>
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="nameSampleIndex"
                      name="nameSampleIndex"
                      autoComplete="nameSampleIndex"
                      value={tempTask?.nameSampleIndex ?? ''}
                      onChange={changeNameSampleIndex}
                      disabled={!tempTask?.isJapanese}
                      className={
                        !tempTask?.isJapanese
                          ? classes.disabledItem
                          : classes.enabledItem
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="nameSampleIndexEn"
                      name="nameSampleIndexEn"
                      autoComplete="nameSampleIndexEn"
                      value={tempTask?.nameSampleIndexEn ?? ''}
                      onChange={changeNameSampleIndexEn}
                      disabled={!tempTask?.isEnglish}
                      className={
                        !tempTask?.isEnglish
                          ? classes.disabledItem
                          : classes.enabledItem
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>採取場所</td>
                  <td>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="samplePlace"
                      name="samplePlace"
                      autoComplete="samplePlace"
                      value={tempTask?.samplePlace ?? ''}
                      onChange={changeSamplePlace}
                      disabled={!tempTask?.isJapanese}
                      className={
                        !tempTask?.isJapanese
                          ? classes.disabledItem
                          : classes.enabledItem
                      }
                    />
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="samplePlaceEn"
                      name="samplePlaceEn"
                      autoComplete="samplePlaceEn"
                      value={tempTask?.samplePlaceEn ?? ''}
                      onChange={changeSamplePlaceEn}
                      disabled={!tempTask?.isEnglish}
                      className={
                        !tempTask?.isEnglish
                          ? classes.disabledItem
                          : classes.enabledItem
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>採取者氏名</td>
                  <td>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="sampler"
                      name="sampler"
                      autoComplete="sampler"
                      value={tempTask?.sampler ?? ''}
                      onChange={changeSampler}
                      disabled={!tempTask?.isJapanese}
                      className={
                        !tempTask?.isJapanese
                          ? classes.disabledItem
                          : classes.enabledItem
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  </td>
                  <td>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="samplerEn"
                      name="samplerEn"
                      autoComplete="samplerEn"
                      value={tempTask?.samplerEn ?? ''}
                      onChange={changeSamplerEn}
                      disabled={!tempTask?.isEnglish}
                      className={
                        !tempTask?.isEnglish
                          ? classes.disabledItem
                          : classes.enabledItem
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>採取年月日</td>
                  <td colSpan={2}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="date"
                        value={tempTask?.dateSamplingType ?? 0}
                        onChange={changeDateSamplingType}
                      >
                        <div>
                          <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label="日付指定"
                          />
                          <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            type="date"
                            id="dateSampling"
                            name="dateSampling"
                            value={tempTask?.dateSampling ?? ''}
                            onChange={changeDateSampling}
                            disabled={tempTask?.dateSamplingType !== 1}
                            className={
                              tempTask?.dateSamplingType !== 1
                                ? classes.disabledItem
                                : classes.enabledItem
                            }
                          />
                          <br />
                          <FormControlLabel
                            value={2}
                            control={<Radio color="primary" />}
                            label="自由入力"
                          />
                          <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            id="dateSamplingFree"
                            name="dateSamplingFree"
                            value={tempTask?.dateSamplingFree ?? ''}
                            onChange={changeDateSamplingFree}
                            disabled={tempTask?.dateSamplingType !== 2}
                            className={
                              tempTask?.dateSamplingType !== 2
                                ? classes.disabledItem
                                : classes.enabledItem
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </td>
                </tr>
              </tbody>
            </table>
            <Typography
              component="h1"
              variant="h5"
              className={classes.titleSpace}
            >
              その他項目
            </Typography>
            <table className="business-content">
              <tbody>
                <tr>
                  <th className={classes.titleWidth}>項目</th>
                  <th className={classes.length}>入力内容</th>
                </tr>
                <tr>
                  <td>採取量・数量</td>
                  <td>
                    <div className={classes.flex}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        id="sampleWeight"
                        name="sampleWeight"
                        className={classes.quantity}
                        value={tempTask?.sampleWeight ?? ''}
                        onChange={changeSampleWeight}
                        inputProps={{ maxLength: 20 }}
                      />
                      <Box className={classes.unit}>グラム</Box>
                    </div>
                    <div className={classes.flex}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        id="sampleQuantity"
                        name="sampleQuantity"
                        className={classes.quantity}
                        value={tempTask?.sampleQuantity ?? ''}
                        onChange={changeSampleQuantity}
                        inputProps={{ maxLength: 20 }}
                      />
                      <Box className={classes.unit}>個</Box>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>採取時の状態</td>
                  <td>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="state"
                        value={tempTask?.sampleStatus ?? 0}
                        onChange={changeSampleStatus}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="常温"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label="冷蔵"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio color="primary" />}
                          label="冷凍"
                        />
                      </RadioGroup>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>試験品の外観</td>
                  <td>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="exterior"
                        value={tempTask?.sampleAppearance ?? 0}
                        onChange={changeSampleAppearance}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="新鮮"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label="その他"
                        />
                        <TextField
                          variant="outlined"
                          margin="dense"
                          required
                          id="sampleAppearanceOthers"
                          name="sampleAppearanceOthers"
                          value={tempTask?.sampleAppearanceOthers ?? ''}
                          onChange={changeSampleAppearanceOthers}
                          disabled={tempTask?.sampleAppearance !== 2}
                          className={
                            tempTask?.sampleAppearance !== 2
                              ? classes.disabledItem
                              : classes.enabledItem
                          }
                          inputProps={{ maxLength: 50 }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>搬送条件</td>
                  <td>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="conditions"
                        value={tempTask?.transportConditions ?? 0}
                        onChange={changeTransportConditions}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="常温"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label="冷蔵"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio color="primary" />}
                          label="冷凍"
                        />
                      </RadioGroup>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>
                    検査項目
                    <span
                      style={{ float: 'right' }}
                      className={classes.textColor}
                    >
                      (必須)
                    </span>
                    <br />
                    <br />
                    <span
                      hidden={
                        !(requestData?.dspRecieptType1 === '栄養成分検査')
                      }
                    >
                      セット項目の詳細は
                      <a
                        href="http://www.yobou.or.jp/shokuhin/%e6%a4%9c%e6%9f%bb/%e6%a0%84%e9%a4%8a%e6%88%90%e5%88%86%e6%a4%9c%e6%9f%bb/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ホームページ
                      </a>
                      をご確認ください。
                    </span>
                  </td>
                  <td>
                    {mesitemIndex.map((item) => (
                      <span key={item.code} className={classes.mesitemIndex}>
                        {!item.showCheckBox && (
                          <Box
                            className={
                              item.sortNo === 1
                                ? classes.itemTitleTop
                                : classes.itemTitle
                            }
                          >
                            {item.mesItemName}
                          </Box>
                        )}
                        {item.existsComment && <br />}
                        {item.showCheckBox && (
                          <FormControlLabel
                            value={`${item.code}`}
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const data = { ...tempTask } as Task;
                                  if (event.target.checked) {
                                    const taskSample: TaskSample = {
                                      id: data.taskSampleList.length + 1,
                                      idMesItem: item.code,
                                      mesItemName: item.mesItemName,
                                      comment: '',
                                    };

                                    data.taskSampleList.push(taskSample);
                                  } else {
                                    data.taskSampleList =
                                      data.taskSampleList.filter(
                                        (n) => n.idMesItem !== item.code,
                                      );
                                  }
                                  setTempTask(data);
                                }}
                              />
                            }
                            label={`${item.mesItemName}${item.comment ?? ''}`}
                            name={item.mesItemName}
                            id={item.mesItemName}
                            checked={
                              tempTask?.taskSampleList.find(
                                (n) => n.idMesItem === item.code,
                              ) != null ?? false
                            }
                          />
                        )}
                        {item.existsComment && (
                          <>
                            <br />
                            <TextField
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              required
                              multiline
                              id={`${item.mesItemName}Text`}
                              name={`${item.mesItemName}Text`}
                              disabled={
                                !(
                                  tempTask?.taskSampleList.find(
                                    (n) => n.idMesItem === item.code,
                                  ) != null ?? false
                                )
                              }
                              value={
                                tempTask?.taskSampleList.find(
                                  (n) => n.idMesItem === item.code,
                                )?.comment ?? ''
                              }
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                              ) => {
                                const data = { ...tempTask } as Task;
                                if (tempTask) {
                                  const target = data.taskSampleList.find(
                                    (n) => n.idMesItem === item.code,
                                  );
                                  if (target != null) {
                                    target.comment = event.target.value;
                                    data.taskSampleList =
                                      data.taskSampleList.filter(
                                        (n) => n.idMesItem !== item.code,
                                      );
                                    data.taskSampleList.push(target);
                                    setTempTask(data);
                                  }
                                }
                              }}
                            />
                          </>
                        )}
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td>備考</td>
                  <td>
                    <div className={classes.flex}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        id="comment"
                        name="comment"
                        multiline
                        fullWidth
                        value={tempTask?.comment ?? ''}
                        onChange={changeComment}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <MuiThemeProvider theme={theme2}>
              <DialogActions>
                <MuiThemeProvider theme={theme3}>
                  <Button
                    variant="contained"
                    onClick={confirmation}
                    color="primary"
                    className={classes.confirm}
                  >
                    確定
                  </Button>
                </MuiThemeProvider>
                <MuiThemeProvider theme={theme4}>
                  <Button
                    variant="contained"
                    onClick={clickDelete}
                    color="primary"
                    className={classes.delete}
                    hidden={!isEdit}
                  >
                    削除
                  </Button>
                </MuiThemeProvider>
                <MuiThemeProvider theme={theme5}>
                  <Button
                    variant="contained"
                    onClick={dialogClose}
                    color="primary"
                    className={classes.return}
                  >
                    キャンセル
                  </Button>
                </MuiThemeProvider>
              </DialogActions>
            </MuiThemeProvider>
            <div className={classes.toTopDiv}>
              <Button
                variant="contained"
                color="primary"
                className={classes.toTopButton}
                onClick={scrollToTop}
              >
                トップへ
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BusinessContentDialog;
