import { FC } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(() =>
  createStyles({
    info: {
      backgroundColor: '#0381FF',
      color: '#FFF',
      marginTop: 10,
      marginBottom: 20,
      padding: 10,
      width: '100%',
    },
    error: {
      backgroundColor: '#F8D6DA',
      color: '#711623',
      marginTop: 10,
      marginBottom: 20,
      padding: 10,
      width: '100%',
    },
  }),
);

export type Mode = 'info' | 'error';

export type MessagePanelProps = {
  title?: string;
  messages?: string[];
  isHide?: boolean;
  mode?: Mode;
};

const MessagePanel: FC<MessagePanelProps> = ({
  title,
  messages = [],
  isHide = true,
  mode = 'error',
}) => {
  const classes = useStyles();

  return (
    <Card
      className={mode === 'info' ? classes.info : classes.error}
      hidden={isHide}
    >
      {title && (
        <CardHeader title={title} titleTypographyProps={{ variant: 'h4' }} />
      )}
      <CardContent>
        {messages.length !== 0 &&
          messages.map((message, index) => (
            <Box key={index.toString()}>
              {message}
              <br />
            </Box>
          ))}
      </CardContent>
    </Card>
  );
};

export default MessagePanel;
