import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AccountStaffInput from 'components/molecules/AccountStaffInput';
import LoginIDInput from 'components/molecules/LoginIDInput';
import PasswordInput from 'components/molecules/PasswordInput';
import RegalPersonalityInput from 'components/molecules/RegalPersonalityInput';
import PostalCodeInput from 'components/molecules/PostalCodeInput';
import StaffTempRegistrationDialog from 'components/molecules/StaffTempRegistrationDialog';
import StaffDeleteDialog from 'components/molecules/StaffDeleteDialog';
import { Table, Button as Button2, Tooltip } from 'antd';
import { AccountInfo, RegisterAccountInfo, PicList } from 'types/User';
import { API_URL, rootDir } from 'utils/CommonConst';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkPassword,
  consoleLog,
  debugLog,
  loginIDCheckFunc,
  mailCheckFunc2,
  masterCheckFunc,
  methodGet,
  methodPost,
  methodPut,
  requestCheckFunc,
  requestDeleteFunc,
} from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import CustomTextField from 'components/molecules/CustomTextField';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';
import { User } from '../../types/User';
/* non eslint-disable  */
const theme1 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});
const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});
const theme3 = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});
const theme4 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(-3, 0, 7),
    },
    masterSubmit: {
      margin: theme.spacing(3, 0, 7),
    },
    backButton: {
      margin: theme.spacing(-3, 5, 7),
    },
    length: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: 600,
    },
    tooltip: {
      margin: theme.spacing(1, 4, 2),
      '&:hover': {
        background: 'gray',
      },
    },
    button: {
      margin: theme.spacing(1, 4, 2),
      '&:hover': {
        background: 'orange',
      },
    },
    button2: {
      margin: theme.spacing(1, 2, 2),
      '&:hover': {
        background: 'green',
      },
    },
    space: {
      margin: theme.spacing(0, 2, 0),
    },
    staffBlock: {
      margin: theme.spacing(-3, 0, 1),
    },
    line: {
      marginBlockEnd: theme.spacing(7),
    },
  }),
);

export type AccountInfoPageProps = {
  mode?: 'register' | 'change' | 'master';
};

const AccountInfoPage: FC<AccountInfoPageProps> = ({ mode = 'register' }) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const telCheck = /\d{2,4}-\d{2,4}-\d{3,4}$/;
  const postalCodeCheck = /^[0-9]{7}$/;
  const mailCheck =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
  const loginIDCheckReg = /^[A-Za-z0-9]{8,}/;

  const { search } = useLocation();
  const query2 = new URLSearchParams(search);
  const username = query2.get('username') ?? '';
  const requestID = query2.get('request') ?? '';

  const [isLoading, setIsLoading] = useState(true);
  const [isMaster, setIsMaster] = useState(false);

  const [openTemp, setOpenTemp] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dispMode, setDispMode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accountInfo, setAcountInfo] = useState<User>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [mode2, setMode] = useState<Mode>('info');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [accountInfo2, setAccountInfo2] = useState<User>();
  const [picData, setPicData] = useState<PicList[]>([]);
  const [picList, setPicList] = useState<PicList>();
  const navigate = useNavigate();
  const location = useLocation();
  const { idCust } = (location.state as { idCust: number }) || {
    idCust: 0,
  };
  const [accountPicName, setAccountPicName] = useState('');
  const [accountPicMail, setAccountPicMail] = useState('');
  const [accountPicTel, setAccountPicTel] = useState('');

  // トップへ移動
  const scrollToTop = () => {
    scroller.scrollTo('TopPage', {
      duration: 250,
      delay: 100,
      smooth: true,
      containerId: 'HeaderContent',
      offset: -150, // Scrolls to element + 50 pixels down the page
    });
  };

  // 基本情報の取得
  const getData = async () => {
    if (appContext.state.LoginUser.isLogin) {
      try {
        const url = `${API_URL}api/v1/users/${
          idCust === 0 ? appContext.state.LoginUser.idCust ?? '0' : idCust
        }`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as User;
        setAcountInfo(data);
        setAccountInfo2(data);
        setIsLoading(false);
      } catch (ex) {
        consoleLog(ex);
        setIsLoading(false);
      }
    }
  };

  // リクエストIDのチェック
  const requestIDCheck = async () => {
    try {
      const check = await requestCheckFunc(requestID);
      if (check) {
        const temp = { ...accountInfo } as User;
        temp.mail = username;
        const master = await masterCheckFunc(username);
        setIsMaster(master);
        setAcountInfo(temp);
      } else {
        setIsHide(false);
        setIsSuccess(true);
        setMode('error');
        setErrorMessages([
          'リクエストの有効期限が過ぎているか、不正なリクエストです。',
        ]);
      }
      setIsLoading(false);
    } catch (ex) {
      consoleLog(ex);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    switch (mode) {
      case 'register':
        // 登録時
        setDispMode('register');
        requestIDCheck().finally(() => {
          /* Nothing to do. */
        });
        break;
      case 'change': {
        // 変更時
        setDispMode('change');
        getData().finally(() => {
          /* Nothing to do. */
        });
        break;
      }
      case 'master':
        // 管理者時
        setDispMode('master');
        getData().finally(() => {
          /* Nothing to do. */
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, appContext.state.LoginUser]);

  const text =
    '担当者とは、依頼書・結果書・請求書に設定できる担当者です。 ¥n それぞれについての通知メールが登録したメールアドレスに届きます。';
  const breakedText = text.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  const insert: RegisterAccountInfo = {
    idCust: appContext.state.LoginUser?.idCust,
    custData: accountInfo?.custData ?? '',
    custName: accountInfo?.custName ?? '',
    furigana: accountInfo?.furigana ?? '',
    orgTypeAdd: accountInfo?.orgTypeAdd ?? 0,
    orgType: accountInfo?.orgType ?? 0,
    branchName: accountInfo?.branchName ?? '',
    zip: accountInfo?.zip ?? '',
    address1: accountInfo?.address1 ?? '',
    address2: accountInfo?.address2 ?? '',
    tel: accountInfo?.tel ?? '',
    fax: accountInfo?.fax ?? '',
    loginID: accountInfo?.loginID ?? '',
    picName: accountInfo?.picName ?? '',
    mail: accountInfo?.mail ?? '',
    picList: picData,
    password,
  };

  // 登録処理
  const postData = async () => {
    try {
      if (accountInfo) {
        const check = await loginIDCheckFunc(accountInfo?.loginID);
        const check2 = await mailCheckFunc2(accountInfo?.mail);

        if (check && check2) {
          if (isMaster) {
            const url = `${API_URL}api/v1/users/master`;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const res = await methodPost(url, insert, false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const resp = res.data as boolean;
            setIsHide(false);
            if (resp) {
              const del = await requestDeleteFunc(requestID);
              if (del) {
                setMode('info');
                setIsSuccess(true);
                setErrorMessages(['アカウント登録が完了しました。']);
              }
            } else {
              setMode('error');
              setErrorMessages(['アカウント登録が失敗しました。']);
            }
          } else {
            const url = `${API_URL}api/v1/users`;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const res = await methodPost(url, insert, false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const resp = res.data as boolean;
            setIsHide(false);
            if (resp) {
              const del = await requestDeleteFunc(requestID);
              if (del) {
                setMode('info');
                setIsSuccess(true);
                if (
                  insert.picList.filter((n) => n.mail !== accountInfo.mail)
                    .length > 0
                ) {
                  setErrorMessages([
                    'アカウント登録が完了しました。',
                    'アカウント登録完了のメールを送信しました。',
                    'また、担当者に認証用のメールを送信しました。内容を確認して担当者の登録を完了させてください。',
                  ]);
                } else {
                  setErrorMessages([
                    'アカウント登録が完了しました。',
                    'アカウント登録完了のメールを送信しました。',
                  ]);
                }
              }
            } else {
              setMode('error');
              setErrorMessages(['アカウント登録が失敗しました。']);
            }
          }
        } else if (!check || check === null) {
          setIsHide(false);
          setMode('error');
          setErrorMessages(['ログインIDが重複しています。']);
        } else if (!check2 || check2 === null) {
          setIsHide(false);
          setMode('error');
          setErrorMessages(['既に登録済のメールアドレスです。']);
        } else {
          setIsHide(false);
          setMode('error');
          setErrorMessages(['予期せぬエラーが発生しました。']);
        }
        scrollToTop();
      }
    } catch (ex) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['予期せぬエラーが発生しました。']);
      consoleLog(ex);
    }
  };

  // 登録ボタンクリック
  const clickInsert = () => {
    setIsLoading(true);
    postData().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
  };

  // 登録確認
  const clickRegister = () => {
    if (accountInfo) {
      let error = false;
      const message: string[] = [];
      if (!accountInfo.picName || accountInfo.picName === '') {
        message.push('・アカウント担当者名が入力されていません。');
        error = true;
      }
      if (!accountInfo.mail || accountInfo.mail === '') {
        message.push('・メールアドレスが入力されていません。');
        error = true;
      }
      if (
        accountInfo.mail &&
        accountInfo.mail !== '' &&
        !mailCheck.test(accountInfo.mail)
      ) {
        message.push('・メールアドレスの形式になっていません。');
        error = true;
      }
      if (!accountInfo.loginID || accountInfo.loginID === '') {
        message.push('・ログインIDが入力されていません。');
        error = true;
      }
      if (
        accountInfo.loginID &&
        accountInfo.loginID !== '' &&
        !loginIDCheckReg.test(accountInfo.loginID)
      ) {
        message.push('・ログインIDは8桁～12桁の半角英数字で入力してください。');
        error = true;
      }
      if (password === '') {
        message.push('・パスワードが入力されていません。');
        error = true;
      }
      if (confirmPassword === '') {
        message.push('・パスワード（確認）が入力されていません。');
        error = true;
      }
      if (
        password !== '' &&
        confirmPassword !== '' &&
        password !== confirmPassword
      ) {
        message.push('・パスワード（確認）が違います。');
        error = true;
      }
      if (password !== '') {
        if (!checkPassword(password, accountInfo.loginID)) {
          message.push('・パスワードがパスワードポリシーを満たしていません。');
          error = true;
        }
      }
      if (!isMaster) {
        if (!accountInfo.custName || accountInfo.custName === '') {
          message.push(
            '・名称（企業：企業名、個人：氏名）が入力されていません。',
          );
          error = true;
        }
        if (!accountInfo.furigana || accountInfo.furigana === '') {
          message.push('・フリガナが入力されていません。');
          error = true;
        }
        if (!accountInfo.zip || accountInfo.zip === '') {
          message.push('・郵便番号が入力されていません。');
          error = true;
        }
        if (
          accountInfo.zip &&
          accountInfo.zip !== '' &&
          !postalCodeCheck.test(accountInfo.zip)
        ) {
          message.push('・郵便番号は半角数字で7桁で入力してください。');
          error = true;
        }
        if (!accountInfo.address1 || accountInfo.address1 === '') {
          message.push('・住所が入力されていません。');
          error = true;
        }
        if (!accountInfo.tel || accountInfo.tel === '') {
          message.push('・電話番号が入力されていません。');
          error = true;
        }
        if (
          accountInfo.tel &&
          accountInfo.tel !== '' &&
          !telCheck.test(accountInfo.tel)
        ) {
          message.push(
            '・電話番号は半角数字と半角ハイフンで入力してください。',
          );
          message.push('　例）083-941-6300');
          error = true;
        }
        if (
          accountInfo.fax &&
          accountInfo.fax !== '' &&
          !telCheck.test(accountInfo.fax)
        ) {
          message.push(
            '・ファックス番号は半角数字と半角ハイフンで入力してください。',
          );
          message.push('　例）083-941-6400');
          error = true;
        }
        if (picData.length === 0) {
          message.push('・担当者が登録されていません。');
          error = true;
        }
      }
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);
        scrollToTop();

        return;
      }
      scrollToTop();
      setIsHide(true);
      if (isMaster) {
        clickInsert();
      } else {
        setDispMode('registerVerifi');
      }
    }
  };
  // 戻る（登録）
  const clickRegisterBack = () => {
    setDispMode('register');
  };
  // 変更確認
  const clickChange = () => {
    const check = (data: User, data2: User): boolean => {
      if (
        data.custName === data2.custName &&
        data.furigana === data2.furigana &&
        data.orgTypeAdd === data2.orgTypeAdd &&
        data.orgType === data2.orgType &&
        data.branchName === data2.branchName &&
        data.zip === data2.zip &&
        data.address1 === data2.address1 &&
        data.address2 === data2.address2 &&
        data.tel === data2.tel &&
        data.fax === data2.fax
      ) {
        return true;
      }

      return false;
    };

    if (accountInfo && accountInfo2) {
      let error = false;
      const message: string[] = [];
      if (check(accountInfo, accountInfo2)) {
        message.push('・内容が変更されていません。');
        error = true;
      }
      if (accountInfo.custName === '') {
        message.push(
          '・名称（企業：企業名、個人：氏名）が入力されていません。',
        );
        error = true;
      }
      if (accountInfo.furigana === '') {
        message.push('・フリガナが入力されていません。');
        error = true;
      }
      if (accountInfo.zip === '') {
        message.push('・郵便番号が入力されていません。');
        error = true;
      }
      if (accountInfo.zip !== '' && !postalCodeCheck.test(accountInfo.zip)) {
        message.push('・郵便番号は半角数字で7桁で入力してください。');
        error = true;
      }
      if (accountInfo.address1 === '') {
        message.push('・住所が入力されていません。');
        error = true;
      }
      if (accountInfo.tel === '') {
        message.push('・電話番号が入力されていません。');
        error = true;
      }
      if (accountInfo.tel !== '' && !telCheck.test(accountInfo.tel)) {
        message.push('・電話番号は半角数字と半角ハイフンで入力してください。');
        message.push('　例）083-941-6300');
        error = true;
      }
      if (accountInfo.fax !== '' && !telCheck.test(accountInfo.fax)) {
        message.push('・FAX番号は半角数字と半角ハイフンで入力してください。');
        message.push('　例）083-941-6400');
        error = true;
      }
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);
        scrollToTop();

        return;
      }
    }
    setDispMode('changeVerifi');
    setIsHide(true);
  };

  // 戻る（変更）
  const clickChangeBack = () => {
    setDispMode('change');
  };
  // 担当者追加ダイアログの表示
  const openStaffRegisDialog = () => {
    setAccountPicName('');
    setAccountPicMail('');
    setAccountPicTel('');
    setOpenTemp(true);
  };
  // 担当者追加ダイアログの表示
  const openAccountStaffRegisDialog = () => {
    const data = { ...accountInfo } as User;
    setAccountPicName(data.picName);
    setAccountPicMail(data.mail);
    setAccountPicTel(data.tel);
    setOpenTemp(true);
  };
  // 担当者追加ダイアログの非表示
  const closeStaffRegisDialog = (
    handleClickInsert: boolean,
    insertPicList?: PicList,
  ) => {
    setOpenTemp(false);
    if (handleClickInsert && insertPicList) {
      const data = [...picData];

      let id = 0;
      const ids = data.map((n) => n.picID ?? 0);
      for (let i = 0; i < ids.length; i += 1) {
        id = Math.max(ids[i], id);
      }
      const data2 = { ...insertPicList };
      data2.picID = id + 1;

      data.push(data2);
      setPicData(data);
    }
  };
  // 担当者削除ダイアログの表示
  const openStaffDeleteDialog = (row: PicList) => {
    setOpenDelete(true);
    setPicList(row);
  };
  // 担当者削除ダイアログの非表示
  const closeStaffDeleteDialog = (
    handleClickInsert: boolean,
    row?: PicList,
  ) => {
    setOpenDelete(false);
    if (handleClickInsert && row) {
      const data = [...picData];
      const x = data.filter((n) => n.picID !== row.picID);
      setPicData(x);
    }
  };
  // アカウント担当者変更
  const changeAcountStaff = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.picName = event.target.value;
    setAcountInfo(data);
  };
  // メールアドレス変更
  const changeMailAddress = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.mail = event.target.value;
    setAcountInfo(data);
  };
  // ログインID変更
  const changeLoginID = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.loginID = event.target.value;
    setAcountInfo(data);
  };
  // パスワード変更
  const changePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  // パスワード（確認）変更
  const changeConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };
  // 法人格変更（有無）
  const changeCheckRegal = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    const value = Number(event.target.value);
    data.orgTypeAdd = value;
    setAcountInfo(data);
  };
  // 法人格変更（内容）
  const changeSelectRegal = (event: ChangeEvent<{ value: unknown }>) => {
    const data = { ...accountInfo } as User;
    const value = Number(event.target.value);
    data.orgType = value;
    setAcountInfo(data);
  };
  // 名称変更
  const changeName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.custName = event.target.value;
    setAcountInfo(data);
  };
  // フリガナ
  const changeKana = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.furigana = event.target.value;
    setAcountInfo(data);
  };
  // 支店支所名変更
  const changeBranchName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.branchName = event.target.value;
    setAcountInfo(data);
  };
  // 郵便番号変更
  const changePostalCode = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.zip = event.target.value;
    setAcountInfo(data);
  };
  // 住所変更
  const changeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.address1 = event.target.value;
    setAcountInfo(data);
  };
  // 建物名等変更
  const changeBuildingName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.address2 = event.target.value;
    setAcountInfo(data);
  };
  // 電話番号変更
  const changeTel = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.tel = event.target.value;
    setAcountInfo(data);
  };
  // FAX番号変更
  const changeFax = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...accountInfo } as User;
    data.fax = event.target.value;
    setAcountInfo(data);
  };

  const update: AccountInfo = {
    idCust: appContext.state.LoginUser?.idCust,
    custData: accountInfo?.custData ?? '',
    custName: accountInfo?.custName ?? '',
    furigana: accountInfo?.furigana ?? '',
    orgTypeAdd: accountInfo?.orgTypeAdd ?? 0,
    orgType: accountInfo?.orgType ?? 0,
    branchName: accountInfo?.branchName ?? '',
    zip: accountInfo?.zip ?? '',
    address1: accountInfo?.address1 ?? '',
    address2: accountInfo?.address2 ?? '',
    tel: accountInfo?.tel ?? '',
    fax: accountInfo?.fax ?? '',
    isApproved: false,
    isDeleted: false,
  };

  // 更新処理
  const putData = async () => {
    try {
      const url = `${API_URL}api/v1/users/${
        appContext.state.LoginUser.idCust ?? '0'
      }/`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPut(
        url,
        update,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const data = res.data as boolean;
      if (data) {
        setIsHide(false);
        setMode('info');
        setIsSuccess(true);
        debugLog('基本情報の変更が完了しました。');
        setIsLoading(false);
        navigate(`${rootDir}finish`, {
          state: {
            title: '基本情報',
            message: ['基本情報の変更が完了しました。'],
            from: location,
          },
        });
      } else {
        setIsLoading(false);
      }
    } catch (ex) {
      setIsLoading(false);
      consoleLog(ex);
    }
  };

  // 更新ボタンクリック
  const clickUpdate = () => {
    setIsLoading(true);
    putData().finally(() => {
      /* Nothing to do. */
    });
  };

  const columns = [
    {
      title: '担当者名',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      align: 'center' as const,
    },
    {
      title: '所属',
      dataIndex: 'section',
      key: 'section',
      align: 'center' as const,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'mail',
      key: 'mail',
      align: 'center' as const,
    },
    {
      title: '電話番号',
      dataIndex: 'tel',
      key: 'tel',
      align: 'center' as const,
    },
    {
      title: '操作',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      render: (_value: boolean, row: PicList) => (
        <>
          <Button2
            type="primary"
            danger
            className={classes.space}
            onClick={() => {
              const data = { ...row } as PicList;
              openStaffDeleteDialog(data);
            }}
          >
            削除
          </Button2>
          <StaffDeleteDialog
            open={openDelete}
            onClickClose={closeStaffDeleteDialog}
            row={picList}
            staffDelete={false}
          />
        </>
      ),
      align: 'center' as const,
    },
  ];
  const columnsVerifi = [
    {
      title: '担当者名',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      align: 'center' as const,
    },
    {
      title: '所属',
      dataIndex: 'section',
      key: 'section',
      align: 'center' as const,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'mail',
      key: 'mail',
      align: 'center' as const,
    },
    {
      title: '電話番号',
      dataIndex: 'tel',
      key: 'tel',
      align: 'center' as const,
    },
  ];

  /* non eslint-disable  */
  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            className={dispMode !== 'register' ? classes.title : ''}
          >
            {dispMode === 'register' && 'アカウント登録'}
            {dispMode === 'registerVerifi' && 'アカウント登録（確認）'}
            {dispMode === 'change' && '基本情報'}
            {dispMode === 'changeVerifi' && '基本情報変更申込（確認）'}
            {dispMode === 'master' &&
              `基本情報（${accountInfo?.custFullName ?? ''}）`}
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode2} />
          <div className={classes.form} hidden={isSuccess || isLoading}>
            <div hidden={dispMode === 'change' || dispMode === 'changeVerifi'}>
              <AccountStaffInput
                title="アカウント担当者名"
                margin="dense"
                readonly={
                  dispMode === 'master' ||
                  dispMode === 'registerVerifi' ||
                  dispMode === 'changeVerifi'
                }
                value={accountInfo?.picName ?? ''}
                onChange={changeAcountStaff}
              />
              <CustomTextField
                variant="outlined"
                margin="dense"
                required
                id="mail"
                label="メールアドレス"
                name="mail"
                autoComplete="mail"
                className={classes.length}
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'register' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                value={accountInfo?.mail ?? ''}
                onChange={changeMailAddress}
              />
              <br />
              <LoginIDInput
                title="ログインID（8桁～12桁）"
                margin="dense"
                readonly={
                  dispMode === 'master' ||
                  dispMode === 'registerVerifi' ||
                  dispMode === 'changeVerifi'
                }
                value={accountInfo?.loginID ?? ''}
                onChange={changeLoginID}
              />
              <br />
            </div>
            <div
              hidden={dispMode !== 'register' && dispMode !== 'registerVerifi'}
            >
              <PasswordInput
                title="パスワード"
                readonly={
                  dispMode === 'master' ||
                  dispMode === 'registerVerifi' ||
                  dispMode === 'changeVerifi'
                }
                value={password}
                onChange={changePassword}
              />
              <span hidden={dispMode !== 'register'}>
                <CustomTextField
                  variant="outlined"
                  margin="dense"
                  required
                  name="confirmPassword"
                  label="パスワード（確認）"
                  type="password"
                  id="confirmPassword"
                  className={classes.length}
                  InputProps={{
                    readOnly:
                      dispMode === 'master' ||
                      dispMode === 'registerVerifi' ||
                      dispMode === 'changeVerifi',
                  }}
                  value={confirmPassword}
                  onChange={changeConfirmPassword}
                />
                <br />
              </span>
            </div>
            <Box hidden={!isMaster}>
              <Button
                variant="contained"
                color="primary"
                className={classes.masterSubmit}
                onClick={clickRegister}
              >
                登録
              </Button>
            </Box>
            <div hidden={isMaster}>
              <RegalPersonalityInput
                title="法人格"
                margin="dense"
                checkValue={accountInfo?.orgTypeAdd ?? 0}
                selectValue={accountInfo?.orgType ?? ''}
                readonly={
                  dispMode === 'master' ||
                  dispMode === 'registerVerifi' ||
                  dispMode === 'changeVerifi'
                }
                onChangeCheck={changeCheckRegal}
                onChangeSelect={changeSelectRegal}
              />
              <CustomTextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="custName"
                label="名称（企業：企業名、個人：氏名）"
                name="custName"
                autoComplete="custName"
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                value={accountInfo?.custName ?? ''}
                onChange={changeName}
              />
              <CustomTextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="furigana"
                label="フリガナ"
                name="furigana"
                autoComplete="furigana"
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                value={accountInfo?.furigana ?? ''}
                onChange={changeKana}
              />
              <CustomTextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="branchName"
                label="支店支所名"
                name="branchName"
                autoComplete="branchName"
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 20,
                  },
                }}
                value={accountInfo?.branchName ?? ''}
                onChange={changeBranchName}
              />
              <PostalCodeInput
                title="郵便番号"
                margin="dense"
                readonly={
                  dispMode === 'master' ||
                  dispMode === 'registerVerifi' ||
                  dispMode === 'changeVerifi'
                }
                value={accountInfo?.zip ?? ''}
                updateAddress={(values: string) => {
                  const data = { ...accountInfo } as User;
                  data.address1 = values;
                  setAcountInfo(data);
                }}
                onChange={changePostalCode}
              />
              <CustomTextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="address1"
                label="住所"
                name="address1"
                autoComplete="address1"
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 20,
                  },
                }}
                value={accountInfo?.address1 ?? ''}
                onChange={changeAddress}
              />
              <CustomTextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="address2"
                label="建物名等"
                name="address2"
                autoComplete="address2"
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 20,
                  },
                }}
                value={accountInfo?.address2 ?? ''}
                onChange={changeBuildingName}
              />
              <CustomTextField
                variant="outlined"
                margin="dense"
                required
                id="tel"
                label="電話番号(ﾊｲﾌﾝ有)"
                name="tel"
                autoComplete="tel"
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 20,
                  },
                }}
                value={accountInfo?.tel ?? ''}
                onChange={changeTel}
              />
              <br />
              <CustomTextField
                variant="outlined"
                margin="dense"
                id="fax"
                label="FAX番号(ﾊｲﾌﾝ有)"
                name="fax"
                autoComplete="fax"
                InputProps={{
                  readOnly:
                    dispMode === 'master' ||
                    dispMode === 'registerVerifi' ||
                    dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 20,
                  },
                }}
                value={accountInfo?.fax ?? ''}
                onChange={changeFax}
                className={classes.line}
              />
              <div
                hidden={
                  dispMode !== 'register' && dispMode !== 'registerVerifi'
                }
              >
                <Box className={classes.staffBlock}>
                  担当者
                  <MuiThemeProvider theme={theme3}>
                    <Tooltip title={breakedText}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.tooltip}
                      >
                        担当者とは
                      </Button>
                    </Tooltip>
                  </MuiThemeProvider>
                  <MuiThemeProvider theme={theme2}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={openStaffRegisDialog}
                      hidden={dispMode === 'registerVerifi'}
                    >
                      追加
                    </Button>
                  </MuiThemeProvider>
                  <MuiThemeProvider theme={theme4}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button2}
                      onClick={openAccountStaffRegisDialog}
                      hidden={dispMode === 'registerVerifi'}
                    >
                      アカウント担当者を担当者へ追加
                    </Button>
                  </MuiThemeProvider>
                  <StaffTempRegistrationDialog
                    open={openTemp}
                    onClickClose={closeStaffRegisDialog}
                    text=""
                    button="追加"
                    register={false}
                    accountName={accountPicName}
                    accountMail={accountPicMail}
                    accountTell={accountPicTel}
                  />
                </Box>
                <Table
                  dataSource={picData}
                  columns={
                    dispMode !== 'registerVerifi' ? columns : columnsVerifi
                  }
                  pagination={{ pageSize: 20 }}
                  rowKey="picID"
                  className={classes.line}
                />
              </div>
              <Box hidden={dispMode !== 'register'}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={clickRegister}
                >
                  確認
                </Button>
              </Box>
              <Box hidden={dispMode !== 'registerVerifi'}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={clickInsert}
                >
                  登録
                </Button>
                <MuiThemeProvider theme={theme1}>
                  <Button
                    variant="contained"
                    className={classes.backButton}
                    onClick={clickRegisterBack}
                  >
                    戻る
                  </Button>
                </MuiThemeProvider>
              </Box>
              <Box hidden={dispMode !== 'change'}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={clickChange}
                >
                  内容変更
                </Button>
              </Box>
              <Box hidden={dispMode !== 'changeVerifi'}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={clickUpdate}
                >
                  変更申込
                </Button>
                <MuiThemeProvider theme={theme1}>
                  <Button
                    variant="contained"
                    className={classes.backButton}
                    onClick={clickChangeBack}
                  >
                    戻る
                  </Button>
                </MuiThemeProvider>
              </Box>
            </div>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default AccountInfoPage;
