import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SearchUserConditions, UserListModel } from 'types/User';
import { API_URL } from 'utils/CommonConst';
import { RecieptType } from 'types/RecieptType';
import {
  consoleLog,
  debugLog,
  methodGet,
  methodPost,
} from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import CustomTextField from './CustomTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(2, 5, 5),
    },
    length: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: 500,
      margin: theme.spacing(2, 5, 0),
    },
  }),
);
/* not eslint-disable */
export type ClientSearchPanelProps = {
  setResult: Dispatch<SetStateAction<UserListModel[]>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setRecieptTypes: Dispatch<SetStateAction<RecieptType[]>>;
};

const ClientSearchPanel: FC<ClientSearchPanelProps> = ({
  setResult,
  setIsLoading,
  setRecieptTypes,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [custData, setCustDate] = useState('');
  const [custName, setCustName] = useState('');

  const changedCustDate = (event: ChangeEvent<HTMLInputElement>) => {
    setCustDate(event.target.value);
  };
  const changedCustName = (event: ChangeEvent<HTMLInputElement>) => {
    setCustName(event.target.value);
  };

  // 検索処理
  const userSearch = useCallback(
    (conditions: SearchUserConditions) => {
      // ここに依頼検索の処理を書く（サーバー側処理の呼び出し）

      const getData = async () => {
        try {
          const url = `${API_URL}api/v1/users/get`;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const res = await methodPost(
            url,
            conditions,
            true,
            appContext.state.LoginUser,
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = res.data as UserListModel[];
          if (data.length > 0) {
            setResult(data);
            // eslint-disable-next-line
            debugLog(data);
          } else {
            setResult(data);
            // eslint-disable-next-line
            debugLog(data);
          }
        } catch (ex) {
          consoleLog(ex);
        }
      };
      if (appContext.state.LoginUser.isLogin) {
        setIsLoading(true);
        getData().finally(() => {
          /* Nothing to do. */
          setIsLoading(false);
        });
      }
    },
    [appContext.state.LoginUser, setIsLoading, setResult],
  );

  useEffect(() => {
    // 検査区分マスタの取得・設定
    const getRecieptType = async () => {
      try {
        const url = `${API_URL}api/v1/masters/recieptttype`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as RecieptType[];
        setRecieptTypes(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    if (appContext.state.LoginUser.isLogin) {
      getRecieptType().finally(() => {
        /* Nothing to do. */
      });
      const conditions: SearchUserConditions = {
        custData,
        custName,
      };
      userSearch(conditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearch]);

  return (
    <>
      <div>
        <CustomTextField
          variant="outlined"
          margin="dense"
          id="custData"
          label="顧客番号"
          name="custData"
          autoComplete="custData"
          className={classes.length}
          value={custData}
          onChange={changedCustDate}
        />
        <br />
        <CustomTextField
          variant="outlined"
          margin="dense"
          id="custName"
          label="顧客名"
          name="custName"
          autoComplete="custName"
          className={classes.length}
          value={custName}
          onChange={changedCustName}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            const conditions: SearchUserConditions = {
              custData,
              custName,
            };

            userSearch(conditions);
          }}
        >
          検索
        </Button>
      </div>
    </>
  );
};

export default ClientSearchPanel;
