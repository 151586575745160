import { ChangeEvent, FC } from 'react';
import 'antd/dist/antd.css';
import './Panel.css';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles,
  Theme,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Request } from 'types/RequestDataInfo';
import { addressType } from 'utils/CommonConst';
import CustomTextField2 from './CustomTextField2';

const theme2 = createTheme({
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 1,
        marginBottom: 1,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    width: {
      width: 220,
    },
    length: {
      width: 500,
    },
    color: {
      color: 'red',
      alignItems: 'right',
    },
    titleSpace: {
      margin: theme.spacing(3, 0, 1),
    },
  }),
);

export type DestinationInputPanelProps = {
  type?: number;
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};

const DestinationInputPanel: FC<DestinationInputPanelProps> = ({
  type = 1,
  requestData,
  updateRequest,
}) => {
  const classes = useStyles();

  const text = '例） ¥n 新事業創造支援センター１２号室';
  const breakedText = text.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));
  // 宛名
  const changeSendName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    if (type === addressType.DestinationResult) {
      data.sendName = event.target.value;
    } else {
      data.chargeSendName = event.target.value;
    }
    if (updateRequest) updateRequest(data);
  };

  // 郵便番号
  const changeSendZipCode = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    if (type === addressType.DestinationResult) {
      data.sendZipCode = event.target.value;
    } else {
      data.chargeSendZipCode = event.target.value;
    }
    if (updateRequest) updateRequest(data);
  };

  // 住所
  const changeSendAddress1 = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    if (type === addressType.DestinationResult) {
      data.sendAddress1 = event.target.value;
    } else {
      data.chargeSendAddress1 = event.target.value;
    }
    if (updateRequest) updateRequest(data);
  };

  // 建物等
  const changeSendAddress2 = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    if (type === addressType.DestinationResult) {
      data.sendAddress2 = event.target.value;
    } else {
      data.chargeSendAddress2 = event.target.value;
    }
    if (updateRequest) updateRequest(data);
  };
  // 電話番号
  const changeSendTel = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    if (type === addressType.DestinationResult) {
      data.sendTel = event.target.value;
    } else {
      data.chargeSendPicTel = event.target.value;
    }
    if (updateRequest) updateRequest(data);
  };
  // FAX番号
  const changeSendFax = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    if (type === addressType.DestinationResult) {
      data.sendFax = event.target.value;
    } else {
      data.chargeSendPicFax = event.target.value;
    }
    if (updateRequest) updateRequest(data);
  };
  // 担当者
  const changeSendPic = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    if (type === addressType.DestinationResult) {
      data.sendPic = event.target.value;
    } else {
      data.chargeSendPic = event.target.value;
    }
    if (updateRequest) updateRequest(data);
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5" className={classes.titleSpace}>
        送付先
      </Typography>
      <MuiThemeProvider theme={theme2}>
        <table className="destination-input">
          <tbody>
            <tr>
              <th className={classes.width}>
                名称
                <span style={{ float: 'right' }} className={classes.color}>
                  (必須)
                </span>
              </th>
              <td className={classes.length}>
                <CustomTextField2
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="name"
                  name="name"
                  autoComplete="name"
                  value={
                    type === addressType.DestinationResult
                      ? requestData?.sendName
                      : requestData?.chargeSendName
                  }
                  onChange={changeSendName}
                  inputProps={{
                    maxLength:
                      type === addressType.DestinationInvoice ? 20 : 50,
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>
                郵便番号
                <span style={{ float: 'right' }} className={classes.color}>
                  (必須)
                </span>
              </th>
              <td>
                <CustomTextField2
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="postalCode"
                  name="postalCode"
                  autoComplete="postalCode"
                  value={
                    type === addressType.DestinationResult
                      ? requestData?.sendZipCode
                      : requestData?.chargeSendZipCode
                  }
                  inputProps={{ maxLength: 7 }}
                  onChange={changeSendZipCode}
                />
              </td>
            </tr>
            <tr>
              <th>
                住所
                <span style={{ float: 'right' }} className={classes.color}>
                  (必須)
                </span>
              </th>
              <td>
                <CustomTextField2
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="address1"
                  name="address1"
                  autoComplete="address1"
                  value={
                    type === addressType.DestinationResult
                      ? requestData?.sendAddress1
                      : requestData?.chargeSendAddress1
                  }
                  onChange={changeSendAddress1}
                  inputProps={{ maxLength: 20 }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Tooltip title={breakedText} placement="bottom">
                  <div>建物等</div>
                </Tooltip>
              </th>
              <td>
                <CustomTextField2
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="address2"
                  name="address2"
                  autoComplete="address2"
                  value={
                    type === addressType.DestinationResult
                      ? requestData?.sendAddress2
                      : requestData?.chargeSendAddress2
                  }
                  onChange={changeSendAddress2}
                  inputProps={{ maxLength: 20 }}
                />
              </td>
            </tr>
            <tr>
              <th>
                電話番号(ﾊｲﾌﾝ有)
                <span style={{ float: 'right' }} className={classes.color}>
                  (必須)
                </span>
              </th>
              <td>
                <CustomTextField2
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="tel"
                  name="tel"
                  autoComplete="tel"
                  value={
                    type === addressType.DestinationResult
                      ? requestData?.sendTel
                      : requestData?.chargeSendPicTel
                  }
                  onChange={changeSendTel}
                  inputProps={{ maxLength: 20 }}
                />
              </td>
            </tr>
            <tr>
              <th>FAX番号(ﾊｲﾌﾝ有)</th>
              <td>
                <CustomTextField2
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="fax"
                  name="fax"
                  autoComplete="fax"
                  value={
                    type === addressType.DestinationResult
                      ? requestData?.sendFax
                      : requestData?.chargeSendPicFax
                  }
                  onChange={changeSendFax}
                  inputProps={{ maxLength: 20 }}
                />
              </td>
            </tr>
            <tr>
              <th>
                担当者
                <span style={{ float: 'right' }} className={classes.color}>
                  (必須)
                </span>
              </th>
              <td>
                <CustomTextField2
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="pic"
                  name="pic"
                  autoComplete="pic"
                  value={
                    type === addressType.DestinationResult
                      ? requestData?.sendPic
                      : requestData?.chargeSendPic
                  }
                  onChange={changeSendPic}
                  inputProps={{ maxLength: 20 }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </MuiThemeProvider>
    </div>
  );
};

export default DestinationInputPanel;
