import {
  FC,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  useContext,
} from 'react';
import { Link as ScrollLink } from 'react-scroll';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles,
  Theme,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FileListPanel from 'components/molecules/FileListPanel';
import Container from '@material-ui/core/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URL, rootDir } from 'utils/CommonConst';
import { Request, UpdateProcess } from 'types/RequestDataInfo';
import { ProcessCategory } from 'types/ProcessCategory';
import { TextField } from '@material-ui/core';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import {
  consoleLog,
  debugLog,
  methodGet,
  methodPut,
} from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const theme1 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'red',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      alignItems: 'left',
      fontSize: '18px',
    },
    line: {
      marginTop: theme.spacing(5),
    },
    interval: {
      margin: theme.spacing(3, 0, 1),
    },
    control: {
      margin: theme.spacing(-0.2, 2, 0),
      maxWidth: 300,
    },
    update: {
      margin: theme.spacing(0, 1, 1),
    },
    statusDelete: {
      margin: theme.spacing(0, 1, 1, 3),
      '&:hover': {
        background: '#dc143c',
      },
    },
    width: {
      width: 100,
    },
    toTopDiv: {
      textAlign: 'right',
    },
    toTopButton: {
      width: 100,
      margin: theme.spacing(0, 0, 10),
    },
    recieptTitle: {
      width: 180,
    },
  }),
);

const FileUploadPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const location = useLocation();
  const { id } = (location.state as { id: number }) || {
    id: 0,
  };
  const { search } = useLocation();
  const query2 = new URLSearchParams(search);
  const queryid = appContext.state.LoginUser.isMaster
    ? query2.get('reciepid') ?? ''
    : '';
  const reciepid = id === 0 ? Number(queryid) : id;
  const [category, setCategory] = useState<number | ''>('');
  const [requestData, setRequestData] = useState<Request>();
  const [processCategories, setProcessCategories] = useState<ProcessCategory[]>(
    [],
  );
  const [updateDate, setUpdateDate] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [mode, setMode] = useState<Mode>('info');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (appContext.state.LoginUser.loginID !== '') {
      if (!appContext.state.LoginUser.isMaster) {
        // 管理者専用ページのため
        navigate(`${rootDir}top`);
      }
    }
  }, [appContext.state.LoginUser, navigate]);

  const handleChangeCategory = (event: ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as number);
  };

  const getRequestData = useCallback(async () => {
    if (reciepid !== 0 && appContext.state.LoginUser.isLogin) {
      try {
        const url = `${API_URL}api/v1/requests/${reciepid}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as Request;
        setRequestData(data);
        debugLog(data);
      } catch (ex) {
        consoleLog(ex);
      }
    }
  }, [reciepid, appContext.state.LoginUser]);

  const getProcessCategory = useCallback(async () => {
    try {
      const url = `${API_URL}api/v1/masters/processcategory`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodGet(url, false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const data = res.data as ProcessCategory[];
      setProcessCategories(data);
    } catch (ex) {
      consoleLog(ex);
    }
  }, []);

  useEffect(() => {
    getRequestData().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
    getProcessCategory().finally(() => {
      /* Nothing to do. */
    });
  }, [getRequestData, getProcessCategory]);

  const changeUpdateDate = (event: ChangeEvent<HTMLInputElement>) =>
    setUpdateDate(event.target.value);

  const updateData = async () => {
    try {
      if (requestData) {
        const putData: UpdateProcess = {
          idCustData: requestData?.idCust,
          id: requestData?.id,
          code: category === '' ? 1 : category,
          processDate: updateDate,
        };
        const url = `${API_URL}api/v1/requests/process`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodPut(
          url,
          putData,
          true,
          appContext.state.LoginUser,
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        if (data) {
          setMode('info');
          setIsHide(false);
          setErrorMessages(['状況を更新しました。']);
          setIsLoading(true);
          getRequestData().finally(() => {
            /* Nothing to do. */
            setIsLoading(false);
          });
        } else {
          setMode('error');
          setIsHide(false);
          setErrorMessages(['状況の更新に失敗しました。']);
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const deleteStatus = async () => {
    try {
      if (requestData) {
        const putData: UpdateProcess = {
          idCustData: requestData?.idCust,
          id: requestData?.id,
          code: category === '' ? 1 : category,
          processDate: updateDate,
        };
        const url = `${API_URL}api/v1/requests/process/delete`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodPut(
          url,
          putData,
          true,
          appContext.state.LoginUser,
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        if (data) {
          setMode('info');
          setIsHide(false);
          setErrorMessages(['状況を更新しました。']);
          setIsLoading(true);
          getRequestData().finally(() => {
            /* Nothing to do. */
            setIsLoading(false);
          });
        } else {
          setMode('error');
          setIsHide(false);
          setErrorMessages(['状況の更新に失敗しました。']);
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  // 更新ボタン
  const confirmation = () => {
    // 入力チェック
    if (requestData && appContext.state.LoginUser.isLogin) {
      setMode('error');
      // 基本情報
      if (category === '') {
        setIsHide(false);
        setErrorMessages(['状況を選択してください。']);

        return;
      }
      if (updateDate === '') {
        setIsHide(false);
        setErrorMessages(['更新日を入力してください。']);

        return;
      }

      setIsHide(true);
      updateData().finally(() => {
        /* Nothing to do. */
      });
    }
  };

  // 状況取消ボタン
  const statusDelete = () => {
    // 入力チェック
    if (requestData && appContext.state.LoginUser.isLogin) {
      setMode('error');
      // 基本情報
      if (category === '') {
        setIsHide(false);
        setErrorMessages(['状況を選択してください。']);

        return;
      }
      // eslint-disable-next-line
      if (category == 1) {
        setIsHide(false);
        setErrorMessages(['依頼申込は取り消せません。']);

        return;
      }
      // eslint-disable-next-line
      const test = requestData.processList.find((n) => n.code == category);
      if (!test) {
        setIsHide(false);
        setErrorMessages(['状況が登録されていません。']);

        return;
      }

      setIsHide(true);
      deleteStatus().finally(() => {
        /* Nothing to do. */
      });
    }
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="lg">
        <div className={classes.paper} hidden={id === 0 && queryid === ''}>
          <Typography component="h1" variant="h4">
            ファイルアップロード
          </Typography>
          <div hidden={isLoading} className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.interval}
            >
              依頼内容
            </Typography>
            <table className="html-table">
              <tbody>
                <tr>
                  <th className={classes.recieptTitle}>顧客番号</th>
                  <td>{requestData?.custData}</td>
                </tr>
                <tr>
                  <th>顧客名</th>
                  <td>{requestData?.custName}</td>
                </tr>
                <tr>
                  <th>依頼番号</th>
                  <td>{requestData?.dspIdReciept}</td>
                </tr>
                <tr>
                  <th>担当者：依頼</th>
                  <td>
                    {requestData?.picList?.map((pic, index) => {
                      if (index === 0) {
                        return pic.name;
                      }

                      return `、${pic.name}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <th>担当者：結果書</th>
                  <td>
                    {requestData?.sendPICList?.map((pic, index) => {
                      if (index === 0) {
                        return pic.name;
                      }

                      return `、${pic.name}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <th>担当者：請求書</th>
                  <td>
                    {requestData?.chargePICList?.map((pic, index) => {
                      if (index === 0) {
                        return pic.name;
                      }

                      return `、${pic.name}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <th>依頼日</th>
                  <td>{requestData?.rcptCreate}</td>
                </tr>
                <tr>
                  <th>試験品名</th>
                  <td>
                    {requestData?.taskList?.map((sample, index) => {
                      if (sample.isJapanese) {
                        return (
                          <span key={sample.nameSampleIndex}>
                            {index !== 0 && '、'}
                            {sample.nameSampleIndex}
                          </span>
                        );
                      }
                      if (sample.isEnglish) {
                        return (
                          <span key={sample.nameSampleIndexEn}>
                            {index !== 0 && '、'}
                            {sample.nameSampleIndexEn}
                          </span>
                        );
                      }

                      return '';
                    })}
                  </td>
                </tr>
                <tr>
                  <th>検査項目</th>
                  <td>
                    {requestData?.mesItemList?.map((mesItem, index) => (
                      <span key={mesItem.name}>
                        {index !== 0 && '、'}
                        {mesItem.name}
                      </span>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={classes.line} />
            <MessagePanel
              messages={errorMessages}
              isHide={isHide}
              mode={mode}
            />

            <Typography
              component="h1"
              variant="h5"
              className={classes.interval}
            >
              状況
              <FormControl
                variant="outlined"
                margin="dense"
                fullWidth
                className={classes.control}
              >
                <InputLabel>検査区分</InputLabel>
                <Select
                  value={`${category}`}
                  onChange={handleChangeCategory}
                  label="検査区分"
                >
                  {processCategories.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.code === 0 ? '' : `${item.code}`}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                id="updateDate"
                label="更新日"
                name="updateDate"
                type="date"
                margin="dense"
                className={classes.control}
                value={updateDate}
                onChange={changeUpdateDate}
                InputLabelProps={{ shrink: true }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.update}
                onClick={confirmation}
              >
                更新
              </Button>
              <MuiThemeProvider theme={theme1}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.statusDelete}
                  onClick={statusDelete}
                >
                  状況取消
                </Button>
              </MuiThemeProvider>
            </Typography>
            <div className="status-table">
              <table>
                <tbody>
                  <tr>
                    {requestData?.sampleList.length === 1 &&
                      processCategories
                        .filter((n) => n.code !== 0 && n.code !== 9)
                        .map((item) => (
                          <th key={item.name} className={classes.width}>
                            {item.name}
                          </th>
                        ))}
                    {requestData?.sampleList &&
                      requestData?.sampleList.length > 1 &&
                      processCategories
                        .filter((n) => n.code !== 0)
                        .map((item) => (
                          <th key={item.name} className={classes.width}>
                            {item.name}
                          </th>
                        ))}
                  </tr>
                  <tr>
                    {requestData?.sampleList.length === 1 &&
                      processCategories
                        .filter((n) => n.code !== 0 && n.code !== 9)
                        .map((item) => (
                          <td key={item.name}>
                            {requestData &&
                              requestData.processList?.find(
                                (n) => n.code === item.code,
                              )?.processDate}
                          </td>
                        ))}
                    {requestData?.sampleList &&
                      requestData?.sampleList.length > 1 &&
                      processCategories
                        .filter((n) => n.code !== 0)
                        .map((item) => (
                          <td key={item.name}>
                            {requestData &&
                              requestData.processList?.find(
                                (n) => n.code === item.code,
                              )?.processDate}
                          </td>
                        ))}
                  </tr>
                </tbody>
              </table>
              <div className={classes.line} />
              <FileListPanel
                title="速報"
                fileType={3}
                requestData={requestData}
                updateRequest={(values: Request) => {
                  setRequestData(values);
                }}
                dataLoad={() => {
                  setIsLoading(true);
                  getRequestData().finally(() => {
                    /* Nothing to do. */
                    setIsLoading(false);
                  });
                }}
              />
              <div className={classes.line} />
              <FileListPanel
                title="結果書"
                fileType={4}
                requestData={requestData}
                updateRequest={(values: Request) => {
                  setRequestData(values);
                }}
                dataLoad={() => {
                  setIsLoading(true);
                  getRequestData().finally(() => {
                    /* Nothing to do. */
                    setIsLoading(false);
                  });
                }}
              />
              <div className={classes.line} />
              <FileListPanel
                title="御見積書等"
                fileType={5}
                requestData={requestData}
                updateRequest={(values: Request) => {
                  setRequestData(values);
                }}
                dataLoad={() => {
                  setIsLoading(true);
                  getRequestData().finally(() => {
                    /* Nothing to do. */
                    setIsLoading(false);
                  });
                }}
              />
              <div className={classes.line} />
              <FileListPanel
                title="請求書"
                fileType={6}
                requestData={requestData}
                updateRequest={(values: Request) => {
                  setRequestData(values);
                }}
                dataLoad={() => {
                  setIsLoading(true);
                  getRequestData().finally(() => {
                    /* Nothing to do. */
                    setIsLoading(false);
                  });
                }}
              />
              <div className={classes.line} />
            </div>
            <div className={classes.toTopDiv}>
              <ScrollLink
                to="TopPage"
                smooth
                duration={250}
                containerId="HeaderContent"
                offset={-150}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.toTopButton}
                >
                  トップへ
                </Button>
              </ScrollLink>
            </div>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default FileUploadPage;
