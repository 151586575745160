import { Auth } from 'aws-amplify';
import axios from 'axios';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LoginUser } from 'types/User';
import jwtDecode from 'jwt-decode';
import { FilesKey, FileInfo } from '../types/Files';
import { API_URL, debugMode } from './CommonConst';
import AUTH_USER_TOKEN_KEY from './constants';

// デバッグログ出力
// eslint-disable-next-line
export const debugLog = (logContent: any) => {
  // eslint-disable-next-line
  if (debugMode) console.log(logContent);
};

// エラーログ出力
// eslint-disable-next-line
export const consoleLog = (logContent: any) => {
  // eslint-disable-next-line
  console.error(logContent);
};

//-----------------------------------------------------------------------------------------------------------
// unmount されたコンポーネントで setState を実行するとメモリリークのエラーが表示される件の対応
// （結局使用せず。）
export const useUnmountRef = (): MutableRefObject<boolean> => {
  const unmountRef = useRef(false);

  useEffect(
    () => () => {
      unmountRef.current = true;
    },
    [],
  );

  return unmountRef;
};
/* eslint-disable */
export const useSafeState = (
  unmountRef: MutableRefObject<boolean>,
  defaultValue: any,
): any => {
  const [state, changeState] = useState(defaultValue);
  const wrapChangeState = useCallback(
    (v: any) => {
      if (!unmountRef.current) {
        changeState(v);
      }
    },
    [changeState, unmountRef],
  );

  return [state, wrapChangeState];
};
//-----------------------------------------------------------------------------------------------------------
/* eslint-eable */

/**
 * ON/OFF切替フック
 * @param initialState
 * @returns
 */
export const useToggle = (
  initialState?: boolean,
): [boolean | undefined, () => void] => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => {
    setState((b) => !b);
  }, []);

  return [state, toggle];
};

// GETメソッド
export const methodGet = async (
  url: string,
  isAuthHeaders: boolean,
  loginUser?: LoginUser,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const headers = isAuthHeaders
      ? {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          userName: loginUser?.loginID,
          userID: loginUser?.isMaster
            ? loginUser?.idMaster ?? 0
            : loginUser?.idCust ?? 0,
          isMaster: loginUser?.isMaster,
        }
      : {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        };

    const response = await axios.get(url, {
      headers,
      timeout: 15000,
    });

    return response;
  } catch (error) {
    consoleLog('Error!');

    return null;
  }
};

// POSTメソッド
export const methodPost = async (
  url: string,
  // eslint-disable-next-line
  data: any,
  isAuthHeaders: boolean,
  loginUser?: LoginUser,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const headers = isAuthHeaders
      ? {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          userName: loginUser?.loginID,
          userID: loginUser?.isMaster
            ? loginUser?.idMaster ?? 0
            : loginUser?.idCust ?? 0,
          isMaster: loginUser?.isMaster,
        }
      : {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        };

    const response = await axios.post(url, data, {
      headers,
      timeout: 30000,
    });

    return response;
  } catch (error) {
    consoleLog('Error!');

    return null;
  }
};

// PUTメソッド
export const methodPut = async (
  url: string,
  // eslint-disable-next-line
  data: any,
  isAuthHeaders: boolean,
  loginUser?: LoginUser,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const headers = isAuthHeaders
      ? {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          userName: loginUser?.loginID,
          userID: loginUser?.isMaster
            ? loginUser?.idMaster ?? 0
            : loginUser?.idCust ?? 0,
          isMaster: loginUser?.isMaster,
        }
      : {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        };

    const response = await axios.put(url, data, {
      headers,
      timeout: 15000,
    });

    return response;
  } catch (error) {
    consoleLog('Error!');

    return null;
  }
};

// DELETEメソッド
export const methodDelete = async (
  url: string,
  isAuthHeaders: boolean,
  loginUser?: LoginUser,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const headers = isAuthHeaders
      ? {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          userName: loginUser?.loginID,
          userID: loginUser?.isMaster
            ? loginUser?.idMaster ?? 0
            : loginUser?.idCust ?? 0,
          isMaster: loginUser?.isMaster,
        }
      : {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        };

    const response = await axios.delete(url, {
      headers,
      timeout: 15000,
    });

    return response;
  } catch (error) {
    consoleLog('Error!');

    return null;
  }
};

/**
 * 指定バイナリデータを指定ファイル名でダウンロード
 */
export const downloadFile = (fileData: Blob, fileName: string): void => {
  // IE 以外の場合
  const fileUrl = window.URL.createObjectURL(fileData);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.click();

  // eslint-disable-next-line no-alert
  // alert('完了しました。');
};

/**
 * ファイルをダウンロード
 */
export const downloadData = async (
  key: FilesKey[],
  fileName: string,
  loginUser: LoginUser,
): Promise<void> => {
  const url = `${API_URL}api/v1/files`;
  const sendData = key;

  try {
    const response = await axios.post(url, sendData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'AWQS',
        userName: loginUser.loginID,
        userID: loginUser.isMaster
          ? loginUser.idMaster ?? 0
          : loginUser.idCust ?? 0,
        isMaster: loginUser.isMaster,
      },
      responseType: 'blob',
    });

    let fileName2 = fileName;
    if (
      fileName2 === '添付' ||
      fileName2 === '関連文書' ||
      fileName2 === '一括'
    ) {
      // eslint-disable-next-line
      const fileName3: string = response.headers[
        'content-disposition'
      ].substring(20) as string;
      debugLog(fileName3.substring(fileName3.lastIndexOf('.')));

      const hExtension: string = fileName3.substring(
        fileName3.lastIndexOf('.'),
      );
      let extension: string;
      if (hExtension === '.zip') {
        extension = '.zip';
      } else {
        extension = hExtension;
      }

      const date = new Date();
      const yymmdd =
        date.getFullYear().toString().slice(-2) +
        `0${date.getMonth() + 1}`.slice(-2) +
        `0${date.getDate()}`.slice(-2);
      if (fileName2 === '添付') {
        fileName2 = `添付ファイル_${yymmdd}${extension}`;
      }
      if (fileName2 === '関連文書') {
        fileName2 = `関連文書_${yymmdd}${extension}`;
      }
      if (fileName2 === '一括') {
        fileName2 = `一括_${yymmdd}${extension}`;
      }
    }
    downloadFile(response.data, fileName2);
  } catch (error) {
    consoleLog('Error!');
  }
};

/**
 * 依頼書を出力
 */
export const createRequestForm = async (
  id: number,
  loginUser: LoginUser,
): Promise<void> => {
  const url = `${API_URL}api/v1/requests/form/${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        userName: loginUser.loginID,
        userID: loginUser.isMaster
          ? loginUser.idMaster ?? 0
          : loginUser.idCust ?? 0,
        isMaster: loginUser.isMaster,
      },
      responseType: 'blob',
    });

    const date = new Date();
    const yymmdd =
      date.getFullYear().toString().slice(-2) +
      `0${date.getMonth() + 1}`.slice(-2) +
      `0${date.getDate()}`.slice(-2);
    const extension = '.pdf';
    // const extension = '.xlsx';
    const fileName2 = `依頼書_${yymmdd}${extension}`;

    downloadFile(response.data, fileName2);
  } catch (error) {
    consoleLog('Error!');
  }
};
/**
 * ファイル情報を取得
 */
export const findFile = async (
  key: FilesKey[],
  loginUser: LoginUser,
): Promise<FileInfo[]> => {
  const url = `${API_URL}api/v1/files/getinfo`;
  const sendData = key;

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await methodPost(url, sendData, true, loginUser);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.data as FileInfo[];
  } catch (error) {
    consoleLog('Error!');
  }

  return [];
};

/**
 * ファイル削除
 */
export const deleteFile = async (
  key: FilesKey[],
  loginUser: LoginUser,
): Promise<boolean> => {
  const url = `${API_URL}api/v1/files/delete`;
  const sendData = key;

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await methodPost(url, sendData, true, loginUser);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.data as boolean;
  } catch (error) {
    consoleLog('Error!');
  }

  return false;
};

/**
 * ファイル公開
 */
export const releaseFile = async (
  key: FilesKey[],
  loginUser: LoginUser,
): Promise<boolean> => {
  const url = `${API_URL}api/v1/files/release`;
  const sendData = key;

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await methodPut(url, sendData, true, loginUser);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.data as boolean;
  } catch (error) {
    consoleLog('Error!');
  }

  return false;
};

// ログインIDの重複チェック
export const loginIDCheckFunc = async (
  value: string,
): Promise<boolean | null> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/loginidcheck`,
      `"${value}"`,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return null;
  }
};

// メールアドレスの重複チェック(Cognito)
export const mailCheckFunc = async (value: string): Promise<boolean | null> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/mailcheck`,
      `"${value}"`,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return null;
  }
};

// メールアドレスの重複チェック(DB)
export const mailCheckFunc2 = async (
  value: string,
): Promise<boolean | null> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/mailcheck2`,
      `"${value}"`,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return null;
  }
};

// リクエストIDのチェック
export const requestCheckFunc = async (
  requestID: string,
): Promise<boolean | null> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/requestcheck`,
      requestID,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return null;
  }
};

// リクエストの処理
export const requestProcessFunc = async (
  requestID: string,
  username: string,
): Promise<boolean | null> => {
  try {
    const data = { requestID, username };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/requestprocess`,
      data,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return null;
  }
};

// 管理者チェック
export const masterCheckFunc = async (mail: string): Promise<boolean> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/mastercheck`,
      mail,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return false;
  }
};

// リクエストの削除
export const requestDeleteFunc = async (
  requestID: string,
): Promise<boolean | null> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/requestdelete`,
      requestID,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return null;
  }
};

// ログインチェック
export const loginCheckFunc = async (loginID: string): Promise<number> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/logincheck`,
      `"${loginID}"`,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as number;
  } catch (ex) {
    consoleLog(ex);

    return -2;
  }
};

// ログイン失敗回数の更新
export const loginLockCheckFunc = async (
  loginID: string,
  loginFailuresCount: number,
  password: string,
): Promise<boolean | null> => {
  try {
    const postData = {
      loginID,
      loginFailuresCount,
      password,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodPost(
      `${API_URL}api/v1/users/loginlockcheck`,
      postData,
      false,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return null;
  }
};

// IPのチェック
export const ipCheckFunc = async (): Promise<boolean> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = await methodGet(`${API_URL}api/v1/users/ipcheck`, false);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return res.data as boolean;
  } catch (ex) {
    consoleLog(ex);

    return false;
  }
};

// パスワードのチェック（OK：True、NG：false）
export const checkPassword = (password: string, loginID: string): boolean => {
  if (loginID !== '' && password.indexOf(loginID) >= 0) {
    // alert('ログインIDが含まれています。');

    return false;
  }

  if (password.length < 8) {
    // alert('8文字以上になっていません。');

    return false;
  }

  const rall = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/;
  if (!rall.test(password)) {
    // alert('小文字・大文字・数字が含まれていません。');

    return false;
  }

  // 特殊文字の必須化は解除
  // if (!password.match(/\W/g)) {
  //   // alert('特殊文字が含まれていません。');

  //   return false;
  // }

  return true;
};

// Cognito ユーザーの username を取得する
/* eslint-disable */
export const getUsername = async (): Promise<string> => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    return currentUser.username as string;
  } catch (err: any) {
    return '';
  }
};

export const getCurrentUser = async (): Promise<LoginUser> => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    localStorage.setItem(
      AUTH_USER_TOKEN_KEY,
      currentUser.signInUserSession.accessToken.jwtToken,
    );
    debugLog(currentUser);
    const reloginUser: LoginUser = {
      isLogin: true,
      idCust: currentUser.attributes['custom:IdCust'],
      idMaster: currentUser.attributes['custom:IdMaster'],
      isMaster: currentUser.attributes['custom:authority'] === 'master',
      loginID: currentUser.attributes.preferred_username,
    };

    return reloginUser;
  } catch (err: any) {
    const reloginUser: LoginUser = {
      isLogin: false,
      idCust: 0,
      idMaster: 0,
      isMaster: false,
      loginID: '',
    };

    return reloginUser;
  }
};

export const checkUpdateToken = async (): Promise<boolean> => {
  try {
    let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    if (!token) {
      return false;
    }
    const decodedJwt: any = jwtDecode(token);
    debugLog(decodedJwt);

    // let flg = decodedJwt.exp >= Date.now() / 1000;
    // let flg = decodedJwt.exp >= 1681884684;
    // if (!flg) {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      // const currentSession = await Auth.currentSession();
      const newSession = await currentUser.refreshSession(
        currentUser.signInUserSession.refreshToken,
        (_err: any, session: any) => {
          if (session.isValid()) {
            localStorage.setItem(
              AUTH_USER_TOKEN_KEY,
              session.accessToken.jwtToken,
            );
          }
        },
      );

      // localStorage.setItem(
      //   AUTH_USER_TOKEN_KEY,
      //   currentUser.signInUserSession.accessToken.jwtToken,
      // );
      // debugLog(currentUser);
      // const reloginUser: LoginUser = {
      //   isLogin: true,
      //   idCust: currentUser.attributes['custom:IdCust'],
      //   idMaster: currentUser.attributes['custom:IdMaster'],
      //   isMaster: currentUser.attributes['custom:authority'] === 'master',
      //   loginID: currentUser.attributes.preferred_username,
      // };

      return true;
    } else {
      return false;
    }
    // }

    return true;
  } catch (err: any) {
    return false;
  }
};
/* eslint-enable */

export const signOut = async (): Promise<boolean> => {
  try {
    await Auth.signOut();
    debugLog('finish signout');
    localStorage.clear();

    return true;
  } catch (error) {
    consoleLog('no user signing in');

    return false;
  }
};

export const checkDate = (strDate: string): boolean => {
  if (!/^\d{4}\/\d{2}\/\d{2}$/.exec(strDate)) {
    return false;
  }

  const y = Number(strDate.split('/')[0]);
  const m = Number(strDate.split('/')[1]) - 1;
  const d = Number(strDate.split('/')[2]);
  const date = new Date(y, m, d);

  if (
    date.getFullYear() !== y ||
    date.getMonth() !== m ||
    date.getDate() !== d
  ) {
    return false;
  }

  return true;
};

export const checkDate2 = (startDate: string, endDate: string): boolean => {
  const y = Number(startDate.split('/')[0]);
  const m = Number(startDate.split('/')[1]) - 1;
  const d = Number(startDate.split('/')[2]);
  const date = new Date(y, m, d);

  const y2 = Number(endDate.split('/')[0]);
  const m2 = Number(endDate.split('/')[1]) - 1;
  const d2 = Number(endDate.split('/')[2]);
  const date2 = new Date(y2, m2, d2);

  if (date > date2) {
    return false;
  }

  return true;
};

// 日付チェック（入力した日付が過去なら false,）
export const checkDate3 = (targetDate: string): boolean => {
  const y = Number(targetDate.split('/')[0]);
  const m = Number(targetDate.split('/')[1]) - 1;
  const d = Number(targetDate.split('/')[2]);
  const date = new Date(y, m, d);

  const date2 = new Date();
  const date3 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
  );

  if (date < date3) {
    return false;
  }

  return true;
};

export const nonNullable = <T>(value: T): value is NonNullable<T> =>
  value != null;

export default downloadFile;
