import { FC, useState, ChangeEvent, useContext } from 'react';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import PasswordInput from 'components/molecules/PasswordInput';
import { PasswordChange } from 'types/User';
import {
  checkPassword,
  consoleLog,
  debugLog,
  methodPost,
} from 'utils/CommonFunctions';
import { API_URL, rootDir } from 'utils/CommonConst';
import { useNavigate } from 'react-router';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

const PasswordChangePage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>('info');
  const [password, setPassword] = useState('');
  const [passwordOld, setPasswordOld] = useState('');
  const [passwordVeri, setPasswordVeri] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onChangePassword = (value: ChangeEvent<HTMLInputElement>) => {
    setPassword(value.target.value);
  };
  const onChangePasswordOld = (value: ChangeEvent<HTMLInputElement>) => {
    setPasswordOld(value.target.value);
  };
  const onChangePasswordVeri = (value: ChangeEvent<HTMLInputElement>) => {
    setPasswordVeri(value.target.value);
  };

  const changePassword = async () => {
    if (!password || !passwordOld || !passwordVeri) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['パスワードが入力されていません。']);
      debugLog('パスワードが入力されていません。');

      return;
    }
    if (password !== passwordVeri) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['新しいパスワード(確認)が一致しません。']);
      debugLog('新しいパスワード(確認)が一致しません');

      return;
    }

    if (!checkPassword(password, appContext.state.LoginUser.loginID)) {
      setIsHide(false);
      setMode('error');
      setErrorMessages([
        '新しいパスワードがパスワードポリシーを満たしていません。',
      ]);

      return;
    }

    try {
      setIsLoading(true);
      // eslint-disable-next-line
      const user = await Auth.currentAuthenticatedUser();

      try {
        const url = `${API_URL}api/v1/users/passwordchange`;
        /* eslint-disable */
        const data: PasswordChange = {
          userName: user.username,
          token: user.signInUserSession.accessToken.jwtToken,
          oldPassword: passwordOld,
          newPassword: password,
        };
        /* eslint-enable */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodPost(url, data, false);
        setIsHide(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (res.data) {
          setMode('info');
          setIsSuccess(true);
          setErrorMessages(['パスワード変更が完了しました。']);
          debugLog('パスワード変更が完了しました。');
        } else {
          setMode('error');
          setErrorMessages(['予期せぬエラーが発生しました。']);
          debugLog(res);
        }

        /* eslint-disable  */
      } catch (err: any) {
        setMode('error');
        consoleLog(err);
        switch (err.code) {
          case 'NotAuthorizedException':
            // 現在のパスワードが間違っている場合に起こる
            setIsHide(false);
            setErrorMessages(['現在のパスワードが間違っています。']);
            debugLog('現在のパスワードが間違っています');
            debugLog('NotAuthorizedException');
            break;
          case 'InvalidPasswordException':
            // 新しいパスワードがパスワードポリシーを満たしていない場合に起こる
            setIsHide(false);
            setErrorMessages([
              '新しいパスワードがパスワードポリシーを満たしていません。',
            ]);
            debugLog('新しいパスワードがパスワードポリシーを満たしていません');
            debugLog('InvalidPasswordException');
            break;
          default:
            // その他のエラー
            setIsHide(false);
            setErrorMessages(['予期しないエラーが発生しました。']);
            consoleLog('予期しないエラーが発生しました');
            consoleLog(err);
            consoleLog('Unknowned error');
            break;
        }
      }
      /* eslint-enable */
      // eslint-disable-next-line
    } catch (err: any) {
      setIsHide(false);
      setMode('error');
      setErrorMessages([err]);
      consoleLog(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            パスワード変更
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="currentPassword"
              label="現在のパスワード"
              type="password"
              id="currentPassword"
              value={passwordOld}
              onChange={onChangePasswordOld}
            />

            <PasswordInput
              title="新しいパスワード"
              onChange={onChangePassword}
            />
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="confirmNewPassword"
              label="新しいパスワード(確認)"
              type="password"
              id="confirmNewPassword"
              value={passwordVeri}
              onChange={onChangePasswordVeri}
            />
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={changePassword}
              >
                変更
              </Button>
            </Box>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            hidden={!isSuccess}
            onClick={() => {
              navigate(`${rootDir}requestsearch`);
            }}
          >
            依頼検索へ戻る
          </Button>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default PasswordChangePage;
