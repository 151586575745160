import { FC, useEffect } from 'react';
import { Table } from 'antd';
// import { DragOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import './DraggableTable.css';
import { Request, Task } from 'types/RequestDataInfo';

export type DraggableTableProps = {
  /* eslint-disable */
  columns: any;
  requestData?: Request;
  updateRequest?: any;
  rowkey: string;
};

const getIndexInParent = (el: any) =>
  Array.from(el.parentNode.children).indexOf(el);

const DraggableTable: FC<DraggableTableProps> = ({
  columns,
  requestData,
  updateRequest,
  rowkey,
}) => {
  const handleReorder = (dragIndex: number, draggedIndex: number) => {
    if (updateRequest) {
      updateRequest((oldState: Request) => {
        const newState = { ...oldState } as Request;
        const task = [...oldState.taskList] as Task[];
        newState.taskList = task;
        const item = task.splice(dragIndex, 1)[0];
        task.splice(draggedIndex, 0, item);
        for (let index = 0; index < task.length; index += 1) {
          task[index].sortNo = index + 1;
        }

        return newState;
      });
    }
  };

  useEffect(() => {
    let start: number;
    let end: number;
    const container = document.querySelector('.ant-table-tbody');
    if (container) {
      const drake = dragula([container], {
        moves: (el) => {
          start = getIndexInParent(el);

          return true;
        },
      });

      drake.on('drop', (el) => {
        end = getIndexInParent(el);
        handleReorder(start, end);
      });
    }
  }, []);

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={requestData?.taskList}
      rowKey={rowkey}
    />
  );
};
export default DraggableTable;
