import { ChangeEvent, FC, useContext, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { FilesKey, UploadFile } from 'types/Files';
import { consoleLog, findFile, methodPut } from 'utils/CommonFunctions';
import { API_URL } from 'utils/CommonConst';
import { Box } from '@material-ui/core';
import { AppContext } from 'utils/AppContext';
import TextField from '@material-ui/core/TextField';
import MessagePanel from './MessagePanel';

/* non eslint-disable */
const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: '#1769aa',
      },
    },
    length: {
      margin: theme.spacing(2, 0, 0),
      minWidth: 200,
    },
    uploadFile: {
      minWidth: 430,
      margin: theme.spacing(3, 0, 4),
    },
    space: {
      margin: theme.spacing(3, 3, 0),
    },
    fileInput: {
      display: 'none',
    },
    dialogTitle: {
      fontSize: 24,
    },
  }),
);

export type InvoiceUploadDialogProps = {
  open: boolean;
  onClickClose: (handleClickInsert: boolean) => void;
  idCust: number;
  name: string;
};

const InvoiceUploadDialog: FC<InvoiceUploadDialogProps> = ({
  open,
  onClickClose,
  idCust,
  name,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const refInput = useRef<HTMLInputElement>(null);
  const [commDlg, setCommDlg] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [file, setFile] = useState<UploadFile>();
  const [fileName, setFileName] = useState<string>('');
  const [billingDate, setBillingDate] = useState<string>('');

  const addClick = () => {
    refInput?.current?.click();
  };

  const dialogClose = (isAdd: boolean) => {
    setIsHide(true);
    setErrorMessages(['']);
    onClickClose(isAdd);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileClick = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    e.target.value = '';
  };

  const billingDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBillingDate(e.target.value);
  };

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      // ファイル名の文字数50文字以内
      if (e.target.files[0].name.length <= 50) {
        setFile({
          idCust,
          id: null,
          fileType: 2,
          no: null,
          name: e.target.files[0]?.name,
          path: '',
          userID: appContext.state.LoginUser.idMaster ?? 0,
          uploadDate: '',
          downloadCount: 0,
          billingDate,
          isEnable: false,
          formFile: e.target.files[0],
        });
        setFileName(e.target.files[0]?.name);

        setIsHide(true);
        setErrorMessages(['']);
      } else {
        setIsHide(false);
        setErrorMessages([
          'ファイル名が長すぎます。',
          'ファイル名は50文字以内にしてください。',
        ]);
      }
    }
  };

  const uploadFile = async () => {
    try {
      if (file && appContext.state.LoginUser.isLogin) {
        const formData = new FormData();
        formData.append('idCust', file.idCust.toString());
        formData.append('id', file.id ? file.id.toString() : '');
        formData.append('fileType', file.fileType.toString());
        formData.append('name', file.name);
        formData.append('userID', file.userID.toString());
        formData.append('downloadCount', '0');
        formData.append('billingDate', billingDate);
        formData.append('isEnable', file.isEnable.toString());
        if (file.formFile) {
          formData.append('formFile', file.formFile);
        }
        const url = `${API_URL}api/v1/files`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodPut(
          url,
          formData,
          true,
          appContext.state.LoginUser,
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (res.status === 200) {
          // const data = res.data as FileInfo;

          setFile(undefined);
          setFileName('');
          setBillingDate('');
          dialogClose(true);
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
    if (commDlg) setCommDlg(false);
  };

  const confirmation = async () => {
    if (idCust === 0) {
      return;
    }
    if (!billingDate) {
      setIsHide(false);
      setErrorMessages(['請求月を選択してください。']);

      return;
    }
    if (file) {
      const fileKey: FilesKey = {
        idCust: file.idCust,
        id: file.id,
        fileType: file.fileType,
        no: file.no,
        name: '',
        path: '',
        billingDate,
        isEnable: null,
      };
      const filesKey: FilesKey[] = [];
      filesKey.push(fileKey);
      const fileInfo = await findFile(filesKey, appContext.state.LoginUser);
      if (fileInfo && fileInfo.length > 0) {
        setIsHide(false);
        setErrorMessages([
          '指定した年月の請求書はアップロードされています。',
          '年月を変更するか、ファイルを削除してから再度アップロードしてください。',
        ]);
      } else {
        uploadFile().finally(() => {
          /* Nothing to do. */
        });
      }
    } else {
      setIsHide(false);
      setErrorMessages(['ファイルを選択してください。']);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => dialogClose(false)}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>月次請求書アップロード</Box>
        </DialogTitle>
        <div className={classes.form}>
          <DialogContent>
            <MessagePanel messages={errorMessages} isHide={isHide} />
            <input
              ref={refInput}
              type="file"
              onChange={saveFile}
              onClick={fileClick}
              className={classes.fileInput}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              id="customerName"
              label="顧客名"
              name="customerName"
              value={name}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              required
              id="billingMonth"
              label="請求月"
              name="billingMonth"
              type="month"
              value={billingDate}
              InputLabelProps={{ shrink: true }}
              className={classes.length}
              onChange={billingDateChange}
            />
            <TextField
              variant="outlined"
              margin="dense"
              required
              id="uploadFile"
              label="アップロードファイル"
              name="uploadFile"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
              }}
              className={classes.uploadFile}
              value={fileName}
            />
            <Button
              variant="contained"
              color="inherit"
              className={classes.space}
              onClick={addClick}
            >
              参照
            </Button>
          </DialogContent>
          <MuiThemeProvider theme={theme2}>
            <DialogActions>
              <MuiThemeProvider theme={theme3}>
                <Button
                  variant="contained"
                  onClick={confirmation}
                  color="primary"
                  className={classes.submit}
                >
                  アップロード
                </Button>
              </MuiThemeProvider>
              <Button
                variant="contained"
                onClick={() => dialogClose(false)}
                color="primary"
                className={classes.submit}
              >
                キャンセル
              </Button>
            </DialogActions>
          </MuiThemeProvider>
        </div>
      </Dialog>
    </div>
  );
};

export default InvoiceUploadDialog;
