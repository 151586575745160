// 統合
const cognitoConstants = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

// デバッグ用
// const cognitoConstants = {
//   aws_project_region: 'ap-northeast-1',
//   aws_cognito_identity_pool_id:
//     'ap-northeast-1:e9b58c96-4bf9-4e06-a7f5-d48eee806b0c',
//   aws_cognito_region: 'ap-northeast-1',
//   aws_user_pools_id: 'ap-northeast-1_8eMmHoaCO',
//   aws_user_pools_web_client_id: '4b8t3r3iaee8e2ggtjoso3o424',
// };

// テスト環境
// const cognitoConstants = {
//   aws_project_region: 'ap-northeast-1',
//   aws_cognito_identity_pool_id:
//     'ap-northeast-1:c95de3f3-249e-44c6-a788-7193badfbfe2',
//   aws_cognito_region: 'ap-northeast-1',
//   aws_user_pools_id: 'ap-northeast-1_PpF2TgShx',
//   aws_user_pools_web_client_id: '3tk3uhh1h7r4g76m8arafbnvf1',
// };

// 本番環境
// const cognitoConstants = {
//   aws_project_region: 'us-east-1',
//   aws_cognito_identity_pool_id:
//     'us-east-1:71d7826c-7470-4cad-b373-7ad228b0ca67',
//   aws_cognito_region: 'us-east-1',
//   aws_user_pools_id: 'us-east-1_BQr0f6lLF',
//   aws_user_pools_web_client_id: '3g68kpj65hb5bpog9q1hcabtce',
// };

export default cognitoConstants;
