import { useState, FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SearchPanel from 'components/molecules/SearchPanel';
import RequestListPanel from 'components/molecules/RequestListPanel';
import { useLocation } from 'react-router-dom';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';
import { RequestDataInfo } from '../../types/RequestDataInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
  }),
);

/* non eslint-disable  */
const RequestSearchPage: FC = () => {
  const classes = useStyles();
  const [requestData, setRequestData] = useState<RequestDataInfo[]>();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const { custData } = (location.state as { custData: string }) || {
    custData: '',
  };

  return (
    <HeaderTemplate title="依頼検索" subTitle="※一昨年度分まで閲覧可能です。">
      <Container component="main" maxWidth="xl">
        <div className={classes.paper}>
          <SearchPanel
            setResult={setRequestData}
            setIsLoading={setIsLoading}
            defaultCustData={custData}
          />
          <div hidden={isLoading}>
            <RequestListPanel result={requestData} setResult={setRequestData} />
          </div>
        </div>
      </Container>
      <CustomBackdrop open={isLoading} />
    </HeaderTemplate>
  );
};

export default RequestSearchPage;
