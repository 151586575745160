/* non eslint-disable  */
import { FC, useState, MouseEvent, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button as Button2 } from 'antd';
import 'antd/dist/antd.css';
import './Page.css';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClientSearchPanel from 'components/molecules/ClientSearchPanel';
import { UserListModel } from 'types/User';
import { RecieptType } from 'types/RecieptType';
import { rootDir } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
  }),
);

export type ClientSearchPanelProps = {
  title?: string;
};

const ClientSearchPage: FC<ClientSearchPanelProps> = ({ title = '' }) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<UserListModel[]>([]);
  const [recieptTypes, setRecieptTypes] = useState<RecieptType[]>([]);
  const [idCust, setIdCust] = useState(0);

  useEffect(() => {
    if (appContext.state.LoginUser.isLogin) {
      if (!appContext.state.LoginUser.isMaster) {
        // 管理者専用ページのため
        navigate(`${rootDir}top`);
      }
    }
  }, [appContext.state.LoginUser, navigate]);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      title: '顧客番号',
      dataIndex: 'custData',
      key: 'custData',
      width: 150,
      align: 'center' as const,
    },
    {
      title: '顧客名',
      dataIndex: 'custName',
      key: 'custName',
      render: (value: string, record: UserListModel) => (
        // eslint-disable-next-line
        <a
          onClick={() => {
            navigate(`${rootDir}accountMaster`, {
              state: { idCust: record.idCust },
            });
          }}
        >
          {value}
        </a>
      ),
      align: 'center' as const,
    },
    {
      title: '住所',
      align: 'center' as const,
      render: (_value: string, record: UserListModel) =>
        `${record.address1} ${record.address2}`,
    },
    {
      title: '依頼件数',
      dataIndex: 'recieptCount',
      key: 'recieptCount',
      width: 150,
      render: (value: string, record: UserListModel) => (
        // eslint-disable-next-line
        <a
          onClick={() => {
            navigate(`${rootDir}requestSearch`, {
              state: { custData: record.custData },
            });
          }}
        >
          {value}
        </a>
      ),
      align: 'center' as const,
    },
    {
      title: '月次請求書',
      dataIndex: 'chargeCount',
      key: 'chargeCount',
      width: 150,
      render: (value: string, record: UserListModel) => {
        if (record.isMonthlyInvoice && record.isWeb) {
          return (
            // eslint-disable-next-line
            <a
              onClick={() => {
                navigate(`${rootDir}invoiceUpload`, {
                  state: { idCust: record.idCust },
                });
              }}
            >
              {value}
            </a>
          );
        }

        return '－';
      },
      align: 'center' as const,
    },
    {
      title: '代理依頼申込',
      dataIndex: 'application',
      key: 'application',
      width: 150,
      render: (_value: string, record: UserListModel) => (
        <div className="clientSearch-button">
          <Button2
            onClick={(e) => {
              setIdCust(record.idCust);
              handleMenu(e);
            }}
            type="primary"
          >
            依頼申込
          </Button2>
          <Menu
            id="application"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={open2}
            onClose={handleClose}
          >
            {recieptTypes
              ?.filter((x) => x.id !== 0)
              .map((item) => (
                <MenuItem
                  key={item.menuTitle}
                  value={`${item.id}`}
                  onClick={() => {
                    navigate(`${rootDir}requestInfo`, {
                      state: {
                        id: null,
                        idCust,
                        recieptType: item.id,
                      },
                    });
                    handleClose();
                  }}
                >
                  {item.menuTitle}
                </MenuItem>
              ))}
          </Menu>
        </div>
      ),
      align: 'center' as const,
    },
  ];

  return (
    <HeaderTemplate title="顧客検索">
      <Container component="main" maxWidth="xl">
        <div className={classes.paper}>
          <Typography component="h5" variant="h4">
            {title}
          </Typography>
          <ClientSearchPanel
            setResult={setUserData}
            setIsLoading={setIsLoading}
            setRecieptTypes={setRecieptTypes}
          />
          <div className="table-size" hidden={isLoading}>
            <Table dataSource={userData} columns={columns} rowKey="idCust" />
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default ClientSearchPage;
