// テスト環境用
// export const SERVER_ROOT_URL = 'https://e-yobou-dev.exnjkcorp.net/e-yobou-api/';
// export const debugMode = false;
// 本番環境用
// export const SERVER_ROOT_URL = 'https://e-yobou.jp/e-yobou-api/';
// export const debugMode = false;
// デバッグ用
// export const SERVER_ROOT_URL = '';
// export const debugMode = true;

export const SERVER_ROOT_URL = process.env.REACT_APP_SERVER_ROOT_URL ?? '';
export const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

export const API_URL = `${SERVER_ROOT_URL}`;

// テスト環境用
// 本番環境用
export const rootDir = '/';

export const mailSendType: { [key: string]: number } = {
  To: 1,
  Cc: 2,
  Bcc: 3,
};

export const addressType: { [key: string]: number } = {
  DestinationResult: 1,
  DestinationInvoice: 2,
  InvoiceAddress: 3,
};
