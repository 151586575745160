import { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { API_URL } from 'utils/CommonConst';
import { consoleLog, debugLog, methodPost } from 'utils/CommonFunctions';
import CustomTextField from 'components/molecules/CustomTextField';
import HeaderTemplate from '../templates/HeaderTemplate';
import { rootDir } from '../../utils/CommonConst';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

const PasswordInitializationPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [mailAddress, setMailAddress] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>('info');
  const changedMailAddressHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setMailAddress(event.target.value);

  const click = async () => {
    if (!mailAddress) {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['メールアドレスが入力されていません。']);

      return;
    }
    try {
      const url = `${API_URL}api/v1/users/passwordreset`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(url, mailAddress, false);
      setIsHide(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (res.data) {
        setMode('info');
        setIsSuccess(true);
        setErrorMessages(['パスワード再設定メールを送信しました。']);
        debugLog('パスワード再設定メールを送信しました。');
      } else {
        setMode('error');
        setErrorMessages([
          '入力されたメールアドレスがシステムに登録されていません。',
        ]);
        debugLog(res);
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            パスワード初期化
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess}>
            アカウント担当者のメールアドレスを入力してください。
            <br />
            <br />
            <CustomTextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="mailAddress"
              label="メールアドレス"
              name="mailAddress"
              value={mailAddress}
              onChange={changedMailAddressHandler}
              autoComplete="mailAddress"
              autoFocus
              inputProps={{ maxLength: 50 }}
            />
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={click}
              >
                メール送信
              </Button>
            </Box>
          </div>
          <div hidden={!isSuccess}>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => navigate(`${rootDir}`)}
              >
                ログイン画面へ
              </Button>
            </Box>
          </div>
        </div>
      </Container>
    </HeaderTemplate>
  );
};

export default PasswordInitializationPage;
