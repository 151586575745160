import { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export type CommonDialogProps = {
  /* eslint-disable */
  msg: any;
  isOpen: boolean;
  doYes: any;
  doNo: VoidFunction;
  okOnly?: boolean;
  buttonDisabled?: boolean;
  yesWord?: string;
  noWord?: string;
};

const CommonDialog: FC<CommonDialogProps> = ({
  msg,
  isOpen,
  doYes,
  doNo,
  okOnly = false,
  buttonDisabled = false,
  yesWord = 'はい',
  noWord = 'いいえ',
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNo()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>{msg}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => doNo()}
            color="primary"
            hidden={okOnly}
            disabled={buttonDisabled}
          >
            {noWord}
          </Button>
          <Button
            onClick={() => doYes()}
            color="primary"
            disabled={buttonDisabled}
          >
            {yesWord}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CommonDialog;
