import jwtDecode from 'jwt-decode';
import { useAppContext } from './AppContext';
import { consoleLog, debugLog } from './CommonFunctions';

/**
 * helper method to validate  user token
 *
 * @param {*} token
 * @returns {boolean}
 */
/* eslint-disable  */
const validateToken = (token: any): boolean => {
  if (!token) {
    // console.log('token:false');
    return false;
  }
  try {
    const appContext = useAppContext();
    const decodedJwt: any = jwtDecode(token);
    debugLog(decodedJwt);

    let flg = decodedJwt.exp >= Date.now() / 1000;
    // if (!flg) {
    //   const init = async () => {
    //     const currentUser = await getCurrentUser();
    //     const state = { ...appContext.state };
    //     state.LoginUser = currentUser;

    //     appContext.setState(state);
    //     flg = currentUser.isLogin;
    //   };
    //   init().finally(() => {
    //     /* Nothing to do. */
    //   });
    // }
    return flg;
  } catch (e) {
    consoleLog(e);

    return false;
  }
};

export default validateToken;
