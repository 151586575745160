import { FC, useEffect } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { getCurrentUser } from 'utils/CommonFunctions';
import FinishPage from 'components/pages/FinishPage';
import AboutStaffPage from 'components/pages/AboutStaffPage';
import { AppContext, useAppContext } from 'utils/AppContext';
import TopPage from 'components/pages/TopPage';
import NewsPage from 'components/pages/NewsPage';
import CofirmMailOldPage from 'components/pages/CofirmMailOldPage';
import PasswordChangeClientPage from 'components/pages/PasswordChangeClientPage';
import LoginPage from './components/pages/LoginPage';
import PasswordInitializationPage from './components/pages/PasswordInitializationPage';
import PasswordResetPage from './components/pages/PasswordResetPage';
import CofirmMailPage from './components/pages/CofirmMailPage';
import InquiryPage from './components/pages/InquiryPage';
import ApplicationForUsePage from './components/pages/ApplicationForUsePage';
import AccountInfoPage from './components/pages/AccountInfoPage';
import PasswordChangePage from './components/pages/PasswordChangePage';
import ClientSearchPage from './components/pages/ClientSearchPage';
import RequestSearchPage from './components/pages/RequestSearchPage';
import StaffManagementPage from './components/pages/StaffManagementPage';
import InvoiceUploadPage from './components/pages/InvoiceUploadPage';
import RequestInfoPage from './components/pages/RequestInfoPage';
import MailFormPage from './components/pages/MailFormPage';
import InvoicePage from './components/pages/InvoicePage';
import FileUploadPage from './components/pages/FileUploadPage';
import { rootDir } from './utils/CommonConst';
import PrivateRoute from './navigation/PrivateRoute';
import UnAuthRoute from './navigation/UnAuthRoute';
import LoginInfoPage from './components/pages/LoginInfoPage';
import BillingInfoPage from './components/pages/BillingInfoPage';
import cognitoConstants from './utils/auth';

Amplify.configure(cognitoConstants);

const App: FC = () => {
  const appContext = useAppContext();

  /* eslint-disable  */
  useEffect(() => {
    const init = async () => {
      const currentUser = await getCurrentUser();
      const state = { ...appContext.state };
      state.LoginUser = currentUser;

      appContext.setState(state);
    };
    init().finally(() => {
      /* Nothing to do. */
    });
  }, []);
  /* eslint-enable  */

  return (
    <AppContext.Provider value={appContext}>
      <Routes basename={rootDir}>
        <Route
          path="/passwordInitialization"
          element={<PasswordInitializationPage />}
        />
        <Route path="/passwordReset" element={<PasswordResetPage />} />
        <Route path="/confirmmail" element={<CofirmMailPage />} />
        <Route path="/confirmmailold" element={<CofirmMailOldPage />} />
        <Route path="/aboutstaff" element={<AboutStaffPage />} />
        <Route path="/inquiry" element={<InquiryPage />} />
        <Route path="/applicationForUse" element={<ApplicationForUsePage />} />
        <Route path="/accountRegister" element={<AccountInfoPage />} />
        <PrivateRoute
          path="/accountInfo"
          element={<AccountInfoPage mode="change" />}
        />
        <PrivateRoute
          path="/accountMaster"
          element={<AccountInfoPage mode="master" />}
        />
        <PrivateRoute
          path="/passwordChangeClient"
          element={<PasswordChangeClientPage />}
        />
        <PrivateRoute path="/passwordChange" element={<PasswordChangePage />} />
        <PrivateRoute path="/clientSearch" element={<ClientSearchPage />} />
        <PrivateRoute path="/requestSearch" element={<RequestSearchPage />} />
        <PrivateRoute path="/top" element={<TopPage />} />
        <PrivateRoute
          path="/staffManagement"
          element={<StaffManagementPage />}
        />
        <PrivateRoute path="/invoiceUpload" element={<InvoiceUploadPage />} />
        <PrivateRoute path="/requestInfo" element={<RequestInfoPage />} />
        <PrivateRoute path="/mailForm" element={<MailFormPage />} />
        <PrivateRoute path="/news" element={<NewsPage />} />
        <PrivateRoute path="/invoice" element={<InvoicePage />} />
        <PrivateRoute path="/fileUpload" element={<FileUploadPage />} />
        <PrivateRoute path="/loginInfo" element={<LoginInfoPage />} />
        <PrivateRoute path="/billingInfo" element={<BillingInfoPage />} />
        <PrivateRoute path="/finish" element={<FinishPage />} />
        <UnAuthRoute path="/" element={<LoginPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AppContext.Provider>
  );
};
export default App;
