import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core';
import { API_URL } from 'utils/CommonConst';
import {
  checkDate,
  checkDate2,
  consoleLog,
  debugLog,
  methodGet,
  methodPost,
} from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import { RequestSearchConditions } from '../../types/RequestSearchConditions';
import { ProcessCategory } from '../../types/ProcessCategory';
import { RecieptType } from '../../types/RecieptType';
import { RequestDataInfo } from '../../types/RequestDataInfo';
import MessagePanel from './MessagePanel';
import CommonDialog from './CommonDialog';
import CustomTextField from './CustomTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(2, 2, 1),
    },
    control: {
      margin: theme.spacing(2, 2, 0, 2),
      minWidth: 200,
      width: 500,
    },
    dateControl: {
      margin: theme.spacing(2, 0, 0, 2),
      Width: 250,
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100%',
    },
    space: {
      margin: theme.spacing(3, 5, 0),
    },
    line: {
      margin: theme.spacing(2, 2, 0, 2),
      width: 500,
    },
    div: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 530,
    },
  }),
);

export type SearchPanelProps = {
  setResult: Dispatch<SetStateAction<RequestDataInfo[] | undefined>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  defaultCustData: string;
};

const SearchPanel: FC<SearchPanelProps> = ({
  setResult,
  setIsLoading,
  defaultCustData,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [custData, setCustDate] = useState('');
  const [custName, setCustName] = useState('');
  const [sRcptCreate, setSRcptCreate] = useState('');
  const [eRcptCreate, setERcptCreate] = useState('');
  const [recieptType, setRecieptType] = useState<number | ''>('');
  const [recieptTypes, setRecieptTypes] = useState<RecieptType[] | null>(null);
  const [process, setProcess] = useState<number | ''>('');
  const [processCategories, setProcessCategories] = useState<
    ProcessCategory[] | null
  >(null);
  const [mesItem, setMesItem] = useState('');
  const [nameSampleIndex, setNameSampleIndex] = useState('');
  const [pic, setPic] = useState('');
  const [commDlg, setCommDlg] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();

  // 検索処理
  const requestSearch = useCallback(
    (conditions: RequestSearchConditions, auto = true) => {
      if (
        (conditions.sRcptCreate !== '' &&
          !checkDate(conditions.sRcptCreate.replaceAll('-', '/'))) ||
        (conditions.eRcptCreate !== '' &&
          !checkDate(conditions.eRcptCreate.replaceAll('-', '/')))
      ) {
        setIsHide(false);
        setErrorMessages(['依頼日の日付が異常です。']);
        debugLog('依頼日の日付が異常です。');

        return;
      }
      if (
        conditions.sRcptCreate !== '' &&
        conditions.eRcptCreate !== '' &&
        !checkDate2(
          conditions.sRcptCreate.replaceAll('-', '/'),
          conditions.eRcptCreate.replaceAll('-', '/'),
        )
      ) {
        setIsHide(false);
        setErrorMessages(['依頼日の指定期間が異常です。']);
        debugLog('依頼日の指定期間が異常です。');

        return;
      }
      setIsHide(true);

      const getData = async () => {
        try {
          const url = `${API_URL}api/v1/requests/get`;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const res = await methodPost(
            url,
            conditions,
            true,
            appContext.state.LoginUser,
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = res.data as RequestDataInfo[];
          if (data.length > 0) {
            setResult(data);
          } else {
            if (!auto) {
              // 手動検索時のみメッセージ表示
              const text = `該当するデータがありません。`;
              const breakedText = text.split('¥n').map((line) => (
                <span key={line}>
                  {line}
                  <br />
                </span>
              ));
              setConfirmMessages(breakedText);
              setCommDlg(true);
            }
            setResult(data);
          }
        } catch (ex) {
          consoleLog(ex);
        }
      };
      if (appContext.state.LoginUser.isLogin) {
        setIsLoading(true);
        getData().finally(() => {
          /* Nothing to do. */
          setIsLoading(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appContext.state.LoginUser],
  );

  useEffect(() => {
    // 状況マスタの取得・設定
    const getProcessCategory = async () => {
      try {
        const url = `${API_URL}api/v1/masters/processcategory`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as ProcessCategory[];
        setProcessCategories(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    // 検査区分マスタの取得・設定
    const getRecieptType = async () => {
      try {
        const url = `${API_URL}api/v1/masters/recieptttype`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as RecieptType[];
        setRecieptTypes(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    getProcessCategory().finally(() => {
      /* Nothing to do. */
    });
    getRecieptType().finally(() => {
      /* Nothing to do. */
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultCustData !== '') {
      // 顧客検索画面からの遷移時
      setCustDate(defaultCustData);
      const conditions: RequestSearchConditions = {
        custData: defaultCustData,
        custName: '',
        sRcptCreate: '',
        eRcptCreate: '',
        recieptType: null,
        process: null,
        mesItem: '',
        nameSampleIndex: '',
        pic: '',
        isMaster: appContext.state.LoginUser.isMaster,
        idCust: appContext.state.LoginUser.idCust,
      };
      requestSearch(conditions);
    }
    if (defaultCustData === '') {
      // 通常検索
      const conditions: RequestSearchConditions = {
        custData: '',
        custName: '',
        sRcptCreate: '',
        eRcptCreate: '',
        recieptType: null,
        process: null,
        mesItem: '',
        nameSampleIndex: '',
        pic: '',
        isMaster: appContext.state.LoginUser.isMaster,
        idCust: appContext.state.LoginUser.idCust,
      };
      requestSearch(conditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCustData, appContext.state.LoginUser]);

  const changedCustDate = (event: ChangeEvent<HTMLInputElement>) =>
    setCustDate(event.target.value);
  const changedCustName = (event: ChangeEvent<HTMLInputElement>) =>
    setCustName(event.target.value);
  const changedSRcptCreate = (event: ChangeEvent<HTMLInputElement>) =>
    setSRcptCreate(event.target.value);
  const changedERcptCreate = (event: ChangeEvent<HTMLInputElement>) =>
    setERcptCreate(event.target.value);
  const chengeRecieptType = (event: ChangeEvent<{ value: unknown }>) => {
    setRecieptType(event.target.value as number);
  };
  const chageProcess = (event: ChangeEvent<{ value: unknown }>) => {
    setProcess(event.target.value as number);
  };
  const chageMesItem = (event: ChangeEvent<HTMLInputElement>) =>
    setMesItem(event.target.value);
  const chageNameSampleIndex = (event: ChangeEvent<HTMLInputElement>) =>
    setNameSampleIndex(event.target.value);
  const chagePic = (event: ChangeEvent<HTMLInputElement>) =>
    setPic(event.target.value);

  return (
    <div className={classes.form}>
      <MessagePanel messages={errorMessages} isHide={isHide} />
      {appContext.state.LoginUser.isMaster && (
        <>
          <CustomTextField
            variant="outlined"
            margin="dense"
            id="custName"
            label="顧客名"
            name="custName"
            autoComplete="custName"
            className={classes.line}
            value={custName}
            onChange={changedCustName}
          />
          <CustomTextField
            variant="outlined"
            margin="dense"
            id="custData"
            label="顧客番号"
            name="custData"
            autoComplete="custData"
            className={classes.line}
            value={custData}
            onChange={changedCustDate}
          />
        </>
      )}
      <div className={classes.div}>
        <CustomTextField
          variant="outlined"
          id="firstDayOfRequest"
          label="依頼日"
          name="firstDayOfRequest"
          type="date"
          margin="dense"
          className={classes.dateControl}
          value={sRcptCreate}
          onChange={changedSRcptCreate}
          InputLabelProps={{ shrink: true }}
        />
        <Box className={classes.space}>～</Box>
        <CustomTextField
          variant="outlined"
          id="lastDayOfRequest"
          label="依頼日"
          name="lastDayOfRequest"
          type="date"
          margin="dense"
          className={classes.dateControl}
          value={eRcptCreate}
          onChange={changedERcptCreate}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <FormControl
        variant="outlined"
        margin="dense"
        className={classes.control}
      >
        <InputLabel>検査区分</InputLabel>
        <Select
          value={`${recieptType}`}
          onChange={chengeRecieptType}
          label="検査区分"
        >
          {recieptTypes?.map((item) => (
            <MenuItem
              key={item.menuTitle}
              value={item.id === 0 ? '' : `${item.id}`}
            >
              {item.menuTitle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        margin="dense"
        className={classes.control}
      >
        <InputLabel>状況</InputLabel>
        <Select value={`${process}`} onChange={chageProcess} label="状況">
          {processCategories?.map((item) => (
            <MenuItem
              key={item.name}
              value={item.idProcess === 0 ? '' : `${item.idProcess}`}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CustomTextField
        variant="outlined"
        margin="dense"
        id="inspectionItem"
        label="検査項目"
        name="inspectionItem"
        autoComplete="inspectionItem"
        className={classes.line}
        value={mesItem}
        onChange={chageMesItem}
      />
      <CustomTextField
        variant="outlined"
        margin="dense"
        id="sampleName"
        label="試験品名"
        name="sampleName"
        autoComplete="sampleName"
        className={classes.line}
        value={nameSampleIndex}
        onChange={chageNameSampleIndex}
      />
      <CustomTextField
        variant="outlined"
        margin="dense"
        id="staffName"
        label="担当者名"
        name="staffName"
        autoComplete="staffName"
        className={classes.line}
        value={pic}
        onChange={chagePic}
      />
      <Box textAlign="right">
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            const conditions: RequestSearchConditions = {
              custData,
              custName,
              sRcptCreate,
              eRcptCreate,
              recieptType: recieptType !== '' ? recieptType : null,
              process: process !== '' ? process : null,
              mesItem,
              nameSampleIndex,
              pic,
              isMaster: appContext.state.LoginUser.isMaster,
              idCust: appContext.state.LoginUser.idCust,
            };
            requestSearch(conditions, false);
          }}
        >
          検索
        </Button>
      </Box>
      <CommonDialog
        msg={confirmMessages}
        isOpen={commDlg}
        doYes={() => {
          setCommDlg(false);
        }}
        doNo={() => {
          setCommDlg(false);
        }}
        okOnly
      />
    </div>
  );
};

export default SearchPanel;
