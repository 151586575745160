/* not eslint-disable */
import { ChangeEvent, FC, useState } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';
import 'antd/dist/antd.css';
import './Panel.css';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { Button as Button2 } from 'antd';
import BusinessContentDialog from 'components/molecules/BusinessContentDialog';
import { ErrorInfo, Request, Task, TaskSample } from 'types/RequestDataInfo';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DraggableTable from './DraggableTable';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    sepalate: { margin: theme.spacing(7, 0, 2) },
    submit: {
      margin: theme.spacing(0, 2),
      maxWidth: 100,
      '&:hover': {
        background: 'darkorange',
      },
    },
    space: {
      margin: theme.spacing(0, 2, 0),
      verticalAlign: 'middle',
    },
    toNextDiv: {
      textAlign: 'right',
      margin: theme.spacing(5, 0, 0),
    },
    toNextButton: {
      margin: theme.spacing(0, 2, 5),
    },
    toTopButton: {
      width: 100,
      margin: theme.spacing(0, 0, 5),
    },
    error: {
      backgroundColor: 'red',
      color: 'white',
    },
    waring: {
      color: 'red',
    },
    annotation: {
      fontSize: '15px',
    },
  }),
);

export type BusinessContentPanelProps = {
  requestData?: Request;
  updateRequest?: (value: Request) => void;
  tabSet: (value: number) => void;
  errorInfo?: ErrorInfo;
  check: () => boolean;
};
/* not eslint-disable */
const BusinessContentPanel: FC<BusinessContentPanelProps> = ({
  requestData,
  updateRequest,
  tabSet,
  errorInfo,
  check,
}) => {
  const classes = useStyles();

  const [task, setTask] = useState<Task | null>(null);
  const [openBusinessContent, setOpenBusinessContent] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  const handleCloseBusinessContent = () => {
    setOpenBusinessContent(false);
  };

  // トップへ移動
  const scrollToTop = () => {
    scroller.scrollTo('TopPage', {
      duration: 250,
      delay: 100,
      smooth: true,
      containerId: 'HeaderContent',
      offset: -150, // Scrolls to element + 50 pixels down the page
    });
  };

  // 追加ボタン
  const openAddDialog = () => {
    if (requestData) {
      setIsEdit(false);
      const idTaskNo =
        requestData.taskList.length > 0
          ? Math.max(...requestData.taskList.map((p) => p.idTask))
          : 0;
      setTask({
        id: requestData.id,
        idTask: idTaskNo + 1,
        isJapanese: true,
        isEnglish: false,
        nameSampleIndex: '',
        nameSampleIndexEn: '',
        dateSamplingType: null,
        dateSampling: '',
        dateSamplingFree: '',
        sampler: '',
        samplerEn: '',
        samplePlace: '',
        samplePlaceEn: '',
        sampleWeight: '',
        sampleQuantity: '',
        sampleStatus: null,
        sampleAppearance: null,
        sampleAppearanceOthers: '',
        transportConditions: null,
        taskSampleList: [],
        comment: '',
        sortNo: null,
      });
    }
    setOpenBusinessContent(true);
  };

  const changeEstimatedShippingDate = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const data = { ...requestData } as Request;
    data.estimatedShippingDate = event.target.value;
    if (updateRequest) updateRequest(data);
  };

  const changEexpectedArrivalDate = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    data.expectedArrivalDate = event.target.value;
    if (updateRequest) updateRequest(data);
  };

  const columns = [
    {
      title: '試験品名',
      dataIndex: 'nameSampleIndex',
      key: 'nameSampleIndex',
      align: 'center' as const,
      render: (_value: string, record: Task) => {
        if (record.isJapanese && record.isEnglish) {
          return (
            <>
              <span>{record.nameSampleIndex}</span>
              <br />
              <span>{record.nameSampleIndexEn}</span>
            </>
          );
        }
        if (record.isJapanese) {
          return record.nameSampleIndex;
        }
        if (record.isEnglish) {
          return record.nameSampleIndexEn;
        }

        return null;
      },
    },
    {
      title: '採取年月日',
      dataIndex: 'dateSampling',
      key: 'dateSampling',
      align: 'center' as const,
      render: (_value: string, record: Task) => {
        if (record.dateSamplingType === 1) {
          return record.dateSampling.replaceAll('-', '/');
        }
        if (record.dateSamplingType === 2) {
          return record.dateSamplingFree;
        }

        return null;
      },
    },
    {
      title: '採取者氏名',
      dataIndex: 'sampler',
      key: 'sampler',
      align: 'center' as const,
      render: (_value: string, record: Task) => {
        if (record.isJapanese && record.isEnglish) {
          return (
            <>
              <span>{record.sampler}</span>
              <br />
              <span>{record.samplerEn}</span>
            </>
          );
        }
        if (record.isJapanese) {
          return record.sampler;
        }
        if (record.isEnglish) {
          return record.samplerEn;
        }

        return null;
      },
    },
    {
      title: '採取場所',
      dataIndex: 'samplePlace',
      key: 'samplePlace',
      align: 'center' as const,
      render: (_value: string, record: Task) => {
        if (record.isJapanese && record.isEnglish) {
          return (
            <>
              <span>{record.samplePlace}</span>
              <br />
              <span>{record.samplePlaceEn}</span>
            </>
          );
        }
        if (record.isJapanese) {
          return record.samplePlace;
        }
        if (record.isEnglish) {
          return record.samplePlaceEn;
        }

        return null;
      },
    },
    {
      title: () => (
        <div>
          採取量
          <br />
          数量
        </div>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center' as const,
      render: (_value: string, record: Task) => {
        if (record.sampleWeight && record.sampleQuantity) {
          return (
            <>
              <span>{record.sampleWeight}グラム</span>
              <br />
              <span>{record.sampleQuantity}個</span>
            </>
          );
        }
        if (record.sampleWeight) {
          return `${record.sampleWeight}グラム`;
        }
        if (record.sampleQuantity) {
          return `${record.sampleQuantity}個`;
        }

        return null;
      },
    },
    {
      title: '採取時状態',
      dataIndex: 'sampleStatus',
      key: 'sampleStatus',
      align: 'center' as const,
      render: (value: number) => {
        switch (value) {
          case 1:
            return '常温';
          case 2:
            return '冷蔵';
          case 3:
            return '冷凍';
          default:
            return null;
        }
      },
    },
    {
      title: '試験品外観',
      dataIndex: 'sampleAppearance',
      key: 'sampleAppearance',
      align: 'center' as const,
      render: (value: number, record: Task) => {
        switch (value) {
          case 1:
            return '新鮮';
          case 2:
            return record.sampleAppearanceOthers;
          default:
            return null;
        }
      },
    },
    {
      title: '搬送条件',
      dataIndex: 'transportConditions',
      key: 'transportConditions',
      align: 'center' as const,
      render: (value: number) => {
        switch (value) {
          case 1:
            return '常温';
          case 2:
            return '冷蔵';
          case 3:
            return '冷凍';
          default:
            return null;
        }
      },
    },
    {
      title: '検査項目',
      dataIndex: 'taskSampleList',
      key: 'taskSampleList',
      align: 'center' as const,
      render: (values: TaskSample[], _record: Task, index: number) => {
        const taskSamples: TaskSample[] = [...values];

        return {
          props: {
            style: { background: taskSamples.length === 0 ? 'red' : '' },
          },
          children: taskSamples?.map((taskSample) => (
            <span key={`${index}-${taskSample.mesItemName}`}>
              {taskSample.mesItemName}
              <br />
            </span>
          )),
        };
      },
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
      render: (_value: null, record: Task) => {
        const openEditDialog = () => {
          setIsEdit(true);
          setTask(record);
          setOpenBusinessContent(true);
        };

        return (
          <div className="edit-button">
            <Button2 type="primary" onClick={openEditDialog}>
              編集
            </Button2>
          </div>
        );
      },
      align: 'center' as const,
    },
    {
      title: '複製',
      dataIndex: 'duplicate',
      key: 'duplicate',
      render: (_value: null, record: Task) => {
        const openCopyDialog = () => {
          setIsEdit(false);
          const data = { ...record };
          data.taskSampleList = [...record.taskSampleList];
          for (let i = 0; i < data.taskSampleList.length; i += 1) {
            data.taskSampleList[i] = { ...record.taskSampleList[i] };
          }
          data.idTask = requestData
            ? Math.max(...requestData.taskList.map((p) => p.idTask)) + 1
            : 0;
          data.sortNo = null;
          setTask(data);
          setOpenBusinessContent(true);
        };

        return (
          <div className="duplicate-button">
            <Button2 type="primary" onClick={openCopyDialog}>
              複製
            </Button2>
          </div>
        );
      },
      align: 'center' as const,
    },
  ];

  return (
    <div className={classes.paper}>
      <table className="input-table">
        <tbody>
          <tr>
            <th className={errorInfo?.error9 === true ? classes.error : ''}>
              発送予定日
            </th>
            <td>
              <TextField
                variant="outlined"
                id="estimatedShippingDate"
                name="estimatedShippingDate"
                type="date"
                margin="dense"
                className={classes.space}
                value={requestData?.estimatedShippingDate ?? ''}
                onChange={changeEstimatedShippingDate}
              />
            </td>
          </tr>
          <tr>
            <th className={errorInfo?.error10 === true ? classes.error : ''}>
              到着予定日
            </th>
            <td className={classes.waring}>
              <TextField
                variant="outlined"
                id="expectedArrivalDate"
                name="expectedArrivalDate"
                type="date"
                margin="dense"
                className={classes.space}
                value={requestData?.expectedArrivalDate ?? ''}
                onChange={changEexpectedArrivalDate}
              />
              ご不明な場合は発送予定日の２日後を予定日としてください
            </td>
          </tr>
        </tbody>
      </table>
      <MuiThemeProvider theme={theme2}>
        <Typography component="h1" variant="h6" className={classes.sepalate}>
          <span className={errorInfo?.error11 === true ? classes.error : ''}>
            試験品一覧
          </span>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={openAddDialog}
          >
            追加
          </Button>
          <span className={classes.annotation}>
            ※ドラッグ＆ドロップで試験品の並び替えが可能です。
          </span>
        </Typography>
      </MuiThemeProvider>
      <BusinessContentDialog
        open={openBusinessContent}
        onClickClose={handleCloseBusinessContent}
        task={task}
        requestData={requestData}
        updateTask={(value: Task, isDelete?: boolean) => {
          const data = { ...requestData } as Request;
          if (isDelete) {
            data.taskList = data.taskList.filter(
              (n) => n.idTask !== value.idTask,
            );
          } else {
            data.taskList = data.taskList.map((atask) => {
              if (atask.idTask === value.idTask) {
                return value;
              }

              return atask;
            });
            const exists = data.taskList.find((n) => n.idTask === value.idTask);
            if (!exists) {
              data.taskList.push(value);
            }
          }
          if (updateRequest) updateRequest(data);
        }}
        isEdit={isEdit}
      />
      <div className="staff-select">
        {/* <Table
          dataSource={requestData?.taskList ?? []}
          columns={columns}
          rowKey="idTask"
        /> */}
        <DraggableTable
          requestData={requestData}
          updateRequest={updateRequest}
          columns={columns}
          rowkey="idTask"
        />
      </div>
      <div className={classes.toNextDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.toNextButton}
          onClick={() => {
            if (check()) {
              tabSet(2);
            } else {
              scrollToTop();
            }
          }}
        >
          ③添付ファイルに進む
        </Button>
        <ScrollLink
          to="TopPage"
          smooth
          duration={250}
          containerId="HeaderContent"
          offset={-150}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.toTopButton}
          >
            トップへ
          </Button>
        </ScrollLink>
      </div>
    </div>
  );
};

export default BusinessContentPanel;
