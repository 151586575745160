import { FC, useState } from 'react';
import 'antd/dist/antd.css';
import './Panel.css';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import StaffSelectDialog from 'components/molecules/StaffSelectDialog';
import { Pic } from 'types/RequestDataInfo';
import { mailSendType } from 'utils/CommonConst';
import { StaffListModel } from 'types/User';
import { Link as ScrollLink } from 'react-scroll';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    space: {
      margin: theme.spacing(1.5, 3, 2),
    },
    width: {
      width: 250,
    },
    length: {
      width: 150,
    },
    waring: {
      color: 'red',
    },
  }),
);
export type StaffListPanelProps = {
  title?: string;
  pics?: Pic[];
  picAll?: StaffListModel[];
  updatePic?: (value: Pic[]) => void;
  picType: number;
  copyPic?: () => void;
  buttonEnable?: boolean;
};

const StaffListPanel: FC<StaffListPanelProps> = ({
  title = '',
  pics = [],
  picAll,
  updatePic,
  picType,
  copyPic,
  buttonEnable = true,
}) => {
  const classes = useStyles();

  const [openSelect, setOpenSelect] = useState(false);

  const handleClickOpenSelect = () => {
    setOpenSelect(true);
  };

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h6">
        {title}
        <Button
          variant="contained"
          color="primary"
          className={classes.space}
          hidden={picType === 1}
          onClick={() => {
            if (copyPic) copyPic();
          }}
          disabled={!buttonEnable}
        >
          依頼者と同じ
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.space}
          onClick={handleClickOpenSelect}
          disabled={!buttonEnable}
        >
          編集
        </Button>
        {!buttonEnable && (
          <ScrollLink
            to="InvoiceContent"
            smooth
            duration={250}
            offset={-50}
            containerId="HeaderContent"
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.space}
            >
              請求方法を変更する
            </Button>
          </ScrollLink>
        )}
        <StaffSelectDialog
          open={openSelect}
          onClickClose={handleCloseSelect}
          picList={pics}
          picAll={picAll}
          updatePic={updatePic}
          picType={picType}
        />
      </Typography>
      <div className="html-table" hidden={!buttonEnable}>
        <table>
          <tbody>
            <tr>
              <th className={classes.width}>所属</th>
              <th className={classes.width}>担当者名</th>
              <th className={classes.length}>宛先設定</th>
              <th>メールアドレス</th>
            </tr>
            {pics.map((pic) => (
              <tr key={`${pic.id}-${pic.mailSendType}`}>
                <td>{pic.section}</td>
                <td>{pic.name}</td>
                <td>
                  {pic.mailSendType === mailSendType.To && `To`}
                  {pic.mailSendType === mailSendType.Cc && `Cc`}
                  {pic.mailSendType === mailSendType.Bcc && `Bcc`}
                </td>
                <td>{pic.mail}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.waring} hidden={buttonEnable}>
        <span>※月次請求書の通知は月次請求設定の担当者へ送信されます。</span>
      </div>
    </div>
  );
};

export default StaffListPanel;
