import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AccountStaffInput from 'components/molecules/AccountStaffInput';
import LoginIDInput from 'components/molecules/LoginIDInput';
import { LoginInfo, UpdateLoginInfo } from 'types/User';
import { API_URL, rootDir } from 'utils/CommonConst';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import {
  consoleLog,
  debugLog,
  getUsername,
  loginIDCheckFunc,
  mailCheckFunc,
  methodGet,
} from 'utils/CommonFunctions';
import { useLocation, useNavigate } from 'react-router';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import CustomTextField from 'components/molecules/CustomTextField';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import CommonDialog from 'components/molecules/CommonDialog';
import HeaderTemplate from '../templates/HeaderTemplate';

const theme1 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 0),
    },
    length: {
      width: 400, // Fix IE 11 issue.
      maxWidth: 600,
    },
    backButton: {
      margin: theme.spacing(3, 5, 0),
    },
    titleSpace: {
      marginBottom: theme.spacing(8),
    },
    checkbox: {
      margin: theme.spacing(1, 1),
    },
  }),
);

const LoginInfoPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const mailCheck =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
  const loginIDCheck = /^[A-Za-z0-9]{8,12}$/;
  const [isLoading, setIsLoading] = useStateIfMounted(true);

  const [loginData, setLoginData] = useState<LoginInfo>();
  const [loginData2, setLoginData2] = useState<LoginInfo>();
  const [mailUnusable, setChangeMailUnusable] = useState<boolean>(false);
  const [newMail, setNewMail] = useState<string>('');
  const [changeID, setChangeID] = useState<boolean>(false);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [mode, setMode] = useState<Mode>('info');
  const [dispMode, setDispMode] = useState('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [commDlg, setCommDlg] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();

  useEffect(() => {
    if (appContext.state.LoginUser.isMaster) {
      // 顧客専用ページのため
      navigate(`${rootDir}top`);
    }
  }, [appContext.state.LoginUser.isMaster, navigate]);

  // 担当者名変更
  const changedPicName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...loginData } as LoginInfo;
    data.picName = event.target.value;
    setLoginData(data);
  };
  // メールアドレス変更
  const changedNewMail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNewMail(value);
  };
  const changeMailUnusable = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setChangeMailUnusable(value);
  };
  // ログインID変更
  const changedLoginID = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...loginData } as LoginInfo;
    data.loginID = event.target.value;
    setLoginData(data);
  };
  // 戻る（変更）
  const clickChangeBack = () => {
    setDispMode('change');
  };

  // 戻る（初期）
  const clickInit = () => {
    setIsHide(true);
    setIsSuccess(false);
    setDispMode('change');
  };

  const mailChangeWarning =
    'メールアドレスを変更するには現在のメールアドレスで変更を承認する必要があります。現在のメールアドレスが使用できない場合、チェックを付けてください。';
  const breakedMailChangeWarning = mailChangeWarning.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  const loginInfo: LoginInfo = {
    idCust: appContext.state.LoginUser?.idCust,
    loginID: loginData?.loginID ?? '',
    picName: loginData?.picName ?? '',
    mail: loginData?.mail ?? '',
    loginFailuresCount: loginData?.loginFailuresCount ?? 0,
    isAccountLook: loginData?.isAccountLook ?? false,
    isEnable: loginData?.isEnable ?? false,
    isApproved: loginData?.isApproved ?? false,
    lastLoginDate: null,
  };

  const getData = useCallback(async () => {
    if (appContext.state.LoginUser.isLogin) {
      try {
        const url = `${API_URL}api/v1/users/${
          appContext.state.LoginUser.idCust ?? 0
        }/login`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as LoginInfo;
        setLoginData(data);
        setLoginData2(data);
        setIsLoading(false);
      } catch (ex) {
        consoleLog(ex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.state.LoginUser]);

  useEffect(() => {
    setDispMode('change');
    getData().finally(() => {
      /* Nothing to do. */
    });
  }, [getData]);

  // useEffect(() => {
  //   consoleLog(appContext.state);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const putData = async () => {
    try {
      const check = await loginIDCheckFunc(loginInfo.loginID);
      const check2 = await mailCheckFunc(newMail);
      if ((!changeID || check) && (newMail === '' || check2)) {
        const update: UpdateLoginInfo = {
          loginInfo,
          changeID,
          mailUnusable,
          newMail,
        };
        const username = await getUsername();

        const res = await axios.put(
          `${API_URL}api/v1/users/${
            appContext.state.LoginUser.idCust ?? 0
          }/login`,
          update,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              UserAccountName: username,
              userName: appContext.state.LoginUser.loginID,
              userID: appContext.state.LoginUser.isMaster
                ? appContext.state.LoginUser.idMaster ?? 0
                : appContext.state.LoginUser.idCust ?? 0,
              isMaster: appContext.state.LoginUser.isMaster,
            },
            timeout: 15000,
          },
        );
        const data = res.data as boolean;
        if (data) {
          setIsHide(false);
          setMode('info');
          setIsSuccess(true);

          if (changeID) {
            const state = { ...appContext.state };
            state.LoginUser = {
              ...state.LoginUser,
            };

            state.LoginUser.loginID = loginInfo.loginID;
            appContext.setState(state);
          }
          if (newMail === '') {
            debugLog('ログイン情報の変更が完了しました。');
            navigate(`${rootDir}finish`, {
              state: {
                title: 'ログイン情報',
                message: ['ログイン情報の変更が完了しました。'],
                from: location,
              },
            });
          } else if (!mailUnusable) {
            debugLog(
              'ログイン情報の変更が完了しました。メールアドレスについては変更前・後のメールアドレスに確認用メールを送信していますので、そのメールで認証後に有効になります。',
            );
            navigate(`${rootDir}finish`, {
              state: {
                title: 'ログイン情報',
                message: [
                  'ログイン情報の変更が完了しました。',
                  'メールアドレスについて、現在のメールアドレスに',
                  '確認用メールを送信しました。',
                  'そのメールでの認証と新しいメールアドレスでの認証後に有効になります。',
                ],
                from: location,
              },
            });
          } else {
            debugLog(
              'ログイン情報の変更が完了しました。メールアドレスについては変更前・後のメールアドレスに確認用メールを送信していますので、そのメールで認証後に有効になります。',
            );
            navigate(`${rootDir}finish`, {
              state: {
                title: 'ログイン情報',
                message: [
                  'ログイン情報の変更が完了しました。',
                  'メールアドレスについては当協会で確認後、',
                  '変更後のメールアドレスに確認用メールを送信します。',
                  'そのメールで認証後に有効になります。',
                ],
                from: location,
              },
            });
          }
        }
      } else if (changeID && check !== null) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(['ログインIDが重複しています。']);
      } else if (newMail !== '' && check2 !== null) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(['メールアドレスが重複しています。']);
      } else {
        setIsHide(false);
        setMode('error');
        setErrorMessages(['予期せぬエラーが発生しました。']);
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const clickVerifi = () => {
    const check = (
      data: LoginInfo,
      data2: LoginInfo,
      mail: string,
    ): boolean => {
      if (
        data.picName === data2.picName &&
        data.mail === data2.mail &&
        data.loginID === data2.loginID &&
        mail === ''
      ) {
        return true;
      }

      return false;
    };

    let error = false;
    const message: string[] = [];
    if (loginData && loginData2) {
      if (check(loginData, loginData2, newMail)) {
        message.push('・内容が変更されていません。');
        error = true;
      }
      if (loginData.picName === '') {
        message.push('・担当者名が入力されていません。');
        if (!error) error = true;
      }
      if (loginData.mail === '') {
        message.push('・メールアドレスが入力されていません。');
        if (!error) error = true;
      }
      if (loginData.mail !== '' && !mailCheck.test(loginData.mail)) {
        message.push('・メールアドレスの形式になっていません。');
        if (!error) error = true;
      }
      if (newMail !== '' && !mailCheck.test(newMail)) {
        message.push(
          '・新しいメールアドレスがメールアドレスの形式になっていません。',
        );
        if (!error) error = true;
      }
      if (newMail !== '' && newMail === loginData.mail) {
        message.push(
          '・現在のメールアドレスと新しいメールアドレスが同じです。',
        );
        if (!error) error = true;
      }
      if (loginData.loginID === '') {
        message.push('・ログインIDが入力されていません。');
        if (!error) error = true;
      }
      if (loginData.loginID !== '' && !loginIDCheck.test(loginData?.loginID)) {
        message.push('・ログインIDは8桁～12桁の半角英数字で入力してください。');
        if (!error) error = true;
      }
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);

        return;
      }
      if (loginData.loginID !== loginData2.loginID) {
        setChangeID(true);
      } else {
        setChangeID(false);
      }

      if (mailUnusable && newMail !== '') {
        const text = `＜メールアドレスの変更について＞¥n
        ※現在のメールアドレスが使用できない場合¥n
        ¥n
        当協会にて確認後に¥n
        新しいメールアドレスに変更確認メールを送付致します。¥n
        確認メールは通常１～２営業日の間に送信させていただきます。¥n
        ¥n
        なお、当協会で確認の際、¥n
        アカウントご担当者様にご連絡させていただく場合がございます。¥n
        ¥n
        上記についてご不明な点がございましたらご連絡ください。`;
        const breakedText = text.split('¥n').map((line, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`${line}-${index}`}>
            {line}
            <br />
          </span>
        ));

        setConfirmMessages(breakedText);
        setCommDlg(true);
      } else {
        setDispMode('changeVerifi');
        setIsHide(true);
      }
    }
  };

  const clickUpdate = () => {
    setIsLoading(true);
    putData().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
  };

  /* non eslint-disable  */
  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            className={dispMode === 'changeVerifi' ? classes.titleSpace : ''}
          >
            {dispMode === 'change' && 'ログイン情報'}
            {dispMode === 'changeVerifi' && 'ログイン情報（確認）'}
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess || isLoading}>
            <AccountStaffInput
              title="アカウント担当者名"
              margin="dense"
              value={loginData?.picName ?? ''}
              onChange={changedPicName}
              readonly={dispMode === 'changeVerifi'}
            />
            <CustomTextField
              variant="outlined"
              margin="dense"
              id="mail"
              label="現在のメールアドレス"
              value={loginData?.mail ?? ''}
              name="mail"
              className={classes.length}
              InputProps={{
                readOnly: true,
                inputProps: {
                  maxLength: 50,
                },
              }}
            />
            <MuiThemeProvider theme={theme1}>
              <Tooltip title={breakedMailChangeWarning} placement="bottom">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={changeMailUnusable}
                      checked={mailUnusable}
                    />
                  }
                  label="現在のメールアドレスは使用できない"
                  disabled={dispMode === 'changeVerifi'}
                  className={classes.checkbox}
                />
              </Tooltip>
            </MuiThemeProvider>
            <br />
            <CustomTextField
              variant="outlined"
              margin="dense"
              id="mail"
              label="新しいメールアドレス"
              value={newMail}
              name="newMail"
              autoComplete="mail"
              className={classes.length}
              onChange={changedNewMail}
              InputProps={{
                readOnly: dispMode === 'changeVerifi',
                inputProps: {
                  maxLength: 50,
                },
              }}
            />
            <br />
            <LoginIDInput
              title="ログインID（8桁～12桁）"
              margin="dense"
              value={loginData?.loginID ?? ''}
              onChange={changedLoginID}
              readonly={dispMode === 'changeVerifi'}
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={clickVerifi}
              hidden={dispMode !== 'change'}
            >
              内容変更
            </Button>
            <Box hidden={dispMode !== 'changeVerifi'}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={clickUpdate}
              >
                変更申込
              </Button>
              <MuiThemeProvider theme={theme1}>
                <Button
                  variant="contained"
                  className={classes.backButton}
                  onClick={clickChangeBack}
                >
                  戻る
                </Button>
              </MuiThemeProvider>
            </Box>
          </div>
          <Box>
            <MuiThemeProvider theme={theme1}>
              <Button
                variant="contained"
                className={classes.backButton}
                onClick={clickInit}
                hidden={!isSuccess}
              >
                戻る
              </Button>
            </MuiThemeProvider>
          </Box>
          <CommonDialog
            msg={confirmMessages}
            isOpen={commDlg}
            doYes={() => {
              setDispMode('changeVerifi');
              setIsHide(true);
              setCommDlg(false);
            }}
            doNo={() => {
              setCommDlg(false);
            }}
            yesWord="はい"
            noWord="いいえ"
          />
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default LoginInfoPage;
