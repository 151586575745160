import { ChangeEvent, FC } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Request } from 'types/RequestDataInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    length: {
      maxWidth: 730,
      margin: theme.spacing(2, 0, 6),
    },
  }),
);

export type ReviewPanelProps = {
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};

const ReviewPanel: FC<ReviewPanelProps> = ({ requestData, updateRequest }) => {
  const classes = useStyles();

  const changeComment = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    data.comment = event.target.value;
    if (updateRequest) updateRequest(data);
  };

  const changeReviewComment = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    data.reviewComment = event.target.value;
    if (updateRequest) updateRequest(data);
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        レビュー
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        id="review"
        label=""
        name="review"
        multiline
        className={classes.length}
        value={requestData?.reviewComment}
        onChange={changeReviewComment}
      />
      <Typography component="h1" variant="h5">
        備考
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        id="note"
        label=""
        name="note"
        multiline
        className={classes.length}
        value={requestData?.comment}
        onChange={changeComment}
      />
    </div>
  );
};

export default ReviewPanel;
