import {
  FC,
  ReactEventHandler,
  ChangeEvent,
  useRef,
  useState,
  SyntheticEvent,
  useContext,
} from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { UploadFile, FileInfo, FilesKey } from 'types/Files';
import { API_URL } from 'utils/CommonConst';
import MessagePanel from 'components/molecules/MessagePanel';
import { consoleLog, findFile, methodPut } from 'utils/CommonFunctions';
import { Request } from 'types/RequestDataInfo';
import { Box } from '@material-ui/core';
import { AppContext } from 'utils/AppContext';
import TextField from '@material-ui/core/TextField';

/* non eslint-disable  */
const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#2196f3',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: '#87cefa',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: 'darkgray',
      },
    },
    length: {
      margin: theme.spacing(1, 0, 4),
      minWidth: 400,
    },
    addSpace: {
      margin: theme.spacing(1, 3, 0),
    },
    cancelSpace: {
      margin: theme.spacing(3, 0, 0),
    },
    upload: {
      margin: theme.spacing(0, 8, 3),
      '&:hover': {
        background: '#1769aa',
      },
    },
    fileInput: {
      display: 'none',
    },
    dialogTitle: {
      fontSize: 24,
    },
  }),
);

export type FileUploadDialogProps = {
  open: boolean;
  dialogTitle?: string;
  onClickClose: ReactEventHandler<HTMLButtonElement>;
  fileType: number;
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};

const FileUploadDialog: FC<FileUploadDialogProps> = ({
  open,
  onClickClose,
  dialogTitle = '',
  fileType,
  requestData,
  updateRequest,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const refInput = useRef<HTMLInputElement>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [file, setFile] = useState<UploadFile>();
  const [fileName, setFileName] = useState<string>('');

  const addClick = () => {
    refInput?.current?.click();
  };

  const dialogClose = (e: SyntheticEvent<HTMLButtonElement>) => {
    setIsHide(true);
    setErrorMessages(['']);
    onClickClose(e);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileClick = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    e.target.value = '';
  };

  const saveFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (requestData) {
      if (e.target.files?.length !== 0 && e.target.files) {
        // ファイル名の文字数50文字以内
        if (e.target.files[0].name.length <= 50) {
          setFile({
            idCust: requestData.idCust,
            id: requestData.id,
            fileType,
            no: null,
            name: e.target.files[0]?.name,
            path: '',
            userID: appContext.state.LoginUser.isMaster
              ? appContext.state.LoginUser.idMaster ?? 0
              : appContext.state.LoginUser.idCust ?? 0,
            uploadDate: '',
            downloadCount: 0,
            billingDate: '',
            isEnable: fileType === 1,
            formFile: e.target.files[0],
          });
          setFileName(e.target.files[0]?.name);

          setIsHide(true);
          setErrorMessages(['']);
        } else {
          setIsHide(false);
          setErrorMessages([
            'ファイル名が長すぎます。',
            'ファイル名は50文字以内にしてください。',
          ]);
        }
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadFile = async (e: any) => {
    try {
      if (file && appContext.state.LoginUser.isLogin) {
        const formData = new FormData();
        formData.append('idCust', file.idCust.toString());
        formData.append('id', file.id ? file.id.toString() : '');
        formData.append('fileType', file.fileType.toString());
        formData.append('name', file.name);
        formData.append('userID', file.userID.toString());
        formData.append('downloadCount', '0');
        formData.append('billingDate', '');
        formData.append('isEnable', file.isEnable.toString());
        if (file.formFile) {
          formData.append('formFile', file.formFile);
        }
        const url = `${API_URL}api/v1/files`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodPut(
          url,
          formData,
          true,
          appContext.state.LoginUser,
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (res.status === 200) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = res.data as FileInfo;
          if (data.fileType === 1 && requestData) {
            const rdata = { ...requestData } as Request;
            const searchText = '\\Temporary\\';
            const isTemp = data.path.indexOf(searchText);
            const path1 = data.path.substring(
              data.path.indexOf(searchText) + searchText.length,
            );
            // const path2 = path1.substring(0, path1.indexOf('\\'));

            rdata.attachmentList.push({
              no: rdata.attachmentList.length + 1,
              name: data.name,
              fileType: data.fileType,
              path: isTemp === -1 ? '' : path1,
            });
            if (updateRequest) updateRequest(rdata);
          } else if (requestData) {
            const rdata = { ...requestData } as Request;
            rdata.documentList.push(data);
            if (updateRequest) updateRequest(rdata);
          }
          setFile(undefined);
          setFileName('');
          dialogClose(e);
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const confirmation = async (e: SyntheticEvent<HTMLButtonElement>) => {
    if (file) {
      if (file.id === 0) {
        const dupData = requestData?.attachmentList.find(
          (n) => n.name === file.name,
        );
        if (dupData) {
          setIsHide(false);
          setErrorMessages([
            '同名のファイルがアップロードされています。',
            'ファイル名を変更するか、ファイルを削除してから再度アップロードしてください。',
          ]);
        } else {
          uploadFile(e).finally(() => {
            /* Nothing to do. */
          });
        }
      } else {
        const fileKey: FilesKey = {
          idCust: file.idCust,
          id: file.id,
          fileType: file.fileType,
          no: file.no,
          name: file.name,
          path: file.path,
          billingDate: '',
          isEnable: null,
        };
        const filesKey: FilesKey[] = [];
        filesKey.push(fileKey);
        const fileInfo = await findFile(filesKey, appContext.state.LoginUser);
        if (fileInfo && fileInfo.length > 0) {
          setIsHide(false);
          setErrorMessages([
            '同名のファイルがアップロードされています。',
            'ファイル名を変更するか、ファイルを削除してから再度アップロードしてください。',
          ]);
        } else {
          uploadFile(e).finally(() => {
            /* Nothing to do. */
          });
        }
      }
    } else {
      setIsHide(false);
      setErrorMessages(['ファイルを選択してください。']);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={dialogClose}>
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>
            {dialogTitle}ファイル：アップロード
          </Box>
        </DialogTitle>
        <div className={classes.form}>
          <DialogContent>
            <MessagePanel messages={errorMessages} isHide={isHide} />
            <input
              ref={refInput}
              type="file"
              onChange={saveFile}
              onClick={fileClick}
              className={classes.fileInput}
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="fileName"
              value={fileName}
              name="fileName"
              className={classes.length}
              InputProps={{
                readOnly: true,
              }}
              multiline
            />
            <Button
              variant="contained"
              color="inherit"
              className={classes.addSpace}
              onClick={addClick}
            >
              選択
            </Button>
          </DialogContent>
          <MuiThemeProvider theme={theme2}>
            <DialogActions>
              <MuiThemeProvider theme={theme3}>
                <Button
                  variant="contained"
                  onClick={confirmation}
                  color="primary"
                  className={classes.upload}
                >
                  アップロード
                </Button>
              </MuiThemeProvider>
              <Button
                variant="contained"
                onClick={dialogClose}
                color="primary"
                className={classes.submit}
              >
                キャンセル
              </Button>
            </DialogActions>
          </MuiThemeProvider>
        </div>
      </Dialog>
    </div>
  );
};

export default FileUploadDialog;
