import { FC, Key, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './Panel.css';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { Table } from 'antd';
import { DestinationHistory, Request } from 'types/RequestDataInfo';
import { addressType } from 'utils/CommonConst';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    space: {
      margin: theme.spacing(1.5, 3, 2),
      '&:hover': {
        background: 'darkgreen',
      },
    },
  }),
);

export type HistoryPanelProps = {
  type?: number;
  history?: DestinationHistory[];
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};

const HistoryPanel: FC<HistoryPanelProps> = ({
  type = 1,
  history = [],
  requestData,
  updateRequest,
}) => {
  const classes = useStyles();

  const [selectedHistory, setSelectedHistory] = useState<DestinationHistory>();
  const [selectedRowKey, setSelectedRowKey] = useState<Key[]>([]);

  useEffect(() => {
    setSelectedRowKey([1]);
    setSelectedHistory(history[0]);
  }, [history]);

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      align: 'center' as const,
    },
    {
      title: '郵便番号',
      dataIndex: 'zip',
      key: 'zip',
      width: 100,
      align: 'center' as const,
    },
    {
      title: '住所',
      dataIndex: 'address1',
      key: 'address1',
      width: 250,
      align: 'left' as const,
    },
    {
      title: '建物等',
      dataIndex: 'address2',
      key: 'address2',
      width: 250,
      align: 'left' as const,
    },
  ];

  const numberColumns = [
    {
      title: '電話番号',
      dataIndex: 'tel',
      key: 'tel',
      width: 150,
      align: 'left' as const,
    },
    {
      title: 'FAX番号',
      dataIndex: 'fax',
      key: 'fax',
      width: 150,
      align: 'left' as const,
    },
    {
      title: '担当者',
      dataIndex: 'pic',
      key: 'pic',
      width: 150,
      align: 'left' as const,
    },
  ];

  const columnsNumber = [...columns, ...numberColumns];

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: DestinationHistory[]) => {
      setSelectedHistory(selectedRows[0]);
      setSelectedRowKey(selectedRowKeys);
    },
  };

  // 設定ボタン
  const setting = () => {
    if (selectedHistory) {
      const data = { ...requestData } as Request;
      switch (type) {
        case addressType.DestinationResult:
          data.sendName = selectedHistory.name;
          data.sendZipCode = selectedHistory.zip;
          data.sendAddress1 = selectedHistory.address1;
          data.sendAddress2 = selectedHistory.address2;
          data.sendTel = selectedHistory.tel;
          data.sendFax = selectedHistory.fax;
          data.sendPic = selectedHistory.pic;
          break;
        case addressType.DestinationInvoice:
          data.chargeSendName = selectedHistory.name;
          data.chargeSendZipCode = selectedHistory.zip;
          data.chargeSendAddress1 = selectedHistory.address1;
          data.chargeSendAddress2 = selectedHistory.address2;
          data.chargeSendPicTel = selectedHistory.tel;
          data.chargeSendPicFax = selectedHistory.fax;
          data.chargeSendPic = selectedHistory.pic;
          break;
        case addressType.InvoiceAddress:
          data.chargeName = selectedHistory.name;
          data.chargeZipCode = selectedHistory.zip;
          data.chargeAddress1 = selectedHistory.address1;
          data.chargeAddress2 = selectedHistory.address2;
          break;
        default:
          break;
      }

      if (updateRequest) updateRequest(data);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        履歴から選択
        <MuiThemeProvider theme={theme2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.space}
            onClick={setting}
          >
            履歴から設定
          </Button>
        </MuiThemeProvider>
      </Typography>
      <div className="table-size">
        <Table
          dataSource={history}
          columns={
            type !== addressType.InvoiceAddress ? columnsNumber : columns
          }
          pagination={{ pageSize: 20 }}
          rowSelection={{
            selectedRowKeys: selectedRowKey,
            type: 'radio',
            ...rowSelection,
          }}
          rowKey="id"
        />
      </div>
    </div>
  );
};

export default HistoryPanel;
