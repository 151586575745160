import { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { rootDir } from '../../utils/CommonConst';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#ebf6f7',
      backgroundColor: theme.palette.secondary.main,
      width: '100%',
      height: '50px',
      position: 'fixed',
      bottom: 0,
    },
    link: {
      textDecoration: 'none',
      color: '#ebf6f7',
    },
    grid: {
      height: '100%',
    },
    menu: {
      fontSize: '18px',
    },
    menu2: {
      fontSize: '18px',
      color: '#1a237e',
    },
  }),
);

const Warning = () => (
  <Typography variant="body2" color="inherit" align="center">
    システムメンテナンス時間：2：00～4：00
  </Typography>
);

const Copyright = () => (
  <Typography variant="body2" color="inherit" align="right">
    © 2021 Yamaguchi Health & Service Association .
  </Typography>
);

const Footer: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer} color="inherit">
      <Grid container className={classes.grid} alignItems="center">
        <Grid item xs={2}>
          <Grid container justifyContent="center">
            <a
              href={`${rootDir}Manual.pdf`}
              target="_blank"
              className={classes.link}
              rel="noreferrer"
            >
              <Box className={classes.menu}>マニュアル</Box>
            </a>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="center">
            <Link to={`${rootDir}inquiry`} className={classes.link}>
              <Box className={classes.menu}>お問い合わせ</Box>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="center">
            <a
              href={`${rootDir}terms.pdf`}
              target="_blank"
              className={classes.link}
              rel="noreferrer"
            >
              <Box className={classes.menu}>利用規約</Box>
            </a>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.grid}>
          <Grid
            container
            className={classes.grid}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Warning />
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.grid}>
          <Grid
            container
            className={classes.grid}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Copyright />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
