import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  consoleLog,
  requestCheckFunc,
  requestDeleteFunc,
  requestProcessFunc,
} from 'utils/CommonFunctions';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

const CofirmMailPage: FC = () => {
  const classes = useStyles();

  const { search } = useLocation();
  const query2 = new URLSearchParams(search);
  const requestID = query2.get('request') ?? '';
  const username = query2.get('username') ?? '';

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [mode, setMode] = useState<Mode>('info');
  const [isLoading, setIsLoading] = useState(true);

  // リクエストIDのチェック
  const requestIDCheck = useCallback(async () => {
    try {
      const check = await requestCheckFunc(requestID);
      if (!check) {
        setIsHide(false);
        setMode('error');
        setErrorMessages([
          'リクエストの有効期限が過ぎているか、不正なリクエストです。',
          '操作をやり直してください。',
        ]);
      } else {
        const res = await requestProcessFunc(requestID, username);
        setIsHide(false);
        if (res) {
          const del = await requestDeleteFunc(requestID);
          if (del) {
            setMode('info');
            setErrorMessages(['メールアドレスの認証が完了しました。']);
          }
        } else {
          setMode('error');
          setErrorMessages(['予期せぬエラーが発生しました。']);
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  }, [requestID, username]);

  useEffect(() => {
    requestIDCheck().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
  }, [requestIDCheck]);

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          {/* <div className={classes.form}>
            <Box textAlign="center">
              <Link to={`${rootDir}`}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  ログインへ
                </Button>
              </Link>
            </Box>
          </div> */}
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default CofirmMailPage;
