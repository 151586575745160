import { FC, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { StaffListModel, PicList } from 'types/User';
import { API_URL } from 'utils/CommonConst';
import { Box } from '@material-ui/core';
import { consoleLog, methodDelete } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'red',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      backgroundColor: '#ff9999',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    space: {
      margin: theme.spacing(3, 5, 0),
    },
    line: {
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: '#dc143c',
      },
    },
    dialogTitle: {
      fontSize: 24,
    },
    submit: {
      maxWidth: 110,
      margin: theme.spacing(0, 10, 3),
      '&:hover': {
        background: 'darkgray',
      },
    },
  }),
);

export type StaffDeleteDialogProps = {
  open: boolean;
  onClickClose: (isDeleted: boolean, insertData?: PicList) => void;
  row: StaffListModel | undefined;
  staffDelete: boolean;
};

const StaffDeleteDialog: FC<StaffDeleteDialogProps> = ({
  open,
  onClickClose,
  row,
  staffDelete,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const picID = row?.picID ?? 0;

  const deleteData = async () => {
    try {
      if (appContext.state.LoginUser) {
        const url = `${API_URL}api/v1/users/${
          row?.idCust ?? 0
        }/staffs/${picID}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodDelete(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        if (data) {
          onClickClose(true);
        }
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const handleClickDelete = () => {
    if (staffDelete) {
      deleteData().finally(() => {
        /* Nothing to do. */
      });
    } else {
      const data = { ...row } as PicList;
      onClickClose(true, data);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          onClickClose(false);
        }}
      >
        <DialogTitle className={classes.color}>
          <Box className={classes.dialogTitle}>担当者削除</Box>
        </DialogTitle>
        <DialogContentText className={classes.space}>
          以下の担当者を削除します。
        </DialogContentText>
        <DialogContent>
          <div className={classes.form}>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              id="name"
              label="担当者名"
              value={row?.name}
              name="name"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              id="section"
              label="所属"
              value={row?.section}
              name="section"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              id="mail"
              label="メールアドレス"
              value={row?.mail}
              name="mail"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              id="tel"
              label="電話番号"
              value={row?.tel}
              name="tel"
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
        </DialogContent>
        <MuiThemeProvider theme={theme2}>
          <DialogActions>
            <MuiThemeProvider theme={theme3}>
              <Button
                variant="contained"
                onClick={handleClickDelete}
                color="primary"
                className={classes.line}
              >
                削除
              </Button>
            </MuiThemeProvider>
            <Button
              variant="contained"
              onClick={() => {
                onClickClose(false);
              }}
              color="primary"
              className={classes.submit}
            >
              キャンセル
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>
    </>
  );
};

export default StaffDeleteDialog;
