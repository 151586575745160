import { ChangeEventHandler, FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { debugLog, loginIDCheckFunc } from 'utils/CommonFunctions';
import { PropTypes } from '@material-ui/core';
import CustomTextField from './CustomTextField';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(1.3, 2, 0.5),
      '&:hover': {
        background: 'green',
      },
    },
    message: {
      color: 'red',
    },
  }),
);

/* non eslint-disable */
export type LoginIDInputProps = {
  title?: string;
  margin?: PropTypes.Margin | undefined;
  value: string;
  readonly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const LoginIDInput: FC<LoginIDInputProps> = ({
  title = '',
  margin = 'normal',
  value,
  readonly = false,
  onChange,
}) => {
  const classes = useStyles();

  const [message, setMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const click = async () => {
    const res = await loginIDCheckFunc(value);
    if (res) {
      setIsError(false);
      setMessage('ログインIDに重複はありません。');
      // eslint-disable-next-line
      debugLog('ログインIDに重複はありません。');
    } else if (res !== null) {
      setIsError(true);
      setMessage('ログインIDが重複しています。');
      // eslint-disable-next-line
      debugLog('ログインIDが重複しています。');
    } else {
      setIsError(true);
      setMessage('予期せぬエラーが発生しました。');
    }
  };

  return (
    <>
      <CustomTextField
        variant="outlined"
        required
        id="loginID"
        label={title}
        name="loginID"
        autoComplete="loginID"
        margin={margin}
        value={value}
        InputProps={{
          readOnly: readonly,
          inputProps: {
            maxLength: 12,
          },
        }}
        onChange={onChange}
      />
      <span hidden={readonly}>
        <MuiThemeProvider theme={theme2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={click}
            disabled={value === '' || value.length < 8}
          >
            重複確認
          </Button>
          <span className={isError ? classes.message : ''}>{message}</span>
        </MuiThemeProvider>
      </span>
    </>
  );
};

export default LoginIDInput;
