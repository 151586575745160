import { FC, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { rootDir } from 'utils/CommonConst';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    toTop: {
      margin: theme.spacing(3, 0, 2),
    },
    toBack: {
      margin: theme.spacing(3, 0, 2, 4),
    },
  }),
);

/* non eslint-disable  */
const FinishPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState<string>();
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>('info');
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { title, message, from } = (location.state as {
    title: string;
    message: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    from: any;
  }) || {
    title: '',
    message: '',
    from: null,
  };

  useEffect(() => {
    setPageTitle(title);
    setErrorMessages(message);
    setIsHide(false);
    setIsSuccess(true);
    setMode('info');
  }, [title, message]);

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            {pageTitle}
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div hidden={!isSuccess}>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.toTop}
                hidden={!isSuccess}
                onClick={() => {
                  navigate(`${rootDir}top`);
                }}
              >
                トップページヘ
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.toBack}
                // eslint-disable-next-line
                onClick={() => navigate(`${from.pathname}`)}
              >
                戻る
              </Button>
            </Box>
          </div>
        </div>
      </Container>
    </HeaderTemplate>
  );
};

export default FinishPage;
