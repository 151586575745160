import { FC } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    let: {
      backgroundColor: '#1e90ff',
      color: '#FFF',
      margin: 10,
      padding: 10,
    },
  }),
);

const InquiryPage: FC = () => {
  const classes = useStyles();

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Card className={classes.let}>
            <CardHeader
              title="システムに関するお問い合わせ"
              titleTypographyProps={{ variant: 'h4' }}
            />
            <CardContent>
              <Box component="h2">電話番号：083-941-6300</Box>
              <Box>音声ガイダンスにしたがって[1]を選択してください。</Box>
              <br />
              <Box>【受付時間】 8:30～17:00</Box>
              <Box>（土・日・祝、ならびに弊社の定める休業日を除く平日）</Box>
              <br />
              <Box>「e-Yobouについて」とお問い合わせ頂くとスムーズです。</Box>
            </CardContent>
          </Card>
        </div>
      </Container>
    </HeaderTemplate>
  );
};

export default InquiryPage;
