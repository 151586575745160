import {
  FC,
  useState,
  MouseEvent,
  useEffect,
  ChangeEvent,
  useContext,
} from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BasicInfoPanel from 'components/molecules/BasicInfoPanel';
import BusinessContentPanel from 'components/molecules/BusinessContentPanel';
import AttachmentPanel from 'components/molecules/AttachmentPanel';
import NotesPanel from 'components/molecules/NotesPanel';
import ReviewPanel from 'components/molecules/ReviewPanel';
import { BillingInfo, StaffListModel, User } from 'types/User';
import { API_URL, mailSendType, rootDir } from 'utils/CommonConst';
import { ErrorInfo, Request } from 'types/RequestDataInfo';
import { RecieptType } from 'types/RecieptType';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import CommonDialog from 'components/molecules/CommonDialog';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import {
  checkDate,
  checkDate2,
  checkDate3,
  consoleLog,
  createRequestForm,
  debugLog,
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
} from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import CommonDialog2 from 'components/molecules/CommonDialog2';
import HeaderTemplate from '../templates/HeaderTemplate';

const theme1 = createTheme({
  overrides: {
    MuiTab: {
      root: {
        backgroundColor: 'lightgray',
        fontSize: 18,
        '&:hover': {
          backgroundColor: 'ligthblue',
          color: 'orange',
          opacity: 1,
        },
        '&$selected': {
          backgroundColor: '#fdfdfd',
          color: 'black',
          fontWeight: 'bold',
        },
      },
    },
  },
});

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});

const theme3 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'red',
      },
    },
  },
});

const theme4 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
    },
    output: {
      margin: theme.spacing(0, 3, 2),
      '&:hover': {
        background: 'darkgreen',
      },
    },
    application: {
      margin: theme.spacing(0, 3, 2),
    },
    application2: {
      margin: theme.spacing(0, 0, 2),
    },
    duplicate: {
      margin: theme.spacing(0, 3, 2),
      '&:hover': {
        background: 'darkorange',
      },
    },
    withdrawal: {
      margin: theme.spacing(0, 0, 2, 3),
      '&:hover': {
        background: '#dc143c',
      },
    },
    tab: {
      color: '#0000ff',
    },
    dspIdReciept: {
      marginRight: 50,
    },
    delText: {
      margin: theme.spacing(0, 0, 2),
      color: 'red',
    },
    label: {
      fontSize: '18px',
    },
    errorLabel: {
      fontSize: '18px',
      backgroundColor: 'red',
    },
    deleteBack: {
      textAlign: 'right',
    },
  }),
);

/* not eslint-disable */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TabPanel = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      id={`simple-tabpanel-${index}`}
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const RequestInfoPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const location = useLocation();
  const { id, idCust, recieptType } = (location.state as {
    id: number | null;
    idCust: number | null;
    recieptType: number | null;
  }) || {
    id: 0,
    idCust: 0,
    recieptType: 0,
  };
  const { search } = useLocation();
  const query2 = new URLSearchParams(search);
  const queryid = appContext.state.LoginUser.isMaster
    ? query2.get('reciepid') ?? ''
    : '';
  const queryidCust = appContext.state.LoginUser.isMaster
    ? query2.get('queryidCust') ?? ''
    : '';
  const reciepid = id === 0 ? Number(queryid) : id ?? 0;
  const reciepidCust = idCust === 0 ? Number(queryidCust) : idCust ?? 0;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl);
  const [requestData, setRequestData] = useState<Request>();
  const [userData, setUserData] = useState<User>();
  const [billingData, setBillingData] = useState<BillingInfo>();
  const [recieptTypes, setRecieptTypes] = useState<RecieptType[] | null>(null);
  const [staffsData, setStaffsData] = useState<StaffListModel[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [mode, setMode] = useState<Mode>('info');
  const [commDlg, setCommDlg] = useState(false);
  const [commDlg2, setCommDlg2] = useState(false);
  const [commDlg3, setCommDlg3] = useState(false);
  const [commDlg4, setCommDlg4] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [commDlgMode, setCommDlgMode] = useState<number>(1); // 1:依頼取下、2:依頼申込、3:依頼更新、4:依頼完了
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isHideContent, setIsHideContent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAgree, setIsAgree] = useState(false);
  const [isError, setIsError] = useState<ErrorInfo>();
  const [isWarning, setIsWarning] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isConsentEn, setIsConsentEn] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const agreeText = (
    <span>
      <a
        href="http://www.yobou.or.jp/shokuhin/%e3%81%94%e4%be%9d%e9%a0%bc%e3%81%ab%e9%96%a2%e3%81%99%e3%82%8b%e6%b3%a8%e6%84%8f%e4%ba%8b%e9%a0%85%e7%ad%89/"
        target="_blank"
        rel="noreferrer"
      >
        依頼に関する注意事項
      </a>
      を確認しました。
    </span>
  );

  const onChangeIsAgree = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAgree(event.target.checked);
  };

  const onChangeIsMail = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    data.isMail = event.target.checked;
    setRequestData(data);
  };

  const displayContent = () => {
    const errorInfo: ErrorInfo = {};
    setIsError(errorInfo);
    setIsHide(true);
    setIsHideContent(false);
    setIsDeleted(false);
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const url = `${API_URL}api/v1/users/${reciepidCust}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as User;
        setUserData(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };

    const getbillingData = async () => {
      try {
        const url = `${API_URL}api/v1/users/${reciepidCust}/charge`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as BillingInfo;
        setBillingData(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    const getPicData = async () => {
      try {
        const url = `${API_URL}api/v1/users/${reciepidCust}/staffs`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as StaffListModel[];
        const data2 = data.filter((n) => n.isEnable === true);
        setStaffsData(data2);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    // 検査区分マスタの取得・設定
    const getRecieptType = async () => {
      try {
        const url = `${API_URL}api/v1/masters/recieptttype`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as RecieptType[];
        setRecieptTypes(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    if (appContext.state.LoginUser.isLogin) {
      getUserData().finally(() => {
        /* Nothing to do. */
      });
      getbillingData().finally(() => {
        /* Nothing to do. */
      });
      getRecieptType().finally(() => {
        /* Nothing to do. */
      });
      getPicData().finally(() => {
        /* Nothing to do. */
      });
    }
  }, [reciepidCust, appContext.state.LoginUser]);

  useEffect(() => {
    const getRequestData = async () => {
      try {
        const url = `${API_URL}api/v1/requests/${reciepid}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as Request;
        setRequestData(data);
        debugLog(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };
    setIsHide(true);
    setIsHideContent(false);
    if (reciepid !== 0 && appContext.state.LoginUser.isLogin) {
      getRequestData().finally(() => {
        /* Nothing to do. */
        setIsLoading(false);
      });
    }
  }, [reciepid, appContext.state.LoginUser]);

  useEffect(() => {
    if (
      reciepid === 0 &&
      recieptTypes &&
      userData &&
      recieptType &&
      reciepidCust
    ) {
      // 依頼初期化
      const data = { ...requestData } as Request;
      data.id = 0;
      data.idCust = reciepidCust;
      data.recieptType1 = recieptType;
      data.custData = userData.custData;
      data.custName = userData.custName;
      data.no = 0;
      data.idReciept = 0;
      data.dspIdReciept = '';
      data.rcptCreate = '';
      const type = recieptTypes.find((n) => n.id === recieptType);
      if (type != null) {
        data.dspRecieptType1 = type.menuTitle;
      }
      data.namePublishForJa = true;
      data.namePublishForEn = false;
      data.namePublishForNum = 1;
      data.sendNameFlgSameRcpt = true;
      data.namePublishFor1 = userData.custFullName;
      data.namePublishFor2 = '';
      data.namePublishFor3 = '';
      data.namePublishFor1En = '';
      data.namePublishFor2En = '';
      data.namePublishFor3En = '';
      data.chargeFlgSameRcpt = true;
      data.chargeName = userData.custFullName;
      data.chargeZipCode = userData.zip;
      data.chargeAddress1 = userData.address1;
      data.chargeAddress2 = userData.address2;
      data.sendReportType = 0;
      data.sendReportNum = null;
      data.sendReportNumEn = null;
      data.sendFlgSameRcpt = true;
      data.sendName = userData.custFullName;
      data.sendZipCode = userData.zip;
      data.sendAddress1 = userData.address1;
      data.sendAddress2 = userData.address2;
      data.sendTel = userData.tel;
      data.sendFax = userData.fax;
      data.chargeMethod = 0;
      data.chargeReportType = 0;
      data.chargeSendOption = 0;
      data.chargeSendName = userData.custFullName;
      data.chargeSendZipCode = userData.zip;
      data.chargeSendAddress1 = userData.address1;
      data.chargeSendAddress2 = userData.address2;
      data.chargeSendPicTel = userData.tel;
      data.chargeSendPicFax = userData.fax;
      data.isPreliminaryReport = true;
      data.preliminaryReportType = 0;
      data.estimatedShippingDate = '';
      data.expectedArrivalDate = '';
      data.deadLine = '';
      data.budget = '';
      data.commentUser = '';
      data.comment = '';
      data.reviewComment = '';
      data.process = '依頼申込';
      data.idProcess = 1;
      data.picList = [];
      data.sendPICList = [];
      data.chargePICList = [];
      data.sampleList = [];
      data.attachmentList = [];
      data.taskList = [];
      data.isMail = false;
      data.isConsentEn = false;

      setRequestData(data);

      displayContent();

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reciepid, recieptTypes, userData, recieptType, reciepidCust]);

  useEffect(() => {
    if (!isHideContent) {
      setIsAgree(false);
    }
  }, [isHideContent]);

  const textRequestUpdate =
    '依頼の受付後は依頼変更できません。 ¥n 個別にお問い合わせください。';
  const breakedTextRequestUpdate = textRequestUpdate
    .split('¥n')
    .map((line, key) => (
      // eslint-disable-next-line
      <span key={`1-${key}`} style={{ fontSize: '15px' }}>
        {line}
        <br />
      </span>
    ));

  const textRequestDelete =
    '依頼の受付後は依頼取下できません。 ¥n 個別にお問い合わせください。';
  const breakedTextRequestDelete = textRequestDelete
    .split('¥n')
    .map((line, key) => (
      // eslint-disable-next-line
      <span key={`2-${key}`} style={{ fontSize: '15px' }}>
        {line}
        <br />
      </span>
    ));

  // 依頼書出力
  const OutputForm = async (id1: number) => {
    await createRequestForm(id1, appContext.state.LoginUser);
  };

  const outputForm = () => {
    if (requestData?.id) {
      setButtonDisabled(true);
      OutputForm(requestData?.id).finally(() => {
        /* Nothing to do. */
        setCommDlg2(false);
        setButtonDisabled(false);
      });
    }
  };

  const arrivalDateCheck = (prodMode: number) => {
    const text = `到着予定日が過去の日付です。¥nよろしいですか？`;
    const breakedText = text.split('¥n').map((line) => (
      <span key={line}>
        {line}
        <br />
      </span>
    ));

    setConfirmMessages(breakedText);

    setCommDlgMode(prodMode);
    setCommDlg(true);
  };
  /* not eslint-disable */
  const isEnglishCheck = (warning: boolean, errPattern: number) => {
    if (requestData) {
      if (errPattern === 1) {
        // 結果書宛名が英文で、試験品の英文が無いパターン
        const data = requestData.taskList.filter((n) => n.isEnglish === false);
        const mes = data.map((n) => {
          if (n.sortNo) {
            return n.sortNo.toString();
          }

          return n.idTask.toString();
        });

        const text = `結果書宛名に英文がありますが、¥n試験品の${mes.join(
          '、',
        )}番目が英文未入力のため、¥n英文は発行されません。よろしいですか？`;
        const breakedText = text.split('¥n').map((line) => (
          <span key={line}>
            {line}
            <br />
          </span>
        ));

        setConfirmMessages(breakedText);
      } else {
        // 試験品が英文で、結果書宛名が英文無いパターン
        const data = requestData.taskList.filter((n) => n.isEnglish === true);
        const mes = data.map((n) => {
          if (n.sortNo) {
            return n.sortNo.toString();
          }

          return n.idTask.toString();
        });

        const text = `試験品の${mes.join(
          '、',
        )}番目が英文ですが、¥n結果書宛名が英文未入力のため、¥n英文は発行されません。よろしいですか？`;
        const breakedText = text.split('¥n').map((line) => (
          <span key={line}>
            {line}
            <br />
          </span>
        ));

        setConfirmMessages(breakedText);
      }
      setIsWarning(warning);
      setCommDlg4(true);
    }
  };

  const createDataDraft = async () => {
    setIsHide(true);
    setIsLoading(true);
    try {
      const url = `${API_URL}api/v1/requests/draft`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(
        url,
        requestData,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const recieptID = res.data as number;
      if (recieptID !== 0) {
        setMode('info');
        setIsHide(false);
        setErrorMessages(['依頼情報を下書き保存しました。']);
        const data = { ...requestData } as Request;
        data.id = recieptID;
        data.process = '下書き（依頼前）';
        data.idProcess = 50;
        setRequestData(data);
        setIsHideContent(true);
      } else {
        setMode('error');
        setIsHide(false);
        setErrorMessages(['依頼情報の下書き保存に失敗しました。']);
      }
    } catch (ex) {
      consoleLog(ex);
    } finally {
      setCommDlg(false);
      setIsLoading(false);
      setIsConsentEn(null);
    }
  };

  const createData = async (consentFlag = false) => {
    setButtonDisabled(true);
    setIsHide(true);
    setIsLoading(true);
    try {
      const sendData = { ...requestData } as Request;
      if (consentFlag) {
        sendData.isConsentEn = consentFlag;
      } else {
        sendData.isConsentEn = isConsentEn ?? false;
      }
      if (sendData.chargeMethod === 1) {
        // 1: 請求方法＝月次
        const pic = sendData.picList.map((apic) => {
          const target = { ...apic };
          target.picType = 3;

          return target;
        });
        sendData.chargePICList = pic;
      }
      const url = `${API_URL}api/v1/requests`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(
        url,
        sendData,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const recieptID = res.data as number;
      if (recieptID !== 0) {
        if (!appContext.state.LoginUser.isMaster) {
          const text = `依頼情報を登録しました。¥n依頼書をPDFで出力します。¥nダウンロードされる依頼書を送付してください。`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));

          setConfirmMessages(breakedText);
          setCommDlg2(true);
        }

        setMode('info');
        setIsHide(false);
        setErrorMessages(['依頼情報を登録しました。']);
        const data = { ...requestData } as Request;
        data.id = recieptID;
        data.process = 'Web依頼申込';
        data.idProcess = 100;
        setRequestData(data);
        setIsHideContent(true);
      } else {
        setMode('error');
        setIsHide(false);
        setErrorMessages(['依頼情報の登録に失敗しました。']);
      }
    } catch (ex) {
      consoleLog(ex);
    } finally {
      setCommDlg(false);
      setIsLoading(false);
      setButtonDisabled(false);
      setIsConsentEn(null);
    }
  };

  const updateData = async (consentFlag = false) => {
    setButtonDisabled(true);
    setIsHide(true);
    setIsLoading(true);
    try {
      const sendData = { ...requestData } as Request;
      if (consentFlag) {
        sendData.isConsentEn = consentFlag;
      } else {
        sendData.isConsentEn = isConsentEn ?? false;
      }
      if (sendData.chargeMethod === 1) {
        // 1: 請求方法＝月次
        const pic = sendData.picList.map((apic) => {
          const target = { ...apic };
          target.picType = 3;

          return target;
        });
        sendData.chargePICList = pic;
      }
      const url = `${API_URL}api/v1/requests`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPut(
        url,
        sendData,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const data = res.data as boolean;
      if (requestData?.process === '下書き（依頼前）') {
        if (data) {
          if (!appContext.state.LoginUser.isMaster) {
            const text = `依頼情報を登録しました。¥n依頼書をPDFで出力します。¥nダウンロードされる依頼書を送付してください。`;
            const breakedText = text.split('¥n').map((line) => (
              <span key={line}>
                {line}
                <br />
              </span>
            ));

            setConfirmMessages(breakedText);
            setCommDlg2(true);
          }

          setMode('info');
          setIsHide(false);
          setErrorMessages(['依頼情報を登録しました。']);
          const data2 = { ...requestData } as Request;
          data2.process = 'Web依頼申込';
          data2.idProcess = 100;
          setRequestData(data2);
          setIsHideContent(true);
        } else {
          setMode('error');
          setIsHide(false);
          setErrorMessages(['依頼情報の登録に失敗しました。']);
        }
      } else if (data) {
        setMode('info');
        setIsHide(false);
        setErrorMessages(['依頼情報を更新しました。']);
      } else {
        setMode('error');
        setIsHide(false);
        setErrorMessages(['依頼情報の更新に失敗しました。']);
      }
    } catch (ex) {
      consoleLog(ex);
    } finally {
      setCommDlg(false);
      setIsLoading(false);
      setButtonDisabled(false);
      setIsConsentEn(null);
    }
  };

  const updateDataDraft = async () => {
    setIsHide(true);
    setIsLoading(true);
    try {
      const url = `${API_URL}api/v1/requests/draft`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPut(
        url,
        requestData,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const data = res.data as boolean;
      if (data) {
        setMode('info');
        setIsHide(false);
        const errorInfo: ErrorInfo = {};
        setIsError(errorInfo);
        setErrorMessages(['依頼情報（下書き）を更新しました。']);
      } else {
        setMode('error');
        setIsHide(false);
        setErrorMessages(['依頼情報（下書き）の更新に失敗しました。']);
      }
    } catch (ex) {
      consoleLog(ex);
    } finally {
      setCommDlg(false);
      setIsLoading(false);
      setIsConsentEn(null);
    }
  };

  // 到着日チェック、依頼、更新処理
  const prod = (warning: boolean, consentFlag = false) => {
    if (requestData) {
      if (requestData.id === 0) {
        if (warning) {
          arrivalDateCheck(2);
        } else {
          createData(consentFlag).finally(() => {
            /* Nothing to do. */
          });
        }
      } else if (warning) {
        arrivalDateCheck(3);
      } else {
        updateData(consentFlag).finally(() => {
          /* Nothing to do. */
        });
      }
    }
    setCommDlg4(false);
  };

  // 依頼申込ボタン
  const confirmation = () => {
    // 入力チェック
    if (requestData) {
      let error = false;
      let warning = false;
      let warning2 = false;
      let warning3 = false;
      const errorInfo: ErrorInfo = {};
      const message: string[] = [];
      // ①基本情報
      if (
        (requestData.namePublishForJa && !requestData.namePublishFor1) ||
        (requestData.namePublishForEn && !requestData.namePublishFor1En)
      ) {
        message.push('【①基本情報】結果書に記載される宛名を入力してください。');
        errorInfo.error1 = true;
        error = true;
      }
      if (!requestData.chargeFlgSameRcpt && !requestData.chargeName) {
        message.push('【①基本情報】請求書に記載される宛名を入力してください。');
        errorInfo.error2 = true;
        error = true;
      }
      const existsPic = requestData.picList.find(
        (n) => n.mailSendType === mailSendType.To,
      );
      if (!existsPic) {
        message.push('【①基本情報】依頼担当者を入力してください。');
        errorInfo.error3 = true;
        error = true;
      }
      const existsSendPic = requestData.sendPICList.find(
        (n) => n.mailSendType === mailSendType.To,
      );
      if (!existsSendPic) {
        message.push('【①基本情報】結果書担当者を入力してください。');
        errorInfo.error4 = true;
        error = true;
      }
      const existsChargePic = requestData.chargePICList.find(
        (n) => n.mailSendType === mailSendType.To,
      );
      if (requestData.chargeMethod !== 1 && !existsChargePic) {
        message.push('【①基本情報】請求書担当者を入力してください。');
        errorInfo.error5 = true;
        error = true;
      }
      if (requestData.sendReportType === 1) {
        if (!requestData.namePublishForEn) {
          if (!requestData.sendReportNum) {
            message.push(
              '【①基本情報】結果書引渡方法が郵送の場合、部数を入力してください。',
            );
            errorInfo.error6 = true;
            error = true;
          } else if (
            requestData.sendReportNum < 1 ||
            requestData.sendReportNum > 3
          ) {
            message.push(
              '【①基本情報】結果書の郵送部数は1～3を入力してください。',
            );
            errorInfo.error6 = true;
            error = true;
          }
        } else if (!requestData.sendReportNumEn) {
          message.push(
            '【①基本情報】結果書引渡方法が郵送かつ結果書宛名の英文有の場合、英文の部数を入力してください。',
          );
          errorInfo.error6 = true;
          error = true;
        } else if (
          (requestData.sendReportNum ?? 0) + requestData.sendReportNumEn < 1 ||
          (requestData.sendReportNum ?? 0) + requestData.sendReportNumEn > 3
        ) {
          message.push(
            '【①基本情報】結果書の郵送部数は和文と英文で合計3部までで入力してください。',
          );
          errorInfo.error6 = true;
          error = true;
        }

        if (!requestData.sendNameFlgSameRcpt && !requestData.sendName) {
          message.push('【①基本情報】結果書送付先情報を入力してください。');
          errorInfo.error6 = true;
          error = true;
        }
        if (!requestData.sendNameFlgSameRcpt && !requestData.sendPic) {
          message.push(
            '【①基本情報】結果書送付先情報の担当者を入力してください。',
          );
          errorInfo.error6 = true;
          error = true;
        }
      }
      if (
        requestData.chargeReportType === 1 &&
        requestData.chargeSendOption === 2 &&
        !requestData.chargeSendName
      ) {
        message.push('【①基本情報】請求書送付先情報を入力してください。');
        errorInfo.error7 = true;
        error = true;
      }
      if (requestData.chargeReportType === 1 && !requestData.chargeSendPic) {
        message.push(
          '【①基本情報】請求書送付先情報の担当者を入力してください。',
        );
        errorInfo.error7 = true;
        error = true;
      }

      if (
        requestData.chargeMethod === 0 &&
        requestData.chargeReportType === 1 &&
        requestData.chargeSendOption === 1
      ) {
        message.push(
          '【①基本情報】請求方法：依頼毎、引渡方法：郵送の場合、請求書送付先情報に「月次請求情報と同じ」は選択できません。',
        );
        errorInfo.error8 = true;
        error = true;
      }

      if (
        requestData.chargeMethod === 1 &&
        requestData.chargeReportType === 1 &&
        requestData.chargeSendOption === 0
      ) {
        message.push(
          '【①基本情報】請求方法：月次、引渡方法：郵送の場合、請求書送付先情報に「依頼者と同じ」は選択できません。',
        );
        errorInfo.error8 = true;
        error = true;
      }

      // ②依頼内容
      if (!requestData.estimatedShippingDate) {
        message.push('【②依頼内容】発送予定日を入力してください。');
        errorInfo.error9 = true;
        error = true;
      }
      if (!requestData.expectedArrivalDate) {
        message.push('【②依頼内容】到着予定日を入力してください。');
        errorInfo.error10 = true;
        error = true;
      }
      if (
        requestData.estimatedShippingDate &&
        !checkDate(requestData.estimatedShippingDate.replaceAll('-', '/'))
      ) {
        message.push('【②依頼内容】発送予定日の日付が異常です。');
        errorInfo.error9 = true;
        error = true;
      }
      if (
        requestData.expectedArrivalDate &&
        !checkDate(requestData.expectedArrivalDate.replaceAll('-', '/'))
      ) {
        message.push('【②依頼内容】到着予定日の日付が異常です。');
        errorInfo.error10 = true;
        error = true;
      } else if (
        requestData.expectedArrivalDate &&
        !checkDate3(requestData.expectedArrivalDate.replaceAll('-', '/'))
      ) {
        warning = true;
      }
      if (
        requestData.estimatedShippingDate &&
        requestData.expectedArrivalDate &&
        !checkDate2(
          requestData.estimatedShippingDate.replaceAll('-', '/'),
          requestData.expectedArrivalDate.replaceAll('-', '/'),
        )
      ) {
        message.push(
          '【②依頼内容】到着予定日は発送予定日以降に設定してください。',
        );
        errorInfo.error10 = true;
        error = true;
      }
      if (requestData.taskList.length === 0) {
        message.push('【②依頼内容】依頼内容を入力してください。');
        errorInfo.error11 = true;
        error = true;
      }
      if (requestData.taskList.find((n) => n.taskSampleList.length === 0)) {
        message.push(
          '【②依頼内容】検査項目が入力されていない試験品があります。入力してください。',
        );
        errorInfo.error13 = true;
        error = true;
      }
      if (
        (requestData.id === 0 || requestData?.process === '下書き（依頼前）') &&
        !isAgree &&
        !appContext.state.LoginUser.isMaster
      ) {
        message.push('注意事項を確認してください。');
        errorInfo.error12 = true;
        error = true;
      }
      if (requestData.namePublishForEn) {
        const warningData = requestData.taskList.filter(
          (n) => n.isEnglish === false,
        );
        if (warningData.length > 0) {
          warning2 = true;
        }
      } else {
        const warningData = requestData.taskList.filter(
          (n) => n.isEnglish === true,
        );
        if (warningData.length > 0) {
          warning3 = true;
        }
      }
      setIsError(errorInfo);
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);

        return;
      }
      setIsHide(true);

      if (warning2) {
        isEnglishCheck(warning, 1);
      } else if (warning3) {
        isEnglishCheck(warning, 2);
      } else {
        setIsConsentEn(false);
        prod(warning, false);
      }
    }
  };

  // 削除ボタン
  const clickDelete = () => {
    setIsHide(true);
    if (requestData?.process === '下書き（依頼前）') {
      const text = `依頼を取り下げます（依頼情報は削除されます）。¥nよろしいですか？`;
      const breakedText = text.split('¥n').map((line) => (
        <span key={line}>
          {line}
          <br />
        </span>
      ));

      setConfirmMessages(breakedText);
      setCommDlgMode(1);
      setCommDlg(true);
    } else {
      const text = `依頼を取り下げます。¥nよろしいですか？`;
      const breakedText = text.split('¥n').map((line) => (
        <span key={line}>
          {line}
          <br />
        </span>
      ));

      setConfirmMessages(breakedText);
      setCommDlg3(true);
    }
  };

  // 削除処理
  const deleteProc = () => {
    const deleteRequestData = async () => {
      try {
        const url = `${API_URL}api/v1/requests/${requestData?.id ?? 0}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodDelete(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        if (data) {
          setMode('info');
          setIsHide(false);
          setErrorMessages(['依頼を取下げました。']);
          setIsDeleted(true);
        } else {
          setMode('error');
          setIsHide(false);
          setErrorMessages(['依頼の取下に失敗しました。']);
        }

        debugLog(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };

    setButtonDisabled(true);
    deleteRequestData().finally(() => {
      /* Nothing to do. */
      setCommDlg(false);
      setCommDlg3(false);
      setButtonDisabled(false);
    });
  };

  // 削除処理
  const revertToDraftProc = () => {
    const revertToDraftData = async () => {
      try {
        const url = `${API_URL}api/v1/requests/reverttodraft/${
          requestData?.id ?? 0
        }`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodDelete(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as boolean;
        if (data) {
          setMode('info');
          setIsHide(false);
          setErrorMessages(['依頼を取下げ、下書きに戻しました。']);
          const data2 = { ...requestData } as Request;
          data2.process = '下書き（依頼前）';
          data2.idProcess = 50;
          setRequestData(data2);
        } else {
          setMode('error');
          setIsHide(false);
          setErrorMessages(['依頼の取下に失敗しました。']);
        }

        debugLog(data);
      } catch (ex) {
        consoleLog(ex);
      }
    };

    setButtonDisabled(true);
    revertToDraftData().finally(() => {
      /* Nothing to do. */
      setCommDlg3(false);
      setButtonDisabled(false);
    });
  };

  // 基本情報チェック
  const confirmationBasic = (): boolean => {
    // 入力チェック
    if (requestData) {
      let error = false;
      const errorInfo: ErrorInfo = {};
      const message: string[] = [];
      // ①基本情報
      if (
        (requestData.namePublishForJa && !requestData.namePublishFor1) ||
        (requestData.namePublishForEn && !requestData.namePublishFor1En)
      ) {
        message.push('【①基本情報】結果書に記載される宛名を入力してください。');
        errorInfo.error1 = true;
        error = true;
      }
      if (!requestData.chargeFlgSameRcpt && !requestData.chargeName) {
        message.push('【①基本情報】請求書に記載される宛名を入力してください。');
        errorInfo.error2 = true;
        error = true;
      }
      const existsPic = requestData.picList.find(
        (n) => n.mailSendType === mailSendType.To,
      );
      if (!existsPic) {
        message.push('【①基本情報】依頼担当者を入力してください。');
        errorInfo.error3 = true;
        error = true;
      }
      const existsSendPic = requestData.sendPICList.find(
        (n) => n.mailSendType === mailSendType.To,
      );
      if (!existsSendPic) {
        message.push('【①基本情報】結果書担当者を入力してください。');
        errorInfo.error4 = true;
        error = true;
      }
      const existsChargePic = requestData.chargePICList.find(
        (n) => n.mailSendType === mailSendType.To,
      );
      if (requestData.chargeMethod !== 1 && !existsChargePic) {
        message.push('【①基本情報】請求書担当者を入力してください。');
        errorInfo.error5 = true;
        error = true;
      }
      if (requestData.sendReportType === 1) {
        if (!requestData.sendReportNum) {
          message.push(
            '【①基本情報】結果書引渡方法が郵送の場合、部数を入力してください。',
          );
          errorInfo.error6 = true;
          error = true;
        } else if (
          requestData.sendReportNum < 1 ||
          requestData.sendReportNum > 3
        ) {
          message.push(
            '【①基本情報】結果書の郵送部数は1～3を入力してください。',
          );
          errorInfo.error6 = true;
          error = true;
        }
        if (!requestData.sendNameFlgSameRcpt && !requestData.sendName) {
          message.push('【①基本情報】結果書送付先情報を入力してください。');
          errorInfo.error6 = true;
          error = true;
        }
      }
      if (
        requestData.chargeReportType === 1 &&
        requestData.chargeSendOption === 2 &&
        !requestData.chargeSendName
      ) {
        message.push('【①基本情報】請求書送付先情報を入力してください。');
        errorInfo.error7 = true;
        error = true;
      }

      if (
        requestData.chargeMethod === 0 &&
        requestData.chargeReportType === 1 &&
        requestData.chargeSendOption === 1
      ) {
        message.push(
          '【①基本情報】請求方法：依頼毎、引渡方法：郵送の場合、請求書送付先情報に「月次請求情報と同じ」は選択できません。',
        );
        errorInfo.error8 = true;
        error = true;
      }

      if (
        requestData.chargeMethod === 1 &&
        requestData.chargeReportType === 1 &&
        requestData.chargeSendOption === 0
      ) {
        message.push(
          '【①基本情報】請求方法：月次、引渡方法：郵送の場合、請求書送付先情報に「依頼者と同じ」は選択できません。',
        );
        errorInfo.error8 = true;
        error = true;
      }

      setIsError(errorInfo);
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);

        return false;
      }
      setIsHide(true);

      return true;
    }

    return false;
  };

  // 依頼内容チェック
  const confirmationBusiness = (): boolean => {
    // 入力チェック
    if (requestData) {
      let error = false;
      const errorInfo: ErrorInfo = {};
      const message: string[] = [];

      // ②依頼内容
      if (!requestData.estimatedShippingDate) {
        message.push('【②依頼内容】発送予定日を入力してください。');
        errorInfo.error9 = true;
        error = true;
      }
      if (!requestData.expectedArrivalDate) {
        message.push('【②依頼内容】到着予定日を入力してください。');
        errorInfo.error10 = true;
        error = true;
      }
      if (
        requestData.estimatedShippingDate !== '' &&
        !checkDate(requestData.estimatedShippingDate.replaceAll('-', '/'))
      ) {
        message.push('【②依頼内容】発送予定日の日付が異常です。');
        errorInfo.error9 = true;
        error = true;
      }
      if (
        requestData.expectedArrivalDate !== '' &&
        !checkDate(requestData.expectedArrivalDate.replaceAll('-', '/'))
      ) {
        message.push('【②依頼内容】到着予定日の日付が異常です。');
        errorInfo.error10 = true;
        error = true;
      }
      if (
        requestData.estimatedShippingDate !== '' &&
        requestData.expectedArrivalDate !== '' &&
        !checkDate2(
          requestData.estimatedShippingDate.replaceAll('-', '/'),
          requestData.expectedArrivalDate.replaceAll('-', '/'),
        )
      ) {
        message.push(
          '【②依頼内容】到着予定日は発送予定日以降に設定してください。',
        );
        errorInfo.error10 = true;
        error = true;
      }
      if (requestData.taskList.length === 0) {
        message.push('【②依頼内容】依頼内容を入力してください。');
        errorInfo.error11 = true;
        error = true;
      }
      if (requestData.taskList.find((n) => n.taskSampleList.length === 0)) {
        message.push(
          '【②依頼内容】検査項目が入力されていない試験品があります。入力してください。',
        );
        errorInfo.error11 = true;
        error = true;
      }
      setIsError(errorInfo);
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);

        return false;
      }
      setIsHide(true);

      return true;
    }

    return false;
  };

  /* eslint-disable */
  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="xl">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            依頼情報
          </Typography>
          <div className={classes.form} hidden={isLoading}>
            <Box textAlign="left">
              業務内容：{requestData?.dspRecieptType1}
              <br />
              <span className={classes.dspIdReciept}>
                依頼番号：{requestData?.dspIdReciept}
              </span>{' '}
              状況：{requestData?.process}
            </Box>
            <MessagePanel
              messages={errorMessages}
              isHide={isHide}
              mode={mode}
            />
            <div hidden={isDeleted}>
              <Box textAlign="right">
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  checked={isAgree ?? false}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={onChangeIsAgree}
                      className={
                        isError?.error12 === true ? classes.errorLabel : ''
                      }
                    />
                  }
                  hidden={
                    (requestData?.id !== 0 &&
                      requestData?.process !== '下書き（依頼前）') ||
                    isHideContent ||
                    appContext.state.LoginUser.isMaster
                  }
                  label={agreeText}
                />
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  checked={requestData?.isMail ?? false}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={onChangeIsMail}
                      className={
                        isError?.error12 === true ? classes.errorLabel : ''
                      }
                    />
                  }
                  hidden={
                    !(
                      (requestData?.id === 0 &&
                        appContext.state.LoginUser.isMaster) ||
                      (appContext.state.LoginUser.isMaster &&
                        requestData?.process === '下書き（依頼前）')
                    ) || isHideContent
                  }
                  label="代理依頼通知メールを送信する"
                />
                <MuiThemeProvider theme={theme2}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.duplicate}
                    hidden={
                      isHideContent || (requestData?.idProcess ?? 0) >= 100
                    }
                    onClick={() => {
                      if (requestData?.id === 0) {
                        createDataDraft().finally(() => {
                          /* Nothing to do. */
                        });
                      } else {
                        updateDataDraft().finally(() => {
                          /* Nothing to do. */
                        });
                      }
                    }}
                  >
                    下書き保存
                  </Button>
                </MuiThemeProvider>
                <MuiThemeProvider theme={theme4}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.output}
                    hidden={
                      requestData?.id === 0 ||
                      requestData?.process === '下書き（依頼前）'
                    }
                    onClick={outputForm}
                  >
                    依頼書出力
                  </Button>
                </MuiThemeProvider>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.application}
                  hidden={!isHideContent}
                  onClick={displayContent}
                >
                  依頼表示
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={
                    !appContext.state.LoginUser.isMaster &&
                    (requestData?.idProcess ?? 0) >= 200
                      ? classes.application2
                      : classes.application
                  }
                  hidden={isHideContent}
                  disabled={
                    !appContext.state.LoginUser.isMaster &&
                    (requestData?.idProcess ?? 0) >= 200
                  }
                  onClick={confirmation}
                >
                  {(requestData?.id === 0 ||
                    requestData?.process === '下書き（依頼前）') &&
                    `依頼申込`}
                  {requestData?.id !== 0 &&
                    requestData?.process !== '下書き（依頼前）' &&
                    `依頼更新`}
                </Button>
                <Tooltip title={breakedTextRequestUpdate} placement="right">
                  <span
                    className={classes.delText}
                    hidden={
                      requestData?.id === 0 ||
                      !(
                        !appContext.state.LoginUser.isMaster &&
                        (requestData?.idProcess ?? 0) >= 200
                      )
                    }
                  >
                    ※
                  </span>
                </Tooltip>
                <MuiThemeProvider theme={theme2}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.duplicate}
                    onClick={handleMenu}
                    hidden={
                      requestData?.id === 0 ||
                      isHideContent ||
                      requestData?.process === '下書き（依頼前）'
                    }
                  >
                    依頼複製
                  </Button>
                </MuiThemeProvider>
                <Menu
                  id="application"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={open2}
                  onClose={handleClose}
                >
                  {recieptTypes
                    ?.filter((x) => x.id !== 0)
                    .map((item) => (
                      <MenuItem
                        key={item.menuTitle}
                        value={`${item.id}`}
                        onClick={() => {
                          if (requestData) {
                            setValue(0);
                            const tempData = { ...requestData };
                            tempData.id = 0;
                            if (tempData.recieptType1 !== item.id) {
                              // 検査区分が異なる場合、検査項目をクリア
                              tempData.taskList = tempData.taskList.map(
                                (atask) => {
                                  const data = { ...atask };
                                  data.taskSampleList = [];

                                  return data;
                                },
                              );
                              // tempData.taskList = [];
                            }
                            tempData.recieptType1 = item.id;
                            tempData.dspRecieptType1 = item.menuTitle;
                            tempData.no = 0;
                            tempData.idReciept = 0;
                            tempData.rcptCreate = '';
                            tempData.dspIdReciept = '';
                            tempData.estimatedShippingDate = '';
                            tempData.expectedArrivalDate = '';
                            tempData.deadLine = '';
                            tempData.comment = '';
                            tempData.reviewComment = '';
                            tempData.idProcess = 1;
                            tempData.attachmentList = [];
                            if (
                              tempData.sendReportType === 1 &&
                              tempData.sendNameFlgSameRcpt &&
                              !tempData.sendPic
                            ) {
                              tempData.sendPic =
                                tempData.picList?.find(
                                  (n) =>
                                    n.picType === 1 && n.mailSendType === 1,
                                )?.name ?? '';
                            }
                            if (
                              tempData.chargeMethod === 0 &&
                              tempData.chargeReportType === 1 &&
                              tempData.chargeSendOption === 0 &&
                              !tempData.chargeSendPic
                            ) {
                              tempData.chargeSendPic =
                                tempData.picList?.find(
                                  (n) =>
                                    n.picType === 1 && n.mailSendType === 1,
                                )?.name ?? '';
                            } else if (
                              tempData.chargeMethod === 1 &&
                              tempData.chargeReportType === 1 &&
                              tempData.chargeSendOption === 1 &&
                              !tempData.chargeSendPic
                            ) {
                              tempData.chargeSendPic =
                                billingData?.picName ?? '';
                            }

                            setRequestData(tempData);

                            displayContent();
                          }
                          handleClose();
                        }}
                      >
                        {item.menuTitle}
                      </MenuItem>
                    ))}
                </Menu>
                <MuiThemeProvider theme={theme3}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.withdrawal}
                    hidden={requestData?.id === 0 || isHideContent}
                    disabled={(requestData?.idProcess ?? 0) >= 200}
                    onClick={clickDelete}
                  >
                    依頼取下
                  </Button>
                  <Tooltip title={breakedTextRequestDelete} placement="right">
                    <span
                      className={classes.delText}
                      hidden={
                        requestData?.id === 0 ||
                        !((requestData?.idProcess ?? 0) >= 200)
                      }
                    >
                      ※
                    </span>
                  </Tooltip>
                </MuiThemeProvider>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.application}
                  hidden={!isHideContent}
                  onClick={() => {
                    navigate(`${rootDir}requestsearch`);
                  }}
                >
                  依頼検索へ戻る
                </Button>
                <CommonDialog
                  msg={confirmMessages}
                  isOpen={commDlg}
                  doYes={
                    commDlgMode === 1
                      ? deleteProc
                      : commDlgMode === 2
                      ? createData
                      : commDlgMode === 3
                      ? updateData
                      : outputForm
                  }
                  buttonDisabled={buttonDisabled}
                  doNo={() => {
                    setCommDlg(false);
                  }}
                />
                <CommonDialog
                  msg={confirmMessages}
                  isOpen={commDlg2}
                  doYes={outputForm}
                  buttonDisabled={buttonDisabled}
                  doNo={() => {
                    setCommDlg2(false);
                  }}
                  okOnly
                />
                <CommonDialog
                  msg={confirmMessages}
                  isOpen={commDlg4}
                  doYes={() => {
                    setIsConsentEn(true);
                    prod(isWarning, true);
                  }}
                  buttonDisabled={buttonDisabled}
                  doNo={() => {
                    setIsConsentEn(null);
                    setCommDlg4(false);
                  }}
                  yesWord="承諾"
                  noWord="修正"
                />
                <CommonDialog2
                  msg={confirmMessages}
                  isOpen={commDlg3}
                  buttonDisabled={buttonDisabled}
                  doNo={() => {
                    setCommDlg3(false);
                  }}
                  doProcess1={deleteProc}
                  doProcess2={revertToDraftProc}
                  process1Word="削除する"
                  process2Word="下書きに戻す"
                />
              </Box>
              <Box sx={{ width: '100%' }} hidden={isHideContent}>
                <MuiThemeProvider theme={theme1}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    className={classes.tab}
                  >
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="①基本情報 *" {...a11yProps(0)} />
                      <Tab label="②依頼内容 *" {...a11yProps(1)} />
                      <Tab label="③添付ファイル" {...a11yProps(2)} />
                      <Tab label="④備考" {...a11yProps(3)} />
                      {appContext.state.LoginUser.isMaster && (
                        <Tab label="⑤レビュー・備考" {...a11yProps(4)} />
                      )}
                    </Tabs>
                  </Box>
                </MuiThemeProvider>
                <TabPanel value={value} index={0}>
                  <BasicInfoPanel
                    userData={userData}
                    billingData={billingData}
                    picData={staffsData}
                    requestData={requestData}
                    updateRequest={(values: Request) => {
                      setRequestData(values);
                    }}
                    tabSet={(values: number) => {
                      setValue(values);
                    }}
                    errorInfo={isError}
                    check={confirmationBasic}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <BusinessContentPanel
                    requestData={requestData}
                    updateRequest={(values: Request) => {
                      setRequestData(values);
                    }}
                    tabSet={(values: number) => {
                      setValue(values);
                    }}
                    errorInfo={isError}
                    check={confirmationBusiness}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <AttachmentPanel
                    requestData={requestData}
                    updateRequest={(values: Request) => {
                      setRequestData(values);
                    }}
                    tabSet={(values: number) => {
                      setValue(values);
                    }}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <NotesPanel
                    requestData={requestData}
                    updateRequest={(values: Request) => {
                      setRequestData(values);
                    }}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <ReviewPanel
                    requestData={requestData}
                    updateRequest={(values: Request) => {
                      setRequestData(values);
                    }}
                  />
                </TabPanel>
              </Box>
            </div>
            <div className={classes.deleteBack}>
              <Button
                variant="contained"
                color="primary"
                className={classes.application}
                hidden={!isDeleted}
                onClick={() => {
                  navigate(`${rootDir}requestsearch`);
                }}
              >
                依頼検索へ戻る
              </Button>
            </div>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default RequestInfoPage;
