import { ChangeEventHandler, FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import AddressSelectDialog, {
  Address,
} from 'components/molecules/AddressSelectDialog';
import { API_URL } from 'utils/CommonConst';
import { consoleLog, methodGet } from 'utils/CommonFunctions';
import { PropTypes } from '@material-ui/core';
import CustomTextField from './CustomTextField';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(1.3, 2, 0.5),
      '&:hover': {
        background: 'green',
      },
    },
  }),
);

/* non eslint-disable */
export type PostalCodeInputProps = {
  title?: string;
  margin?: PropTypes.Margin | undefined;
  value: string;
  readonly?: boolean;
  updateAddress?: (value: string) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const PostalCodeInput: FC<PostalCodeInputProps> = ({
  title = '',
  margin = 'normal',
  value,
  readonly = false,
  updateAddress,
  onChange,
}) => {
  const classes = useStyles();
  const [openSelect, setOpenSelect] = useState(false);
  const [addressList, setAddressList] = useState<Address[]>([]);

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  const addressSearch = () => {
    // ここに住所呼び出しの処理を書く（サーバー側処理の呼び出し）
    const getData = async () => {
      try {
        const url = `${API_URL}api/v1/addresses/${value}`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as Address[];
        if (data.length === 1) {
          if (updateAddress) updateAddress(data[0].address);
        } else if (data.length !== 0) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setAddressList(res.data);
          setOpenSelect(true);
        }
      } catch (ex) {
        consoleLog(ex);
      }
    };
    getData().finally(() => {
      /* Nothing to do. */
    });
  };

  return (
    <>
      <CustomTextField
        variant="outlined"
        margin={margin}
        required
        id="postalCode"
        label={title}
        name="postalCode"
        value={value}
        InputProps={{
          readOnly: readonly,
          inputProps: {
            maxLength: 7,
          },
        }}
        onChange={onChange}
      />
      <span hidden={readonly}>
        <MuiThemeProvider theme={theme2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={addressSearch}
          >
            住所呼び出し
          </Button>
        </MuiThemeProvider>
      </span>
      <AddressSelectDialog
        open={openSelect}
        onClickClose={handleCloseSelect}
        addressList={addressList}
        updateAddress={updateAddress}
      />
    </>
  );
};

export default PostalCodeInput;
