import { FC, useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { SendEmail } from 'types/Email';
import { consoleLog, debugLog, methodPost } from 'utils/CommonFunctions';
import { useLocation, useNavigate } from 'react-router';
import { API_URL, rootDir } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const theme1 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    space: {
      margin: theme.spacing(2, 0, 5),
    },
    submit: {
      margin: theme.spacing(0, 0, 7),
    },
    backButton: {
      margin: theme.spacing(-7, 5, 0),
    },
    titleSpace: {
      margin: theme.spacing(0, 0, 5),
    },
  }),
);

const MailFormPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [email, setEmail] = useState<SendEmail>();
  const [dispMode, setDispMode] = useState('');
  const [isHide, setIsHide] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [mode, setMode] = useState<Mode>('info');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (appContext.state.LoginUser.isLogin) {
      if (!appContext.state.LoginUser.isMaster) {
        // 管理者専用ページのため
        navigate(`${rootDir}top`);
      }
    }
  }, [appContext.state.LoginUser, navigate]);

  // 件名
  const changedSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...email } as SendEmail;
    data.subject = event.target.value;
    setEmail(data);
  };
  // 本文
  const changedText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...email } as SendEmail;
    data.text = event.target.value;
    setEmail(data);
  };
  // 戻る
  const clickChangeBack = () => {
    setDispMode('change');
  };

  const send: SendEmail = {
    subject: email?.subject ?? '',
    text: email?.text ?? '',
  };

  const postData = async () => {
    try {
      const url = `${API_URL}api/v1/emails/`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(url, send, true, appContext.state.LoginUser);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const resp = res.data as boolean;
      if (resp) {
        setIsHide(false);
        setMode('info');
        setIsSuccess(true);
        debugLog('メールを送信しました。');
        navigate(`${rootDir}finish`, {
          state: {
            title: 'メール通知',
            message: ['メールを送信しました。'],
            from: location,
          },
        });
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };
  useEffect(() => {
    setDispMode('change');
  }, []);

  const clickVerifi = () => {
    if ((email?.subject ?? '') === '') {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['件名が未入力です。']);
      debugLog('件名が未入力です。');

      return;
    }
    if ((email?.text ?? '') === '') {
      setIsHide(false);
      setMode('error');
      setErrorMessages(['本文が未入力です。']);
      debugLog('本文が未入力です。');

      return;
    }
    setDispMode('changeVerifi');
    setIsHide(true);
  };

  const clickSend = () => {
    setIsLoading(true);
    postData().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            className={classes.titleSpace}
          >
            {dispMode === 'change' && 'メール通知'}
            {dispMode === 'changeVerifi' && 'メール内容確認'}
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess}>
            <Typography component="h1" variant="h5">
              件名
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              id="subject"
              label=""
              name="subject"
              value={email?.subject ?? ''}
              multiline
              fullWidth
              className={classes.space}
              onChange={changedSubject}
              InputProps={{
                readOnly: dispMode === 'changeVerifi',
              }}
            />
            <Typography component="h1" variant="h5">
              本文
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              id="text"
              label=""
              name="text"
              value={email?.text ?? ''}
              multiline
              fullWidth
              className={classes.space}
              onChange={changedText}
              InputProps={{
                readOnly: dispMode === 'changeVerifi',
              }}
            />
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={clickVerifi}
                hidden={dispMode !== 'change'}
              >
                確認
              </Button>
            </Box>
            <Box textAlign="center" hidden={dispMode !== 'changeVerifi'}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={clickSend}
              >
                送信
              </Button>
              <MuiThemeProvider theme={theme1}>
                <Button
                  variant="contained"
                  className={classes.backButton}
                  onClick={clickChangeBack}
                >
                  戻る
                </Button>
              </MuiThemeProvider>
            </Box>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default MailFormPage;
