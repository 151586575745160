import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import {
  consoleLog,
  debugLog,
  methodGet,
  methodPut,
} from 'utils/CommonFunctions';
import { useLocation, useNavigate } from 'react-router';
import { API_URL, rootDir } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import { NewsInfo } from 'types/News';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    space: {
      margin: theme.spacing(2, 0, 5),
    },
    submit: {
      margin: theme.spacing(0, 0, 7),
    },
    backButton: {
      margin: theme.spacing(-7, 5, 0),
    },
    titleSpace: {
      margin: theme.spacing(0, 0, 5),
    },
    checkbox: {
      margin: theme.spacing(0, 5, 0),
      color: 'red',
    },
    finish: {
      width: '100%', // Fix IE 11 issue.
      alignItems: 'center',
    },
  }),
);

const NewsPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [newsInfo, setNewsInfo] = useState<NewsInfo>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [mode, setMode] = useState<Mode>('info');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (appContext.state.LoginUser.isLogin) {
      if (!appContext.state.LoginUser.isMaster) {
        // 管理者専用ページのため
        navigate(`${rootDir}top`);
      }
    }
  }, [appContext.state.LoginUser, navigate]);

  // お知らせ内容
  const changedNews = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newsInfo } as NewsInfo;
    data.news = event.target.value;
    setNewsInfo(data);
  };
  // リンク用テキスト
  const changedLinkText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newsInfo } as NewsInfo;
    data.linkText = event.target.value;
    setNewsInfo(data);
  };
  // URLリンク
  const changedUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newsInfo } as NewsInfo;
    data.url = event.target.value;
    setNewsInfo(data);
  };
  // 文字色赤
  const changeIsRed = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...newsInfo } as NewsInfo;
    const value = event.target.checked;
    data.isRed = value;
    setNewsInfo(data);
  };

  const getData = useCallback(async () => {
    if (appContext.state.LoginUser.isLogin) {
      try {
        const url = `${API_URL}api/v1/masters/news/`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as NewsInfo;
        setNewsInfo(data);
        setIsLoading(false);
      } catch (ex) {
        consoleLog(ex);
      }
    }
  }, [appContext.state.LoginUser]);

  useEffect(() => {
    getData().finally(() => {
      /* Nothing to do. */
    });
  }, [getData]);

  const postData = async () => {
    try {
      const url = `${API_URL}api/v1/masters/news/`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPut(
        url,
        newsInfo,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const resp = res.data as boolean;
      if (resp) {
        setIsHide(false);
        setMode('info');
        setIsSuccess(true);
        // setErrorMessages(['お知らせ内容を変更しました。']);
        debugLog('お知らせ内容を変更しました。');
        navigate(`${rootDir}finish`, {
          state: {
            title: 'お知らせ編集',
            message: ['お知らせ内容を変更しました。'],
            from: location,
          },
        });
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  const clickSave = () => {
    if (
      ((newsInfo?.linkText ?? '') !== '' || (newsInfo?.url ?? '') !== '') &&
      (newsInfo?.news ?? '') === ''
    ) {
      setIsHide(false);
      setMode('error');
      setErrorMessages([
        'お知らせ内容が未入力です。URLリンク設定時はお知らせ内容も入力して下さい。',
      ]);
      debugLog(
        'お知らせ内容が未入力です。URLリンク設定時はお知らせ内容も入力して下さい。',
      );

      return;
    }
    if ((newsInfo?.linkText ?? '') === '' && (newsInfo?.url ?? '') !== '') {
      setIsHide(false);
      setMode('error');
      setErrorMessages([
        'リンク用テキストが未入力です。URLリンク入力時はリンク用テキストも入力してください。',
      ]);
      debugLog(
        'リンク用テキストが未入力です。URLリンク入力時はリンク用テキストも入力してください。',
      );

      return;
    }
    if ((newsInfo?.linkText ?? '') !== '' && (newsInfo?.url ?? '') === '') {
      setIsHide(false);
      setMode('error');
      setErrorMessages([
        'URLリンクが未入力です。リンク用テキスト入力時はURLリンクも入力してください。',
      ]);
      debugLog(
        'URLリンクが未入力です。リンク用テキスト入力時はURLリンクも入力してください。。',
      );

      return;
    }
    setIsLoading(true);
    postData().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            className={classes.titleSpace}
          >
            お知らせ編集
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess}>
            <Typography component="h1" variant="h5">
              お知らせ内容
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={changeIsRed}
                    checked={newsInfo?.isRed ?? false}
                  />
                }
                label="赤字で表示"
                className={classes.checkbox}
              />
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              id="news"
              label=""
              name="news"
              value={newsInfo?.news ?? ''}
              multiline
              fullWidth
              className={classes.space}
              onChange={changedNews}
              inputProps={{ maxLength: 255 }}
            />
            <Typography component="h1" variant="h5">
              リンク用テキスト
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              id="linkText"
              label=""
              name="linkText"
              value={newsInfo?.linkText ?? ''}
              multiline
              fullWidth
              className={classes.space}
              onChange={changedLinkText}
              inputProps={{ maxLength: 255 }}
            />
            <Typography component="h1" variant="h5">
              URLリンク
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              id="url"
              label=""
              name="url"
              value={newsInfo?.url ?? ''}
              multiline
              fullWidth
              className={classes.space}
              onChange={changedUrl}
              inputProps={{ maxLength: 255 }}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={clickSave}
            >
              保存
            </Button>
          </div>
          {/* <Box textAlign="center">
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              hidden={!isSuccess}
              onClick={() => {
                navigate(`${rootDir}top`);
              }}
            >
              トップページヘ
            </Button>
          </Box> */}
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default NewsPage;
