import { createContext, useCallback, useState } from 'react';
import { LoginUser } from 'types/User';

export type AppState = {
  /** ログイン情報 */
  LoginUser: LoginUser;
};

// set context type
type AppContextType = {
  state: AppState;
  setState: (val: AppState) => void;
};

/** 初期設定 */
export const initailState: AppState = {
  LoginUser: {
    isLogin: false,
    idCust: 0,
    idMaster: 0,
    isMaster: false,
    loginID: '',
  },
};

/** コンテキスト作成 */
export const AppContext = createContext<AppContextType>({
  state: initailState,
  setState: (val: AppState) => val,
});

/** カスタムフック */
export const useAppContext = (): AppContextType => {
  // state名はAppContext typeのプロパティに合わせる。
  const [state, setAppState] = useState<AppState>(initailState);

  // 関数名はAppContext typeのプロパティに合わせる。
  const setState = useCallback((current: AppState): void => {
    setAppState(current);
  }, []);

  return {
    state,
    setState,
  };
};
