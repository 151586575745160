import { FC } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
import { Backdrop, BackdropProps, CircularProgress } from '@material-ui/core';

const theme1 = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        zIndex: zIndex.drawer + 1,
      },
    },
  },
});

export type CustomBackdropProps = BackdropProps;

const CustomBackdrop: FC<CustomBackdropProps> = ({ ...props }) => (
  <>
    <MuiThemeProvider theme={theme1}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Backdrop {...props}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiThemeProvider>
  </>
);
export default CustomBackdrop;
