import { FC, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { API_URL } from 'utils/CommonConst';
import { consoleLog, debugLog, methodPost } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import { PasswordReset2 } from 'types/User';
import HeaderTemplate from '../templates/HeaderTemplate';
import { rootDir } from '../../utils/CommonConst';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '18px',
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

const PasswordChangeClientPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [isHide, setIsHide] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>('info');

  useEffect(() => {
    if (appContext.state.LoginUser.isMaster) {
      // 顧客専用ページのため
      navigate(`${rootDir}top`);
    }
  }, [appContext.state.LoginUser.isMaster, navigate]);

  const click = async () => {
    try {
      const url = `${API_URL}api/v1/users/passwordresetclient`;
      const data: PasswordReset2 = {
        idCust: appContext.state.LoginUser.idCust,
        loginID: appContext.state.LoginUser.loginID,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPost(url, data, false);
      setIsHide(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (res.data) {
        setMode('info');
        setIsSuccess(true);
        setErrorMessages(['パスワード再設定メールを送信しました。']);
        debugLog('パスワード再設定メールを送信しました。');
      } else {
        setMode('error');
        setErrorMessages([
          '入力されたメールアドレスがシステムに登録されていません。',
        ]);
        debugLog(res);
      }
    } catch (ex) {
      consoleLog(ex);
    }
  };

  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            パスワード変更
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess}>
            <Box textAlign="center">
              アカウント担当者のメールアドレスに、パスワード再設定のメールを送信します。
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={click}
              >
                パスワード再設定メール送信
              </Button>
            </Box>
          </div>
          {/* <div hidden={!isSuccess}>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => navigate(`${rootDir}`)}
              >
                ログイン画面へ
              </Button>
            </Box>
          </div> */}
        </div>
      </Container>
    </HeaderTemplate>
  );
};

export default PasswordChangeClientPage;
