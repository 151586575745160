import { ChangeEventHandler, FC } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

const theme2 = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(0, 0, 0),
      '&:hover': {
        background: 'gray',
      },
    },
    length: {
      // margin: theme.spacing(0, 0, 1),
      maxWidth: 600,
    },
    line: {
      maxWidth: 600,
    },
  }),
);

export type PasswordInputProps = {
  title?: string;
  value?: string;
  readonly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const PasswordInput: FC<PasswordInputProps> = ({
  title = '',
  value,
  readonly = false,
  onChange,
}) => {
  const classes = useStyles();

  const text =
    'パスワード規則 ¥n ・8文字以上であること ¥n ・数字が含まれていること ¥n ・大文字が含まれていること ¥n ・小文字が含まれていること ¥n ・ログインIDが含まれていないこと';
  const breakedText = text.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  return (
    <div className={classes.line}>
      <MuiThemeProvider theme={theme2}>
        <Box textAlign="right" hidden={readonly}>
          <Tooltip title={breakedText} placement="right">
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              パスワードポリシー
            </Button>
          </Tooltip>
        </Box>
      </MuiThemeProvider>
      <TextField
        variant="outlined"
        required
        id="newPassword"
        label={title}
        name="newPassword"
        type="password"
        fullWidth
        margin="dense"
        className={classes.length}
        value={value}
        InputProps={{
          readOnly: readonly,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default PasswordInput;
