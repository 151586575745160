import { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export type CommonDialog2Props = {
  /* eslint-disable */
  msg: any;
  isOpen: boolean;
  doProcess1: any;
  doProcess2: any;
  doNo: VoidFunction;
  buttonDisabled?: boolean;
  process1Word?: string;
  process2Word?: string;
  noWord?: string;
};

const CommonDialog2: FC<CommonDialog2Props> = ({
  msg,
  isOpen,
  doProcess1,
  doProcess2,
  doNo,
  buttonDisabled = false,
  process1Word,
  process2Word,
  noWord = 'いいえ',
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNo()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>{msg}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => doNo()}
            color="primary"
            disabled={buttonDisabled}
          >
            {noWord}
          </Button>
          <Button
            onClick={() => doProcess1()}
            color="primary"
            disabled={buttonDisabled}
          >
            {process1Word}
          </Button>
          <Button
            onClick={() => doProcess2()}
            color="primary"
            disabled={buttonDisabled}
          >
            {process2Word}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CommonDialog2;
