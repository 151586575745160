import { ChangeEvent, FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Request } from 'types/RequestDataInfo';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    length: {
      maxWidth: 730,
    },
  }),
);

export type NotesPanelProps = {
  requestData?: Request;
  updateRequest?: (value: Request) => void;
};

const NotesPanel: FC<NotesPanelProps> = ({ requestData, updateRequest }) => {
  const classes = useStyles();

  const changeCommentUser = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...requestData } as Request;
    data.commentUser = event.target.value;
    if (updateRequest) updateRequest(data);
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        備考
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        id="note"
        label=""
        name="note"
        multiline
        value={requestData?.commentUser}
        onChange={changeCommentUser}
        className={classes.length}
      />
    </div>
  );
};

export default NotesPanel;
