import { FC, ReactElement } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';

/** Helpers */
import { rootDir } from 'utils/CommonConst';
import validateToken from 'utils/helpers';

/** Constants */
import AUTH_USER_TOKEN_KEY from 'utils/constants';

export type Props = {
  element: ReactElement;
  checkUserAuth: boolean;
  path?: string;
};

const UnAuthElement: FC<Props> = ({ element, checkUserAuth }) => {
  // const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const location = useLocation();
  const { from } = (location.state as { from: Location }) || {
    from: { pathname: `${rootDir}top` },
  };
  // if (loading) return <p>Loading.. </p>;

  // eslint-disable-next-line
  // console.log(from);

  return checkUserAuth ? <Navigate to={from ?? `${rootDir}top`} /> : element;
};

export type UnAuthRouteProps = {
  element: ReactElement;
  path?: string;
};

/* eslint-disable  */
const UnAuthRoute: FC<UnAuthRouteProps> = ({ element, ...rest }) => {
  const checkUserAuth = validateToken(
    localStorage.getItem(AUTH_USER_TOKEN_KEY),
  );

  // console.log('checkUserAuth:' + checkUserAuth);
  return (
    <Route
      {...rest}
      element={
        <UnAuthElement element={element} checkUserAuth={checkUserAuth} />
      }
    />
  );
};

export default UnAuthRoute;
