import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { scroller } from 'react-scroll';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PostalCodeInput from 'components/molecules/PostalCodeInput';
import { BillingInfo, UpdateBillingInfo } from 'types/User';
import MessagePanel, { Mode } from 'components/molecules/MessagePanel';
import { API_URL, rootDir } from 'utils/CommonConst';
import {
  consoleLog,
  debugLog,
  methodGet,
  methodPut,
} from 'utils/CommonFunctions';
import { useLocation, useNavigate } from 'react-router';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import CustomTextField from 'components/molecules/CustomTextField';
import HeaderTemplate from '../templates/HeaderTemplate';

const theme1 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const theme2 = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'gray',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    tooltip: {
      margin: theme.spacing(0, 2, 0.5),
      '&:hover': {
        background: 'gray',
      },
    },
    radio: {
      margin: theme.spacing(-1.2, 3, 1),
    },
    title: {
      margin: theme.spacing(0, 0, 4),
    },
    length: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: 600,
    },
    space: {
      margin: theme.spacing(2, 4, 5),
    },
    checkbox: {
      margin: theme.spacing(1, 4, 5),
    },
    line: {
      margin: theme.spacing(1, 0, 5),
      width: '100%', // Fix IE 11 issue.
      maxWidth: 600,
    },
    titleSpace: {
      margin: theme.spacing(2, 0, 2),
    },
    submit: {
      margin: theme.spacing(3, 0, 0),
    },
    backButton: {
      margin: theme.spacing(3, 5, 0),
    },
  }),
);

const BillingInfoPage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const mailCheck =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
  const telCheck = /\d{2,4}-\d{2,4}-\d{4}$/;
  const postalCodeCheck = /^[0-9]{7}$/;

  const clcaimText =
    '依頼の請求は、「依頼毎の請求」、「月次請求」の2種類があります。 ¥n ・依頼毎の請求 ¥n 　依頼単位で請求書を発行 ¥n ・月次請求 ¥n 　月末締めで1月分の料金をまとめて請求';
  const clcaimTexts = clcaimText.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  const deliveryText =
    '・本システム（Web）の場合、 ¥n 請求書はシステムにアップロードされます。 ¥n 請求書発行時、通知メールを配信致しますので、 ¥n ご担当者様のメールアドレスを登録してください。 ¥n ¥n ・郵送を希望される場合は、 ¥n 「希望する」を選択し、送付先情報を登録してください。 ¥n ¥n ※両方選択した場合は、依頼毎に設定可能です。';
  const deliveryTexts = deliveryText.split('¥n').map((line) => (
    <span key={line} style={{ fontSize: '15px' }}>
      {line}
      <br />
    </span>
  ));

  const [billingData, setBillingData] = useState<BillingInfo>();
  const [billingData2, setBillingData2] = useState<BillingInfo>();
  const [changeMail, setChangeMail] = useState<boolean>(false);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [mode, setMode] = useState<Mode>('info');
  const [dispMode, setDispMode] = useState('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (appContext.state.LoginUser.isMaster) {
      // 顧客専用ページのため
      navigate(`${rootDir}top`);
    }
  }, [appContext.state.LoginUser.isMaster, navigate]);

  // トップへ移動
  const scrollToTop = () => {
    scroller.scrollTo('TopPage', {
      duration: 250,
      delay: 100,
      smooth: true,
      containerId: 'HeaderContent',
      offset: -150, // Scrolls to element + 50 pixels down the page
    });
  };

  // 希望する・希望しない切り替え
  const onChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    const value = event.target.value.toLowerCase() === 'true';
    data.isMonthlyInvoice = value;
    setBillingData(data);
  };
  // 本システム（Web）選択
  const handleClickWeb = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    const value = event.target.checked;
    data.isWeb = value;
    setBillingData(data);
  };
  // 郵送選択
  const handleClickPost = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    const value = event.target.checked;
    data.isPost = value;
    setBillingData(data);
  };
  // 担当者名変更
  const changedPicName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.picName = event.target.value;
    setBillingData(data);
  };
  // メールアドレス変更
  const changedMail = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.mail = event.target.value;
    setBillingData(data);
  };
  // 名称変更
  const changedName = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.name = event.target.value;
    setBillingData(data);
  };
  // 郵便番号変更
  const changePostalCode = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.zip = event.target.value;
    setBillingData(data);
  };
  // 住所変更
  const changeAddress1 = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.address1 = event.target.value;
    setBillingData(data);
  };
  // 建物名変更
  const changedAddress2 = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.address2 = event.target.value;
    setBillingData(data);
  };
  // 電話番号変更
  const changedTel = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.tel = event.target.value;
    setBillingData(data);
  };
  // FAX変更
  const changedFax = (event: ChangeEvent<HTMLInputElement>) => {
    const data = { ...billingData } as BillingInfo;
    data.fax = event.target.value;
    setBillingData(data);
  };
  // 戻る（変更）
  const clickChangeBack = () => {
    setDispMode('change');
  };

  const billingInfo: BillingInfo = {
    idCust: appContext.state.LoginUser?.idCust,
    picName: billingData?.picName ?? '',
    mail: billingData?.mail ?? '',
    name: billingData?.name ?? '',
    zip: billingData?.zip ?? '',
    address1: billingData?.address1 ?? '',
    address2: billingData?.address2 ?? '',
    tel: billingData?.tel ?? '',
    fax: billingData?.fax ?? '',
    isMonthlyInvoice: billingData?.isMonthlyInvoice ?? false,
    isWeb: billingData?.isWeb ?? false,
    isPost: billingData?.isPost ?? false,
    isApproved: billingData?.isApproved ?? false,
  };

  const getData = useCallback(async () => {
    if (appContext.state.LoginUser.isLogin) {
      try {
        const url = `${API_URL}api/v1/users/${
          appContext.state.LoginUser.idCust ?? 0
        }/charge`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await methodGet(url, true, appContext.state.LoginUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = res.data as BillingInfo;
        setBillingData(data);
        setBillingData2(data);
        setIsLoading(false);
      } catch (ex) {
        consoleLog(ex);
      }
    }
  }, [appContext.state.LoginUser]);

  useEffect(() => {
    setDispMode('change');
    getData().finally(() => {
      /* Nothing to do. */
    });
  }, [getData]);

  const putData = async () => {
    try {
      const update: UpdateBillingInfo = {
        billingInfo,
        changeMail,
      };
      const url = `${API_URL}api/v1/users/${
        appContext.state.LoginUser.idCust ?? 0
      }/charge`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res = await methodPut(
        url,
        update,
        true,
        appContext.state.LoginUser,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const data = res.data as boolean;
      if (data) {
        setIsHide(false);
        setIsSuccess(true);
        if (!changeMail) {
          debugLog('月次請求情報の変更が完了しました。');
          setIsLoading(false);
          navigate(`${rootDir}finish`, {
            state: {
              title: '月次請求情報',
              message: ['月次請求情報の変更が完了しました。'],
              from: location,
            },
          });
        } else {
          debugLog(
            '月次請求情報の変更が完了しました。メールアドレスについては変更後のメールアドレスに確認用メールを送信していますので、そのメールで認証後に有効になります。',
          );
          setIsLoading(false);
          navigate(`${rootDir}finish`, {
            state: {
              title: '月次請求情報',
              message: [
                '月次請求情報の変更が完了しました。',
                'メールアドレスについては変更後のメールアドレスに',
                '確認用メールを送信していますので、そのメールで認証後に有効になります。',
              ],
              from: location,
            },
          });
        }
      } else {
        setIsLoading(false);
      }
    } catch (ex) {
      setIsLoading(false);
      consoleLog(ex);
    }
  };

  const clickVerifi = () => {
    const check = (data: BillingInfo, data2: BillingInfo): boolean => {
      if (
        data.isMonthlyInvoice === data2.isMonthlyInvoice &&
        data.picName === data2.picName &&
        data.isWeb === data2.isWeb &&
        data.isPost === data2.isPost &&
        data.mail === data2.mail &&
        data.name === data2.name &&
        data.zip === data2.zip &&
        data.address1 === data2.address1 &&
        data.address2 === data2.address2 &&
        data.tel === data2.tel &&
        data.fax === data2.fax
      ) {
        return true;
      }

      return false;
    };

    if (billingData && billingData2) {
      let error = false;
      const message: string[] = [];
      if (check(billingData, billingData2)) {
        message.push('・内容が変更されていません。');
        error = true;
      }
      if (billingData.isMonthlyInvoice) {
        if (billingData.picName === '') {
          message.push('・担当者名が入力されていません。');
          error = true;
        }
        if (!billingData.isWeb && !billingData.isPost) {
          message.push('・引渡方法を選択して下さい。');
          error = true;
        }
        if (billingData.isWeb) {
          if (billingData.mail === '') {
            message.push('・メールアドレスが入力されていません。');
            error = true;
          }
          if (billingData.mail !== '' && !mailCheck.test(billingData.mail)) {
            message.push('・メールアドレスの形式になっていません。');
            error = true;
          }
        }
        if (billingData.isPost) {
          if (billingData.name === '') {
            message.push('・名称が入力されていません。');
            error = true;
          }
          if (billingData.zip === '') {
            message.push('・郵便番号が入力されていません。');
            error = true;
          }
          if (
            billingData.zip !== '' &&
            !postalCodeCheck.test(billingData.zip)
          ) {
            message.push('・郵便番号は半角数字で7桁で入力してください。');
            error = true;
          }
          if (billingData.address1 === '') {
            message.push('・住所が入力されていません。');
            error = true;
          }
          if (billingData.tel === '') {
            message.push('・電話番号が入力されていません。');
            error = true;
          }
          if (billingData.tel !== '' && !telCheck.test(billingData.tel)) {
            message.push(
              '・電話番号は半角数字と半角ハイフンで入力してください。',
            );
            message.push('　例）083-941-6300');
            error = true;
          }
          if (billingData.fax !== '' && !telCheck.test(billingData.fax)) {
            message.push(
              '・ファックスは半角数字と半角ハイフンで入力してください。',
            );
            message.push('　例）083-941-6400');
            error = true;
          }
        }
      }
      if (error) {
        setIsHide(false);
        setMode('error');
        setErrorMessages(message);
        scrollToTop();

        return;
      }
      if (billingData.mail !== billingData2.mail) {
        setChangeMail(true);
      } else {
        setChangeMail(false);
      }
    }
    setDispMode('changeVerifi');
    setIsHide(true);
  };

  const clickUpdate = () => {
    setIsLoading(true);
    putData().finally(() => {
      /* Nothing to do. */
    });
  };

  /* non eslint-disable */
  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="md">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            {dispMode === 'change' && '月次請求設定'}
            {dispMode === 'changeVerifi' && '月次請求設定（確認）'}
          </Typography>
          <MessagePanel messages={errorMessages} isHide={isHide} mode={mode} />
          <div className={classes.form} hidden={isSuccess || isLoading}>
            <Box>
              請求方法
              <MuiThemeProvider theme={theme2}>
                <Tooltip title={clcaimTexts} placement="right">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.tooltip}
                  >
                    請求方法？
                  </Button>
                </Tooltip>
              </MuiThemeProvider>
            </Box>
            <Box className={classes.space}>
              月次請求書を
              <FormControl className={classes.radio}>
                <RadioGroup
                  row
                  aria-label="gender"
                  value={billingData?.isMonthlyInvoice ?? false}
                  onChange={onChangeCheck}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="希望しない"
                    disabled={dispMode === 'changeVerifi'}
                  />
                  <FormControlLabel
                    value
                    control={<Radio color="primary" />}
                    label="希望する"
                    disabled={dispMode === 'changeVerifi'}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <div hidden={!billingData?.isMonthlyInvoice}>
              <Typography component="h1" variant="h5">
                担当者情報
              </Typography>
              <br />
              <CustomTextField
                variant="outlined"
                margin="dense"
                required
                id="picName"
                label="担当者名"
                value={billingData?.picName ?? ''}
                name="picName"
                autoComplete="picName"
                className={classes.line}
                onChange={changedPicName}
                InputProps={{
                  readOnly: dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 20,
                  },
                }}
              />
              <Typography component="h1" variant="h5">
                引渡方法
                <MuiThemeProvider theme={theme2}>
                  <Tooltip title={deliveryTexts} placement="right">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.tooltip}
                    >
                      引渡方法？
                    </Button>
                  </Tooltip>
                </MuiThemeProvider>
              </Typography>
              <Box className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleClickWeb}
                      checked={billingData?.isWeb ?? false}
                    />
                  }
                  label="本システム（Web）"
                  disabled={dispMode === 'changeVerifi'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleClickPost}
                      checked={billingData?.isPost ?? false}
                    />
                  }
                  label="郵送"
                  disabled={dispMode === 'changeVerifi'}
                />
              </Box>
              <CustomTextField
                variant="outlined"
                margin="dense"
                required
                id="mail"
                label="メールアドレス"
                value={billingData?.mail ?? ''}
                name="mail"
                autoComplete="mail"
                className={classes.length}
                hidden={!billingData?.isWeb}
                onChange={changedMail}
                InputProps={{
                  readOnly: dispMode === 'changeVerifi',
                  inputProps: {
                    maxLength: 50,
                  },
                }}
              />
              <div hidden={!billingData?.isPost}>
                <Typography
                  component="h1"
                  variant="h6"
                  className={classes.titleSpace}
                >
                  送付先
                </Typography>
                <CustomTextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="name"
                  label="名称"
                  value={billingData?.name ?? ''}
                  name="name"
                  autoComplete="name"
                  onChange={changedName}
                  InputProps={{
                    readOnly: dispMode === 'changeVerifi',
                    inputProps: {
                      maxLength: 20,
                    },
                  }}
                />
                <PostalCodeInput
                  title="郵便番号"
                  margin="dense"
                  value={billingData?.zip ?? ''}
                  onChange={changePostalCode}
                  readonly={dispMode === 'changeVerifi'}
                  updateAddress={(values: string) => {
                    const data = { ...billingData } as BillingInfo;
                    data.address1 = values;
                    setBillingData(data);
                  }}
                />
                <CustomTextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="address1"
                  label="住所"
                  name="address1"
                  autoComplete="address1"
                  value={billingData?.address1 ?? ''}
                  onChange={changeAddress1}
                  InputProps={{
                    readOnly: dispMode === 'changeVerifi',
                    inputProps: {
                      maxLength: 20,
                    },
                  }}
                />
                <CustomTextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="address2"
                  label="建物名等"
                  value={billingData?.address2 ?? ''}
                  name="address2"
                  autoComplete="address2"
                  onChange={changedAddress2}
                  InputProps={{
                    readOnly: dispMode === 'changeVerifi',
                    inputProps: {
                      maxLength: 20,
                    },
                  }}
                />
                <CustomTextField
                  variant="outlined"
                  margin="dense"
                  required
                  id="tel"
                  label="電話番号(ﾊｲﾌﾝ有)"
                  value={billingData?.tel ?? ''}
                  name="tel"
                  autoComplete="tel"
                  onChange={changedTel}
                  InputProps={{
                    readOnly: dispMode === 'changeVerifi',
                    inputProps: {
                      maxLength: 20,
                    },
                  }}
                />
                <br />
                <CustomTextField
                  variant="outlined"
                  margin="dense"
                  id="fax"
                  label="FAX番号(ﾊｲﾌﾝ有)"
                  value={billingData?.fax ?? ''}
                  name="fax"
                  autoComplete="fax"
                  onChange={changedFax}
                  InputProps={{
                    readOnly: dispMode === 'changeVerifi',
                    inputProps: {
                      maxLength: 20,
                    },
                  }}
                />
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={clickVerifi}
              hidden={dispMode !== 'change'}
            >
              確認
            </Button>
            <Box hidden={dispMode !== 'changeVerifi'}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={clickUpdate}
              >
                変更申込
              </Button>
              <MuiThemeProvider theme={theme1}>
                <Button
                  variant="contained"
                  className={classes.backButton}
                  onClick={clickChangeBack}
                >
                  戻る
                </Button>
              </MuiThemeProvider>
            </Box>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default BillingInfoPage;
