import { FC, useState, useEffect, useCallback, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FileInfo, FilesKey } from 'types/Files';
import { downloadData, findFile } from 'utils/CommonFunctions';
import { useNavigate } from 'react-router';
import { rootDir } from 'utils/CommonConst';
import { AppContext } from 'utils/AppContext';
import CustomBackdrop from 'components/molecules/CustomBackdrop';
import HeaderTemplate from '../templates/HeaderTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
  }),
);

const InvoicePage: FC = () => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [invoiceList, setInvoiceList] = useState<FileInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (appContext.state.LoginUser.isMaster) {
      // 顧客専用ページのため
      navigate(`${rootDir}top`);
    }
  }, [appContext.state.LoginUser.isMaster, navigate]);

  const confirmation = useCallback(async () => {
    if (appContext.state.LoginUser.isLogin) {
      const fileKey: FilesKey = {
        idCust: appContext.state.LoginUser.idCust,
        id: null,
        fileType: 2,
        no: null,
        name: '',
        path: '',
        billingDate: '',
        isEnable: true,
      };
      const filesKey: FilesKey[] = [];
      filesKey.push(fileKey);
      const fileInfo: FileInfo[] = await findFile(
        filesKey,
        appContext.state.LoginUser,
      );
      if (fileInfo && fileInfo.length > 0) {
        setInvoiceList(fileInfo);
      }
    }
  }, [appContext.state.LoginUser]);

  useEffect(() => {
    confirmation().finally(() => {
      /* Nothing to do. */
      setIsLoading(false);
    });
  }, [confirmation]);

  // ダウンロード
  const DownloadFile = async (key: FilesKey[], fileName: string) => {
    if (key.length > 0) {
      // ダウンロード要求
      await downloadData(key, fileName, appContext.state.LoginUser);
    } else {
      /*
          void message.warning(
            'ファイル情報を設定してください。',
          );
          */
    }
  };

  /* non eslint-disable */
  return (
    <HeaderTemplate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            月次請求書
          </Typography>
          <div className={classes.form} hidden={isLoading}>
            <div className="html-table">
              <table>
                <tbody>
                  <tr>
                    <th>請求書</th>
                  </tr>
                  {invoiceList.map((invoice) => (
                    <tr key={invoice.name}>
                      <td>
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() => {
                            const fileKey: FilesKey = {
                              idCust: invoice.idCust,
                              id: invoice.id,
                              fileType: invoice.fileType,
                              no: invoice.no,
                              name: invoice.name,
                              path: '',
                              billingDate: invoice.billingDate,
                              isEnable: true,
                            };
                            const filesKey: FilesKey[] = [];
                            filesKey.push(fileKey);
                            DownloadFile(filesKey, invoice.name).finally(() => {
                              /* Nothing to do. */
                            });
                          }}
                        >
                          {invoice.name}
                          <br />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomBackdrop open={isLoading} />
      </Container>
    </HeaderTemplate>
  );
};

export default InvoicePage;
