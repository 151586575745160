import { FC, useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import FileUploadDialog from 'components/molecules/FileUploadDialog';
import { Table, Button as Button2 } from 'antd';
import { Request } from 'types/RequestDataInfo';
import { AttachmentFile, FilesKey } from 'types/Files';
import { deleteFile, downloadData } from 'utils/CommonFunctions';
import { AppContext } from 'utils/AppContext';
import CommonDialog from './CommonDialog';

const theme2 = createTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'orange',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontSize: '18px',
    },
    submit: {
      maxWidth: 100,
      margin: theme.spacing(3, 0, 3),
      '&:hover': {
        background: 'darkorange',
      },
    },
    toNextDiv: {
      textAlign: 'right',
      margin: theme.spacing(5, 0, 0),
    },
    toNextButton: {
      margin: theme.spacing(0, 2, 5),
    },
  }),
);
/* non eslint-disable */
export type AttachmentPanelProps = {
  requestData?: Request;
  updateRequest: (value: Request) => void;
  tabSet: (value: number) => void;
};

const AttachmentPanel: FC<AttachmentPanelProps> = ({
  requestData,
  updateRequest,
  tabSet,
}) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();

  const [attachmentList, setAttachmentList] = useState<AttachmentFile[]>([]);
  const [openTemp, setOpenTemp] = useState(false);
  const [commDlg, setCommDlg] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState<JSX.Element[]>();
  const [targetFilesKey, setTargetFilesKey] = useState<FilesKey[]>([]);

  const handleClickOpenTemp = () => {
    setOpenTemp(true);
  };

  const handleCloseTemp = () => {
    setOpenTemp(false);
  };

  // ダウンロード
  const DownloadFile = async (key: FilesKey[], fileName: string) => {
    if (key.length > 0) {
      // ダウンロード要求
      await downloadData(key, fileName, appContext.state.LoginUser);
    } else {
      /*
        void message.warning(
          'ファイル情報を設定してください。',
        );
        */
    }
  };

  const deleteF = async () => {
    const res: boolean = await deleteFile(
      targetFilesKey,
      appContext.state.LoginUser,
    );
    setCommDlg(false);
    if (res) {
      // 成功
      const data = { ...requestData } as Request;
      data.attachmentList = data.attachmentList.filter(
        (n) => n.no !== targetFilesKey[0].no,
      );
      updateRequest(data);
    }
  };

  const columns = [
    {
      title: 'ファイル名',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record: AttachmentFile, index: number) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          key={`${index}-${value}`}
          onClick={() => {
            if (requestData) {
              const fileKey: FilesKey = {
                idCust: requestData.idCust,
                id: requestData.id,
                fileType: record.fileType,
                no: record.no,
                name: record.name,
                path: record.path,
                billingDate: '',
                isEnable: true,
              };
              const filesKey: FilesKey[] = [];
              filesKey.push(fileKey);
              DownloadFile(filesKey, value).finally(() => {
                /* Nothing to do. */
              });
            }
          }}
        >
          {value}
        </a>
      ),
      align: 'center' as const,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 250,
      render: (_value: string, record: AttachmentFile) => {
        const confirmationDel = () => {
          const text = `${record.name}を削除します。¥nよろしいですか？`;
          const breakedText = text.split('¥n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ));
          const fileKey: FilesKey = {
            idCust: requestData?.idCust ?? 0,
            id: requestData?.id ?? 0,
            fileType: record.fileType,
            no: record.no,
            name: '',
            path: record.path,
            billingDate: '',
            isEnable: null,
          };
          const filesKey: FilesKey[] = [];
          filesKey.push(fileKey);
          setTargetFilesKey(filesKey);

          setConfirmMessages(breakedText);
          setCommDlg(true);
        };

        return (
          <>
            <Button2 type="primary" danger onClick={confirmationDel}>
              削除
            </Button2>
          </>
        );
      },
      align: 'center' as const,
    },
  ];

  useEffect(() => {
    if (requestData) {
      const list = [...requestData.attachmentList];
      setAttachmentList(list);
    }
  }, [requestData]);

  return (
    <div className={classes.paper}>
      <MuiThemeProvider theme={theme2}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClickOpenTemp}
        >
          追加
        </Button>
      </MuiThemeProvider>
      <FileUploadDialog
        open={openTemp}
        onClickClose={handleCloseTemp}
        dialogTitle="添付"
        fileType={1}
        requestData={requestData}
        updateRequest={(values: Request) => {
          updateRequest(values);
        }}
      />
      <CommonDialog
        msg={confirmMessages}
        isOpen={commDlg}
        doYes={deleteF}
        doNo={() => {
          setCommDlg(false);
        }}
      />
      <div className="table-size">
        <Table dataSource={attachmentList} columns={columns} rowKey="no" />
      </div>
      <div className={classes.toNextDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.toNextButton}
          onClick={() => tabSet(3)}
        >
          ④備考の入力に進む
        </Button>
      </div>
    </div>
  );
};

export default AttachmentPanel;
