import { FC, ReactElement } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { rootDir } from 'utils/CommonConst';

/** Helpers */
import {
  checkUpdateToken,
  getCurrentUser,
  signOut,
} from 'utils/CommonFunctions';
import { useAppContext } from 'utils/AppContext';
import validateToken from '../utils/helpers';

/** Constants */
import AUTH_USER_TOKEN_KEY from '../utils/constants';

export type Props = {
  element: ReactElement;
  checkUserAuth: boolean;
  path?: string;
};

const PrivateElement: FC<Props> = ({ element, checkUserAuth }) => {
  const location = useLocation();

  // const { isAuthenticated, loading } = useSelector((state) => state.auth);

  // if (loading) return <p>Loading.. </p>;

  // if (!checkUserAuth) {
  //   // eslint-disable-next-line
  //   console.log(location);
  // }

  return checkUserAuth ? (
    element
  ) : (
    <Navigate to={`${rootDir}`} state={{ from: location }} />
  );
};

export type PrivateRouteProps = {
  element: ReactElement;
  path?: string;
};

/* eslint-disable  */
const PrivateRoute: FC<PrivateRouteProps> = ({ element, ...rest }) => {
  const appContext = useAppContext();

  const checkToken = async () => {
    await checkUpdateToken();
  };
  checkToken().finally(() => {});
  let checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));

  const logout = async () => {
    await signOut();
  };

  if (!checkUserAuth) {
    const init = async () => {
      const currentUser = await getCurrentUser();
      const state = { ...appContext.state };
      state.LoginUser = currentUser;

      appContext.setState(state);
    };
    init().finally(() => {
      /* Nothing to do. */
      checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
      if (!checkUserAuth) {
        void logout;
      }
    });
  }

  // console.log('checkUserAuth:' + checkUserAuth);
  return (
    <Route
      {...rest}
      element={
        <PrivateElement element={element} checkUserAuth={checkUserAuth} />
      }
    />
  );
};

export default PrivateRoute;
